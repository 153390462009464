
const INITIAL_STATE = {
    isIndividualSMSFormShow: false,
    SMSIndividualloading:true,
    SMSIndividualList:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_INDIVIDUAL_SMS_LIST':
            return ({
                ...states,
                SMSIndividualList: action.payload,
                SMSIndividualloading: false
            });

        case 'SHOW_HIDE_INDIVIDUAL_SMS_FORM':
            return({
                ...states,
                isIndividualSMSFormShow:action.payload
            });

        default:
          return states;

  }
}

