const INITIAL_STATE = {
    attendanceLoading:true,
    attendanceList:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_STUDENT_ATTENDANCE_LIST_REPORT':
            return ({
                ...states,
                attendanceList: action.payload,
                attendanceLoading:false
            });

        default:
          return states;

  }
}
