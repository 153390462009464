import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {getReligionList, showHideForm} from '../../../../store/action/ReligionAction';
import {showMessage} from '../../../../store/action/MessageAction';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class ReligionForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      religionId:0,
      isUpdate:false,
      FormGroup: {
        religionName: ''
      }
    };

  }

  clearForm = () => {
    const UserInput = {};
    UserInput['religionName'] = '';

    this.setState({
        FormGroup: UserInput,
        religionId:0,
        isUpdate: false
    });
    
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevProps.religionDetail !== this.props.religionDetail) {
      this.setState({
        FormGroup:this.props.religionDetail
      })

      if(this.props.religionDetail !== ''){
        this.setState({
          religionId:this.props.religionDetail._id,
          isUpdate: true
        })
      }

    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    
    const Religion = this.state.FormGroup;
   
    let url = `${process.env.PUBLIC_URL}/api/SaveReligion`;

    if(this.state.isUpdate){
      url = `${process.env.PUBLIC_URL}/api/updateReligionById/${this.state.religionId}`;
    }

    axios.post(url, Religion)
    .then(res => {

        if(res.data.resType === 'success'){
          this.clearForm();
          this.props.getReligionList();
        }

        this.props.showMessage({
          messageType: res.data.resType,
          Message:res.data.message
        });

     }).catch(err => console.log(err));

  }

render() {

    return (
      <div className='form-modle'>
          
          <form onSubmit={this.handleSubmit}>
            <h2 className="head_h2"> Religion </h2>
            <i onClick={this.props.showHideForm} className="fas fa-arrow-left close-btn"></i>


            <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
              <input type="text" 
                  className="form-control" 
                  placeholder="Religion Name" 
                  name="religionName" 
                  value={this.state.FormGroup.religionName} 
                  onChange={this.handleInputChange} />
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>

          </form>

      </div>

    );
  }
}

function mapStateToProps(state) {
  return ({
    religionDetail: state.religionReducer.religionDetail
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      getReligionList: () => {
        dispatch(getReligionList());
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(false));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(ReligionForm);

