import React, { Component } from 'react';
import { connect } from 'react-redux';
import {hideMessage, showMessage} from '../../../../store/action/MessageAction';
import {getNotificationList, showHideForm} from './Action';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import {SchoolLogo} from '../../../utils/SchoolLogo';
import Navbar from '../../../Navbar';
import {ReactMessage} from '../../../utils/ReactMessage';
import {CheckSession} from '../../../utils/CheckSession';

import NotificationList from './List'
import NotifitionForm from './form'

class PushNotification extends Component {

  constructor(props){

    super(props);
    CheckSession(this.props);

  }


render() {

    return (

      <div className="container mp0">

        <SchoolLogo SchoolName="Inzeal Infotech" />

        {this.props.messageShow ? 
          <ReactMessage 
            func={this.props.hideMessage} 
            messageType={this.props.messageType} 
            Message={this.props.Message} /> : null}

        <Navbar />

          <div className="form-wrapper" style={{width:"100%"}}>
              <h2>List <button onClick={this.props.showHideForm} className="btn btn-sm btn-info float-right">Add New</button></h2>

              {this.props.isFormShow ? <NotifitionForm /> : null}

              <div style={{overflow:"auto"}}>
                <NotificationList />
              </div>
              
          </div>
        

      </div>

    );
  }
}

function mapStateToProps(state) {
    return ({
      messageShow: state.messageReducer.messageShow,
      messageType: state.messageReducer.messageType,
      Message: state.messageReducer.Message,
      isFormShow: state.allNotification.isFormShow
    });
}

function mapDispatchToProps(dispatch) {
  return ({
    getNotificationList : () => {
      dispatch(getNotificationList());
    },
    hideMessage: () => {
      dispatch(hideMessage());
    },
    showMessage: (data) => {
      dispatch(showMessage(data));
    },
    showHideForm: () => {
      dispatch(showHideForm(true));
    }
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(PushNotification);

