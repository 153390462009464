import axios from 'axios';

export const Master = {

    saveCity: (url, City) => {
        return axios.post(url, City)
        .catch(err => console.log(err));
    },

    deleteCity: (id) => {
        return axios.delete(`${process.env.PUBLIC_URL}/api/deleteCity/${id}`)
        .catch(err => console.log(err));
    },

    getCityById: (id) => {
        return axios.get(`${process.env.PUBLIC_URL}/api/getCityById/${id}`)
        .catch(err => console.log(err));
    },

    getCityList: () => {
        return axios.get(`${process.env.PUBLIC_URL}/api/getCity`)
        .catch(err => console.log(err));
    }
    
}

export const AttendanceService = {
    getStudentByClass: (BranchId, ClassId) => {
        return axios.get(`${process.env.PUBLIC_URL}/api/getStudentByClassId/${BranchId}&${ClassId}`)
        .catch(err => console.log(err));
    },

    saveAttendance: (url, Data) => {
        return axios.post(url, Data).catch(err => console.log(err));
    },

    getStudentAttendanceList: (date, branchId, classId) => {
        return axios.get(`${process.env.PUBLIC_URL}/api/getStudentAttendanceList/${classId}&${date}&${branchId}`)
        .catch(err => console.log(err));
    },

    getStudentByParent: () => {
        return axios.get(`${process.env.PUBLIC_URL}/api/parent/getStudentByParent`)
        .catch(err => console.log(err));
    }
}

export const PermissionService = {

    getPermissionList: (id) => {
        return axios.get(`${process.env.PUBLIC_URL}/api/getTeacherPermissionList/${id}`)
        .catch(err => console.log(err));
    },

    savePermission: (id, Data) => {
        return axios.post(`${process.env.PUBLIC_URL}/api/SaveTeacherPermission/${id}`, Data)
        .catch(err => console.log(err));
    }

}

export const ParentPermissionService = {

    getPermissionList: (id) => {
        return axios.get(`${process.env.PUBLIC_URL}/api/getParentPermissionList/${id}`)
        .catch(err => console.log(err));
    },

    savePermission: (id, Data) => {
        return axios.post(`${process.env.PUBLIC_URL}/api/SaveParentPermission/${id}`, Data)
        .catch(err => console.log(err));
    }

}
