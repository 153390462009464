import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import {getSMSList, showHideForm} from '../../../../store/action/SMSClassAction';
import {showMessage} from '../../../../store/action/MessageAction';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import BranchDrop from '../../../utils/BranchDrop';
import {User} from '../../../utils/ValidateUser';
import ClassDrop from '../../../utils/ClassDrop';

class SMSClassForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      FormGroup: {
        PhoneNo: '',
        branchId: '',
        classId: '',
        Message: ''
      }
    };
  }

  clearForm = () => {
    const UserInput = {};
    UserInput['PhoneNo'] = '';
    UserInput['Message'] = '';
    UserInput['branchId'] = '';
    UserInput['classId'] = '';

    this.setState({
        FormGroup: UserInput
    });
    
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

  }

  handleSubmit = async (event) => {
    event.preventDefault();
    
    const SMS = this.state.FormGroup;
   
    let url = `${process.env.PUBLIC_URL}/api/SaveClassSMS`;

    axios.post(url, SMS)
    .then(res => {

        if(res.data.resType === 'success'){
          this.clearForm();
          this.props.getSMSList();
        }

        this.props.showMessage({
          messageType: res.data.resType,
          Message: res.data.message
        });

     }).catch(err => console.log(err));

  }

render() {

    return (
      <div className='form-modle'>
          
          <form onSubmit={this.handleSubmit}>
          <h2 className="head_h2"> SMS </h2>
              <i onClick={this.props.showHideForm} className="fas fa-arrow-left close-btn"></i>

              {User.isAdmin() ? 
              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                  <label>Branch</label>
                  <BranchDrop 
                    isRequred={true}
                    BranchId={this.state.FormGroup.branchId} 
                    func={this.handleInputChange} />
              </div>: null}

              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <label>Class</label>
                <ClassDrop 
                  InputName='classId' 
                  ClassId={this.state.FormGroup.classId} 
                  func={this.handleInputChange} />
              </div>

              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
               
                <textarea 
                className="form-control" 
                placeholder="Type Message..." 
                name="Message" 
                value={this.state.FormGroup.Message} 
                onChange={this.handleInputChange} ></textarea>

              </div>

              <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
                  <button type="submit" className="btn btn-primary">Submit</button>
              </div>

          </form>

      </div>

    );
  }
}

function mapStateToProps(state) {
  return ({
    SMSloading: state.smsClassReducer.SMSClassLoading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      getSMSList: () => {
        dispatch(getSMSList());
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(false));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(SMSClassForm);

