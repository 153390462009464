import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {getTeacherDetail, showHideForm, getTeacherList} from '../../../../store/action/TeacherMasterAction';
import {showMessage} from '../../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';


class TeacherCardView extends Component {


  deleteTeacher = (id) => {
    
    axios.delete(`${process.env.PUBLIC_URL}/api/deleteTeacher/${id}`)
    .then(res => {

      if(res.data.resType === 'success'){
        this.props.changeStateToReducer();
      }

      this.props.showMessage({
        messageType: res.data.resType,
        Message: res.data.message
      });

    }).catch(err => console.log(err));
  }

  getTeacherDet = (id) =>{
    this.props.getTeacherDetail(id);
    this.props.showHideForm();
  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  
render() {

  const TeacherTable  = (List) => {
    console.log(List);
    return(
      <div className="row">
          {List.map((item, index) => 

            <div key={index} className="col-sm-4 col-md-3 col-lg-2">
                <div className="card">
                  <img className="card-img-top" 
                  src={process.env.PUBLIC_URL+"/Teachers/ProfilePhoto/"+item.photoPath} 
                  alt="Card" style={{width:"100%"}} />
                   
                  <div className="card-body" style={{padding:"5px"}}>
                    <h6 className="card-title" style={{marginBottom:'1px'}}>{item.teacherName}</h6>
                    <p style={{marginBottom:'1px'}}>{item.designationId.designationName}</p>
                    <i className="fas fa-edit" onClick={this.getTeacherDet.bind(this, item._id)} style={{margin:"2px", color:"green"}}></i>
                    <i className="fas fa-trash-alt" onClick={this.deleteTeacher.bind(this, item._id)} style={{margin:"2px", color:"red"}}></i>
                  </div>

                </div>
            </div>

          )}

      </div>
    );
  }

  const Teacher = this.props.teacherloading ? <i>Loading......!</i> : TeacherTable(this.props.teacherList);
  
    return (

      <React.Fragment>
        {Teacher}
      </React.Fragment>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    teacherList: state.teacherMasterReducer.teacherList,
    teacherloading:state.teacherMasterReducer.teacherloading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      changeStateToReducer: () => {
        dispatch(getTeacherList());
      },
      getTeacherDetail: (id) => {
        dispatch(getTeacherDetail(id));
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      }
  });
}



export default connect(mapStateToProps,mapDispatchToProps)(TeacherCardView);


