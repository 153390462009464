import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {getNotificationList} from './Action';
import {showMessage} from '../../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class NotificationList extends Component {

  componentDidMount() {
    this.props.changeStateToReducer();
  }

  deleteNotification = (id) => {
    
    axios.delete(`${process.env.PUBLIC_URL}/api/notifications/deleteClassWiseNotification/${id}`)
    .then(res => {

      if(res.data.resType === 'success') {
        this.props.changeStateToReducer();
      }

      this.props.showMessage({
        messageType: res.data.resType,
        Message: res.data.message
      });

    }).catch(err => console.log(err));
  }

  
render() {

  const renderTable  = (List) =>{
    return(
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Title</th>
              <th>Body</th>
              <th>Branch</th>
              <th>Class</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {List.map((item, index) => 
              <tr key={index}>
                <td>{item.title}</td>
                <td>{item.body}</td>
                <td>{item.branch}</td>
                <td>{item.class}</td>
                <td>{item.entryOn}</td>
                <td>
                
                    <i className="fas fa-trash-alt" onClick={this.deleteNotification.bind(this, item._id)} 
                      style={{margin:"2px 5px", color:"red", cursor:"pointer"}} title="Delete"></i>
                      
                </td>
              </tr>
            )}
          </tbody>
        </table>

    );

  }

  const SMS = this.props.Loading ? <div className="loader">
    <img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} />
    </div> : renderTable(this.props.List)

    console.log(this.props.List)

    return (

      <React.Fragment>
        {SMS}
      </React.Fragment>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    List: state.classWiseNotification.classNotificationList,
    Loading:state.classWiseNotification.classNotificationLoading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      changeStateToReducer: () => {
        dispatch(getNotificationList());
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(NotificationList);


