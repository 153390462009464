import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getWardList} from '../Ward/WardAction';
import {showHideForm, getHomeWorkList} from './Action';
import {showMessage} from '../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class CardView extends Component {

  constructor(props){
    super(props);

    this.state = {
      studentList: this.props.studentList,
      studentLoading: this.props.studentLoading
    }
    
  }

  componentDidMount() {
    this.props.changeStateToReducer();
  }

  getHomeWorkDet = (id, index) => {

    this.props.getHomeWorkList(id)
    this.props.showHideForm();

  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

render() {
console.log(this.props.studentList);
  const Card  = (List) => {
    
    return(

      <div className="row">

        {List.map((item, index) => 

          <div key={index} className="col-sm-6 col-md-6 col-lg-4"> 
            
            <div onClick={this.getHomeWorkDet.bind(this, item._id)} 
                className={`thum_card_con`} style={{height: "100px"}}> 

              <img className="card_img" 
                src={process.env.PUBLIC_URL+"/Students/ProfilePhoto/"+item.photoPath} 
                alt="Card" /> 
                
              <div className="card_body" style={{padding:"5px"}}> 
                <h6 className="card_title" style={{marginBottom:'1px'}}>{item.studentName}</h6> 
                <p className="card_p"> Code : {item.studentCode}</p> 
                <p className="card_p">Class : {item.className}</p> 
                <p className="card_p">Roll No. : {item.rollNo}</p> 
              </div>

              <i className="clearfix"></i>

            </div>
            
          </div>

        )}

      </div>
    );
  }

  const Student = this.props.studentLoading ? <i>No Data</i> : Card(this.props.studentList);
 
    return (

      <React.Fragment>
        {Student}
      </React.Fragment>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    studentList: state.wardReducer.wardList,
    studentLoading: state.wardReducer.wardLoading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      getHomeWorkList: (id) => {
        dispatch(getHomeWorkList(id));
      },
      changeStateToReducer: () => {
        dispatch(getWardList());
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(CardView);