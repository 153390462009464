import React, { Component } from 'react';
import axios from 'axios';
const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUBLIC_VAPID_KEY)

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - base64String.length % 4) % 4)
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

 class Subscription extends Component {
  
  constructor(props){
    super(props);
   
    this.sendSubscription = this.sendSubscription.bind(this);
    this.subscribeUser = this.subscribeUser.bind(this);

    this.subscribeUser();
  }

  sendSubscription = (subscription) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/notifications/subscribe`, subscription)
        .catch(err => console.log(err));
  }

  subscribeUser = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        if (!registration.pushManager) {
          console.log('Push manager unavailable.')
          return
        }
  
        registration.pushManager.getSubscription().then((existedSubscription) => {
          if (existedSubscription === null) {
            console.log('No subscription detected, make a request.')
            registration.pushManager.subscribe({
              applicationServerKey: convertedVapidKey,
              userVisibleOnly: true,
            }).then((newSubscription) => {
              console.log('New subscription added.')
              this.sendSubscription(newSubscription)
            }).catch((e) => {
                console.log("permission is --"+ e)
              if (Notification.permission !== 'granted') {
                console.log('Permission was not granted.')
              } else {
                console.error('An error ocurred during the subscription process.', e)
              }
            })
          } else {
            console.log('Existed subscription detected.')
            //this.sendSubscription(existedSubscription)
          }
        })
      }).catch((e) => {
          console.error('An error ocurred during Service Worker registration.', e)
      })
    }
  }

render() {
    return (
      <div> 

      </div> 
    );
    
  }
}
export default Subscription;