const INITIAL_STATE = {
    isDesignationFormShow: false,
    designationLoading:true,
    designationDetail:'',
    designationList:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_DESIGNATION_DETAIL':
          return ({
              ...states,
              designationDetail: action.payload
          });

        case 'UPDATE_DESIGNATION_LIST':
            return ({
                ...states,
                designationList: action.payload,
                designationLoading:false
            });


        case 'SHOW_HIDE_DESIGNATION_FORM':
            return({
                ...states,
                isDesignationFormShow:action.payload
            });

        default:
          return states;

  }
}
