import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getTransportMemberList} from '../../../../store/action/AddTransportMemberAction';
import ClassDrop from '../../../utils/ClassDrop';
import BranchDrop from '../../../utils/BranchDrop';
import {User} from '../../../utils/ValidateUser';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class StudentFilter extends Component {

  constructor(props){
    super(props);
    this.state = {
      FormGroup: {
        branchId: '0', 
        classId: '0' 
      }
    };
  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

  }

  filterList = () => {
    let {branchId, classId} = this.state.FormGroup;
    if(branchId === "" || branchId === null){
      branchId = "0"
    }

    if(classId === "" || classId === null){
      classId = "0"
    }

    this.props.applyFilter(branchId, classId)
    this.props.applyFilterr(this.state.FormGroup);
  }

render() {

    return (
      <React.Fragment>

        {User.isAdmin() ? 
        <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
            <label>Branch</label>
            <BranchDrop 
              BranchId={this.state.FormGroup.branchId} 
              func={this.handleInputChange} />
        </div>: null} 

        <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left"> 
          <label>Class</label> 
          <ClassDrop 
            InputName='classId' 
            ClassId={this.state.FormGroup.classId} 
            func={this.handleInputChange} /> 
        </div>

        <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
            <button onClick={this.filterList} className="btn btn-primary">Search</button>
        </div>

      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return ({
    transportMemberDetail: state.addTransportMemberReducer.transportMemberDetail
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      applyFilter: (branchId, classId) => {
        dispatch(getTransportMemberList(branchId, classId));
      },
      applyFilterr: (data) => {
        dispatch({  
          type: 'UPDATE_TRANSPORT_MEMBER_FILTER',
          payload: [true,data]
        });
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(StudentFilter);
