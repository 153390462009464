import React, { Component } from 'react';
import{Link} from 'react-router-dom';
import axios from 'axios';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import {SchoolLogo} from '../../../utils/SchoolLogo';
import Navbar from '../../../Navbar';
import {ReactMessage} from '../../../utils/ReactMessage';
import {CheckSession} from '../../../utils/CheckSession';


class SchoolSetupList extends Component {

  constructor(props){
    super(props);
    this.state = {
      SchoolList:[],
      loading : true,
      messageShow: false,
      messageType: 'success',
      Message:'',
    };

    this.mounted = true;
    CheckSession(this.props);
  }

  componentWillUnmount(){
    this.mounted = false;
  }

  componentDidMount() {
    this.getSchoolList();
  }

  hideMessage = () => {
    this.setState({
      messageShow: false,
      messageType: 'success',
      Message: '',
    });
  }

  getSchoolList = () => {
  
    axios.get(`${process.env.PUBLIC_URL}/api/getSchool`)
    .then(res => {
            
      if(res.data.resType === 'success'){
        if(this.mounted){
          this.setState({
            SchoolList:res.data.school,
            loading: false
          });
        }
        
      }

    }).catch(err => console.log(err));
  }

  deleteSchool = (id) => {
    
    axios.delete(`${process.env.PUBLIC_URL}/api/deleteSchool/${id}`)
    .then(res => {
          
      if(res.data.resType === 'success'){
        this.getSchoolList();
      }

      this.setState({
        messageShow: true,
        messageType: res.data.resType,
        Message:res.data.message,
      });

    }).catch(err => console.log(err));
  }


  
render() {

  const SchoolTable  = (List) =>{
    return(
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>SchoolCode</th>
              <th>SchoolName</th>
              <th>Email</th>
              <th>ContactNo</th>
              <th>Address</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {List.map((item, index) => 
              <tr key={index}>
                <td>{item.schoolCode}</td>
                <td>{item.schoolName}</td>
                <td>{item.email}</td>
                <td>{item.contactNo}</td>
                <td>{item.Address}</td>
                <td>
                  <Link className="btn btn-sm btn-info" to={"/SchoolSetup/"+item._id}>Edit</Link>
                  <button className="btn btn-sm btn-danger" onClick={this.deleteSchool.bind(this, item._id)}>Delete</button>
                </td>
              </tr>
              
              )}
          </tbody>
        </table>

    );

  }

  const School = this.state.loading ? <div className="loader"><img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} /></div> : SchoolTable(this.state.SchoolList)


    return (

      <div className="container mp0">

        <SchoolLogo SchoolName="Inzeal Infotech" />

        {this.state.messageShow ? <ReactMessage func={this.hideMessage} messageType={this.state.messageType} Message={this.state.Message} /> : null}

        <Navbar />

        <div className="row">
          <div className="form-wrapper" style={{width:"100%", overflow:"auto"}}>
              <h2>School Setup</h2>
              {School}
          </div>
        </div>
      </div>

    );
  }
}
export default SchoolSetupList;

