import React, { Component } from 'react';
import axios from 'axios';
import {MenuCard} from '../../UtilComponent/MenuCard'
import {SchoolLogo} from '../../utils/SchoolLogo'
import Navbar from '../../Navbar';
//import {CheckTeacherSession} from '../../utils/CheckSession';

class TeacherHome extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      cardList:[],
      loading: true
    };
  }

  componentWillMount(){
    //CheckTeacherSession(this.props);
  }

  componentWillReceiveProps(nextProps){
    this.getMenu(nextProps.match.params.id);
  }

  getMenu = (id) =>{
    axios.get(`${process.env.PUBLIC_URL}/api/getAdminMenuById/${id}`)
    .then(res => {

      if(res.data.resType === 'success'){
        this.setState({
          cardList: res.data.adminMenu,
          loading: false
        });
      }

    }).catch(err => console.log(err));
  }

render() {

  const renderCards = (cardList) =>{
    return(
      <React.Fragment>
        {cardList.map((card, index) => 
          <MenuCard key={index} icon={card.icon} title={card.title} Url={card.url+'/'+card._id} />
        )}
      </React.Fragment>
    )
  }

  const cardList = renderCards(this.state.cardList)

  return (
    <div className="container">
      <SchoolLogo SchoolName="Inzeal Infotech" />
      <Navbar />
      <div className="row">
        {this.state.loading ? <div className="loader"><img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} /></div> : cardList}
      </div>
    </div>
  );
  }
}
export default TeacherHome;