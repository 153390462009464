import React, { Component } from 'react';
import { connect } from 'react-redux';
import {showHideForm} from '../../../../store/action/StudentChargeAction';
import {hideMessage} from '../../../../store/action/MessageAction';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import {SchoolLogo} from '../../../utils/SchoolLogo';
import Navbar from '../../../Navbar';
import {ReactMessage} from '../../../utils/ReactMessage';
import {CheckSession} from '../../../utils/CheckSession';

import StudentChargeForm from './StudentChargeForm';
import StudentChargeList from './StudentChargeList';
import StudentDetailFilter from './filter';
import StudentDetail from './StudentDetail';

class StudentChargeMaster extends Component {

  constructor(props){
    super(props);

    CheckSession(this.props);
    this.state = {
      showDetail: this.props.studentFilterLoading
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevProps.studentFilterLoading !== this.props.studentFilterLoading) {
      this.setState({
        showDetail: this.props.studentFilterLoading
      })
    }
  }


render() {

    return (

      <div className="container mp0">

        <SchoolLogo SchoolName="Inzeal Infotech" />

        {this.props.messageShow ? <ReactMessage func={this.props.hideMessage} messageType={this.props.messageType} Message={this.props.Message} /> : null}

        <Navbar />

        
          <div className="form-wrapper" style={{width:"100%"}}>
          <h2>Student Charge </h2>
            <div className="row">
              <StudentDetailFilter />
            </div>
          </div>

          {this.state.showDetail ? null :
          <div className="form-wrapper" style={{width:"100%"}}>

            <h2>Student Detail <button onClick={this.props.showHideForm} className="btn btn-sm btn-info float-right">Add Fee</button></h2>
            
            {this.props.isFormShow ? <StudentChargeForm /> : null}
            
            <React.Fragment>
              <StudentDetail />
              <div style={{overflow:"auto"}}>
                <StudentChargeList />
              </div>
              
            </React.Fragment> 

          </div>}
        

      </div>

    );
  }
}

function mapStateToProps(state) {
    return ({
      messageShow: state.messageReducer.messageShow,
      messageType: state.messageReducer.messageType,
      Message: state.messageReducer.Message,
      isFormShow: state.studentChargeReducer.isStudentChargeFormShow,
      studentFilterLoading: state.studentChargeReducer.studentFilterLoading
    });
}

function mapDispatchToProps(dispatch) {
  return ({
    hideMessage: () => {
        dispatch(hideMessage());
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      }
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentChargeMaster);

