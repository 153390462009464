import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {getTeacherList, getTeacherDetail, showHideForm} from '../../../../store/action/TeacherMasterAction';
import {showMessage} from '../../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';


class TeacherList extends Component {

  componentDidMount() {
    this.props.changeStateToReducer();
  }

  deleteTeacher = (id) => {
    
    axios.delete(`${process.env.PUBLIC_URL}/api/deleteTeacher/${id}`)
    .then(res => {

      if(res.data.resType === 'success'){
        this.props.changeStateToReducer();
      }

      this.props.showMessage({
        messageType: res.data.resType,
        Message:res.data.message
      });

    }).catch(err => console.log(err));
  }

  getTeacherDet = (id) =>{
    this.props.getTeacherDetail(id);
    this.props.showHideForm();
  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  
render() {

  const TeacherTable  = (List) => {
    
    return(
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Teacher Code</th>
              <th>Teacher Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>National Id</th>
              <th>Gender</th>
              <th>Date Of Birth</th>
              <th>Joining Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {List.map((item, index) => 

              <tr key={index}>
                <td>{item.teacherCode}</td>
                <td>{item.teacherName}</td>
                <td>{item.teacherPhone}</td>
                <td>{item.teacherEmail}</td>
                <td>{item.nationalId}</td>
                <td>{item.Gender}</td>
                <td>{this.GetFormattedDate(item.dateOfBirth)}</td>
                <td>{this.GetFormattedDate(item.joiningDate)}</td>
                <td>
                    <i className="fas fa-edit" onClick={this.getTeacherDet.bind(this, item._id)} style={{margin:"2px", color:"green"}}></i>
                    <i className="fas fa-trash-alt" onClick={this.deleteTeacher.bind(this, item._id)} style={{margin:"2px", color:"red"}}></i>
                </td>
              </tr>
              
            )}
          </tbody>
        </table>

    );

  }

  const Teacher = this.props.teacherloading ? <div className="loader"><img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} /></div> : TeacherTable(this.props.teacherList);
  
    return (

      <React.Fragment>
        {Teacher}
      </React.Fragment>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    teacherList: state.teacherMasterReducer.teacherList,
    teacherloading:state.teacherMasterReducer.teacherloading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      changeStateToReducer: () => {
        dispatch(getTeacherList());
      },
      getTeacherDetail: (id) => {
        dispatch(getTeacherDetail(id));
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      }
  });
}



export default connect(mapStateToProps,mapDispatchToProps)(TeacherList);


