import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {getTransportMemberList, getTransportMemberDetail, showHideForm} from '../../../../store/action/AddTransportMemberAction';
import {showMessage} from '../../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class TransportMemberList extends Component {
  
  state = {
    transportMemberList: this.props.transportMemberList,
    search:""
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevProps.transportMemberList !== this.props.transportMemberList) {
      
      this.setState({
        transportMemberList: this.props.transportMemberList
      })

    }
  }

  deleteMember = (id) => {
    const {branchId, classId} = this.props.transportMemberFilter
    axios.delete(`${process.env.PUBLIC_URL}/api/deleteTransportMember/${id}`)
    .then(res => {

      if(res.data.resType === 'success'){
        this.props.changeStateToReducer(branchId, classId);
      }

      this.props.showMessage({
        messageType: res.data.resType,
        Message:res.data.message
      });

    }).catch(err => console.log(err));
  }

  getMemberDet = (id) =>{
    this.props.getMemberDetail(id);
    this.props.showHideForm();
  }

  addNewMember = (id) => {
    this.props.updateMemberId(id);
    this.props.showHideForm();
  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

render() {

  const renderTable  = (List) => {
   
    return(
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Student Code</th>
              <th>Student Name</th>
              <th>rollNo</th>
              <th>Address</th>
              <th>Fee</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody> 
            {List.map((item, index) => 
              <tr key={index}>
                <td>{item.studentCode}</td>
                <td>{item.studentName}</td>
                <td>{item.rollNo}</td>
                <td>{item.Address}</td>
                <td>{item.transport.length <= 0 ? null : item.transport[0].transportFee}</td>
                <td>
                  {item.transport.length <= 0 ? 
                    <i className="fas fa-plus-square" title="Add New" onClick={this.addNewMember.bind(this, item._id)} style={{margin:"2px", color:"green"}}></i>
                  : 
                    <React.Fragment>
                      <i className="fas fa-edit" title="Edit" onClick={this.getMemberDet.bind(this, item.transport[0]._id)} style={{margin:"2px", color:"green"}}></i>
                      <i className="fas fa-trash-alt" title="Delete" onClick={this.deleteMember.bind(this, item.transport[0]._id)} style={{margin:"2px", color:"red"}}></i>
                    </React.Fragment>
                  }
                </td>
              </tr>
            )}
          </tbody>
        </table>
    );
  }

  const Student = this.props.transportMemberLoading ? 
    <div className="loader"><img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} /></div> : 
    renderTable(this.state.transportMemberList);
  
    return (

      <div style={{overflow: "auto"}}>
        {Student}
      </div>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    transportMemberList: state.addTransportMemberReducer.transportMemberList,
    transportMemberLoading: state.addTransportMemberReducer.transportMemberLoading,
    transportMemberFilter: state.addTransportMemberReducer.transportMemberFilter,
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      changeStateToReducer: (branchId, classId) => {
        dispatch(getTransportMemberList(branchId, classId));
      },
      getMemberDetail: (id) => {
        dispatch(getTransportMemberDetail(id));
      },
      updateMemberId: (id) => {
        dispatch({
          type: "UPDATE_TRANS_MEMBER_ID",
          payload: id
        });
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(TransportMemberList);


