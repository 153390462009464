import React, { Component } from 'react';
import { connect } from 'react-redux';
import {showMessage} from '../../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class FeeList extends Component {
  
  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

render() {

  const renderTable  = (List) => {
   
    return(
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Student Code</th>
              <th>Student Name</th>
              <th>Phone</th>
              <th>Class Name</th>
              <th>Roll No</th>
              <th>Fee Type</th>
              <th>Amount</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {List.map((item, index) => 

              <tr key={index}>
                <td>{item.StudentDetail.studentCode}</td>
                <td>{item.StudentDetail.studentName}</td>
                <td>{item.StudentDetail.Phone}</td>
                <td>{item.ClassDetail.length <= 0 ? null : item.ClassDetail[0].className}</td>
                <td>{item.StudentDetail.rollNo}</td>
                <td>{item.FeeTypeDetail.length <= 0 ? null : item.FeeTypeDetail[0].feeTypeName}</td>
                <td>{item.Amount}</td>
                <td>{this.GetFormattedDate(item.Date)}</td>
              </tr>

            )}
          </tbody>
        </table>

    );
  }

  const FeeList = this.props.feeReportLoading ? 
            <div className="loader"><img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} /></div> : 
            renderTable(this.props.feeReportList);
  
    return (

      <div style={{overflow: "auto"}}>
        {/* <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
          <input type="text" 
              className="form-control" 
              placeholder="Search......" 
              name="search" 
              value={this.state.search} 
              onChange={this.handleInputChange} />
        </div> */}
        {FeeList}
      </div>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    feeReportList: state.feeReportReducer.feeReportList,
    feeReportLoading:state.feeReportReducer.feeReportLoading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
   
    showMessage: (data) => {
      dispatch(showMessage(data));
    }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(FeeList);


