const INITIAL_STATE = {
    feeReportLoading:true,
    feeReportList:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

    case 'UPDATE_FEE_REPORT_LIST':
        return ({
            ...states,
            feeReportList: action.payload,
            feeReportLoading:false
        });

    default:
        return states;

  }
}
