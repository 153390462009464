const INITIAL_STATE = {
    wardAttendanceLoading:true,
    wardAttendanceList:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_WARD_ATTEND_LIST':
            return ({
                ...states,
                wardAttendanceList: action.payload,
                wardAttendanceLoading:false
            });

        case 'SHOW_HIDE_WARD_CARDVIEW':
            return({
                ...states,
                isWardCardShow:action.payload
            });

        default:
          return states;

  }
}
