import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {getFeeSetupList, getFeeSetupDetail, showHideForm} from '../../../../store/action/FeeSetupAction';
import {showMessage} from '../../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';


class FeeSetupList extends Component {

  componentDidMount() {
    this.props.changeStateToReducer();
  }

  deleteFeeSetup = (id) => {
    
    axios.delete(`${process.env.PUBLIC_URL}/api/deleteFeeSetup/${id}`)
    .then(res => {

      if(res.data.resType === 'success') {
        this.props.changeStateToReducer();
      }

      this.props.showMessage({
        messageType: res.data.resType,
        Message:res.data.message
      });

    }).catch(err => console.log(err));
  }

  getFeeSetupDetail = (id) => {
    this.props.getFeeSetupDetail(id);
    this.props.showHideForm();
  }
  
render() {

  const renderTable  = (List) =>{
    console.log(List);
    return(
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Fee</th>
              <th>Fee Type</th>
              <th>Class</th>
              <th>Remark</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {List.map((item, index) => 
              <tr key={index}>
                <td>{item.Fee}</td>
                <td>{item.feeTypeId === null ? null : item.feeTypeId.feeTypeName}</td>
                <td>{item.classId === null ? null : item.classId.className}</td>
                <td>{item.Remark}</td>
                <td>
                  <button className="btn btn-sm btn-info" onClick={this.getFeeSetupDetail.bind(this, item._id)}>Edit</button>
                  <button className="btn btn-sm btn-danger" onClick={this.deleteFeeSetup.bind(this, item._id)}>Delete</button>
                </td>
              </tr>
            )}
          </tbody>
        </table>

    );

  }

  const feeSetup = this.props.feeSetuploading ? <div className="loader"><img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} /></div> : renderTable(this.props.feeSetupList)

    return (

      <React.Fragment>
        {feeSetup}
      </React.Fragment>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    feeSetupList: state.feeSetupReducer.feeSetupList,
    feeSetuploading:state.feeSetupReducer.feeSetuploading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      changeStateToReducer: () => {
        dispatch(getFeeSetupList());
      },
      getFeeSetupDetail: (id) => {
        dispatch(getFeeSetupDetail(id));
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(FeeSetupList);


