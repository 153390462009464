import axios from 'axios';

export function getAttendanceList(branchId, classId, studentId, fromDate, toDate) {

    let parms = `?branchId=${branchId}&classId=${classId}&studentId=${studentId}&fromDate=${fromDate}&toDate=${toDate}`;
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/report/getAttendanceList${parms}`)
        .then(response =>

            getAttendanceListRes(dispatch, response)

        ).catch(err => console.log(err));
    }
}

function getAttendanceListRes(dispatch, response) {
    console.log(response);
    try {
       if(response.data.resType === 'success'){

            return dispatch({
                type: 'UPDATE_STUDENT_ATTENDANCE_LIST_REPORT',
                payload: response.data.attendance
            })

       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data.message
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}
