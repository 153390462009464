import React, { Component } from 'react';
import axios from 'axios';

export default class ClassDrop extends Component {

    constructor(props) {

        super(props);
        this.state = {
            ClassList: [],
            loading: true,
            Message: 'wait....!'
        };

        axios.get(`${process.env.PUBLIC_URL}/api/getClassDrop`)
        .then(response => {
            this.setState({ ClassList: response.data.class, loading: false });
        });

    }

    render(){

        const renderlist = (List) => {
            return (
                <select name={this.props.InputName} 
                        value={this.props.ClassId} 
                        onChange={this.props.func} 
                        className="form-control" required={this.props.isRequred}>

                        <option value="">Select</option>
                        {List.map(S =>
                            <option key={S._id} value={S._id}>{S.className}</option>
                        )}

                </select>
            );
        }

        let ClassList = this.state.loading
            ? <p><em>Loading...</em></p>
            : renderlist(this.state.ClassList);

        return (
            <div>
                {ClassList}
            </div>
                
        );
    }
}

ClassDrop.defaultProps = {
    ClassId: "0",
    InputName: "classId",
    isRequred: false
}
