import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import BranchDrop from '../../../utils/BranchDrop';
import {User} from '../../../utils/ValidateUser';
import {getExpenseList, showHideForm} from '../../../../store/action/ExpenseAction';
import {showMessage} from '../../../../store/action/MessageAction';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class ExpenseForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      expenseId:0,
      isUpdate:false,
      FormGroup: {
        branchId: '0',
        Issue: '',
        Date: '',
        Amount: '',
        payMode: '',
        payType: '',
        refDate: '',
        refNo: '',
        Remark: ''
      }
    };
  }

  clearForm = () => {
    const UserInput = {};
    UserInput['branchId'] = '0';
    UserInput['Issue'] = '';
    UserInput['Date'] = '';
    UserInput['Amount'] = '';
    UserInput['Remark'] = '';

    this.setState({
        FormGroup: UserInput,
        expenseId:0,
        isUpdate: false
    });
    
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevProps.expenseDetail !== this.props.expenseDetail) {
      const userInput = this.props.expenseDetail
      userInput['Date'] = this.GetFormattedDate(this.props.expenseDetail.Date);

      if(this.props.expenseDetail !== ''){
        this.setState({
          FormGroup: userInput,
          expenseId:this.props.expenseDetail._id,
          isUpdate: true
        })
      }

    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    
    const Expense = this.state.FormGroup;
   
    let url = `${process.env.PUBLIC_URL}/api/SaveExpense`;

    if(this.state.isUpdate){
      url = `${process.env.PUBLIC_URL}/api/updateExpenseById/${this.state.expenseId}`;
    }

    axios.post(url, Expense)
    .then(res => {

        if(res.data.resType === 'success'){
          this.clearForm();
          this.props.getExpenseList();
        }

        this.props.showMessage({
          messageType: res.data.resType,
          Message: res.data.message
        });

     }).catch(err => console.log(err));

  }

render() {

    return (
      <div className='form-modle'>
          
          <form onSubmit={this.handleSubmit}>
          <h2 className="head_h2"> Fee Type </h2>
          <i onClick={this.props.showHideForm} className="fas fa-arrow-left close-btn"></i>
            {User.isAdmin() ? 
              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                  <label>Branch</label>
                  <BranchDrop 
                    BranchId={this.state.FormGroup.branchId} 
                    func={this.handleInputChange} />
              </div>: null}

              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <label>Issue</label>
                <input type="text" 
                    className="form-control" 
                    placeholder="Issue" 
                    name="Issue" 
                    value={this.state.FormGroup.Issue} 
                    onChange={this.handleInputChange} />
              </div>

              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <label>Date</label>
                <input type="date" 
                    className="form-control" 
                    placeholder="Date" 
                    name="Date" 
                    value={this.state.FormGroup.Date} 
                    onChange={this.handleInputChange} />
              </div>

              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <label>Amount</label>
                <input type="text" 
                    className="form-control" 
                    placeholder="Amount" 
                    name="Amount" 
                    value={this.state.FormGroup.Amount} 
                    onChange={this.handleInputChange} />
              </div>

              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <label>Remark</label>
                <input type="text" 
                    className="form-control" 
                    placeholder="Remark" 
                    name="Remark" 
                    value={this.state.FormGroup.Remark} 
                    onChange={this.handleInputChange} />
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
                  <button type="submit" className="btn btn-primary">Submit</button>
              </div>

          </form>

      </div>

    );
  }
}

function mapStateToProps(state) {
  return ({
    expenseDetail: state.expenseReducer.expenseDetail
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      getExpenseList: () => {
        dispatch(getExpenseList());
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(false));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(ExpenseForm);

