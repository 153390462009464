import axios from 'axios';

export function getStudentChargeDetail(id) {
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/getStudentChargeById/${id}`)
        .then(response =>{

            getStudentChargeDetailRes(dispatch, response)
           
        }).catch(err => console.log(err));
    }
}

function getStudentChargeDetailRes(dispatch, response) {
    
    try {
       if(response.data.resType === 'success'){

            return dispatch({
                type: 'UPDATE_STUDENT_CHARGE_DETAIL',
                payload: response.data.studentCharge
            })
       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}

export function getStudentChargeList(studentId) {
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/getStudentCharge/${studentId}`)
        .then(response =>

            getStudentChargeRes(dispatch, response)

        ).catch(err => console.log(err));
    }
}

function getStudentChargeRes(dispatch, response) {
   
    try {
       if(response.data.resType === 'success'){
            console.log(response.data);
            return dispatch({
                type: 'UPDATE_STUDENT_CHARGE_LIST',
                payload: response.data
            })
       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data.message
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}

export function showHideForm(data) {
  
    return dispatch => {

        return dispatch({
            type: 'SHOW_HIDE_STUDENT_CHARGE_FORM',
            payload: data
        })
    }
}




