import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import * as $ from 'jquery';

class Navbar extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      isMenu: true,
      menuList: [],
      loading: true
    };
    this.getMenu();
  }

  logOut = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  getMenu = () => {
    axios.get(`${process.env.PUBLIC_URL}/api/parent/getMenu`)
    .then(res => {
      
      if(res.data.resType === 'success'){

        this.setState({
          menuList: res.data.parentMenu,
          loading: false
        });

        loadScript();

      }

    }).catch(err => console.log(err));
  }

render() {

  const filterList = (id) => {
    return this.state.menuList.filter(M => M.parentId === id);
  }

  const menuLink = (M) => {
    if (M.url === '/ParentHome') return(
      <span className="has_child">
        <img className="menu_icon" 
          src={process.env.PUBLIC_URL+"/Images/icon/"+M.icon+".png"} 
          alt="Card Icon" /> {M.title}</span>
    )
    else return (
      <Link className="has_child" to={`${M.url}/${M._id}`}>
        <img className="menu_icon" 
          src={process.env.PUBLIC_URL+"/Images/icon/"+M.icon+".png"} 
          alt="Card Icon" /> {M.title}</Link>
    )
  }

    return (
      <React.Fragment>
      {this.state.isMenu ? 
        <div className="side_menu_wrapper side_menu_wrapper_hide">
            <ul className="side_menu">
              <li>
                <Link to={`/ParentHome/auth_newdasdahsdwye`}>
                  <img className="menu_icon" 
                      src={process.env.PUBLIC_URL+"/Images/icon/homeIcon.png"} 
                      alt="Card Icon" /> Home</Link>
              </li>

              {filterList('auth_newdasdahsdwye').map((M, i) => 
                
                  <li key={i}>
                    {menuLink(M)}
                    <ul className="sub_menu">
                      {filterList(M._id).map((m2, i2) => 
                
                        <li key={i2}>
                          <Link className="has_child" to={`${m2.url}/${m2._id}`}>
                            <img className="menu_icon" 
                              src={process.env.PUBLIC_URL+"/Images/icon/"+m2.icon+".png"} 
                              alt="Card Icon" /> {m2.title}</Link>
                          <ul className="sub_menu">
                            {filterList(m2._id).map((m3, i3) => 
                  
                              <li key={i3}>
                                <Link className="has_child" to={`${m3.url}/${m3._id}`}>
                                  <img className="menu_icon" 
                                    src={process.env.PUBLIC_URL+"/Images/icon/"+m2.icon+".png"} 
                                    alt="Card Icon" /> {m3.title}</Link>
                                
                              </li>
                            
                            )}
                          </ul>
                        </li>
                      
                      )}
                    </ul>
                  </li>
                
                )}

              <li>
                <button onClick={this.logOut} style={{color:'black'}}><i className='fas fa-power-off' ></i> Logout</button>
              </li>
          
            </ul>

            <div className="toogle-btn">
              <p></p>
              <p></p>
              <p></p>
            </div>

        </div> : null
      }
      
      </React.Fragment>
      
      
    );
  }
}
export default Navbar;

function loadScript(){

  $(document).ready(function(){
    $(".toogle-btn").click(function(){
      $(".side_menu_wrapper").toggleClass("side_menu_wrapper_hide");
      $(".toogle-btn p:nth-child(1)").toggleClass("toogle-btn-p1");
      $(".toogle-btn p:nth-child(2)").toggleClass("toogle-btn-p2");
      $(".toogle-btn p:nth-child(3)").toggleClass("toogle-btn-p3");
    });

    $(".has_child").click(function(){
      $(this).parent().children('.sub_menu').toggle(100);
    });

    
  });

}
