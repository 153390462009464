const INITIAL_STATE = {
    isSMSFormShow: false,
    SMSloading:true,
    SMSList:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_SMS_LIST':
            return ({
                ...states,
                SMSList: action.payload,
                SMSloading: false
            });

        case 'SHOW_HIDE_SMS_FORM':
            return({
                ...states,
                isSMSFormShow:action.payload
            });

        default:
          return states;

  }
}

