import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getNotifitionList} from './Action';
import {GoogleAdd} from '../GoogleAdd';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class CardView extends Component {

  componentDidMount(){
    this.props.changeStateToReducer();
  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }
  
render() {

  console.log(this.props.List);
  const placeForAd = (i) => {
    return i % 3
  }

  const Card  = (List) => {
    
    return(
      <div className="row">
          
          {List.map((item, index) => 
          <React.Fragment key={index}>
            {placeForAd(index) === 0 ? 
              <React.Fragment>
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="notifi_card">
                    <GoogleAdd />
                  </div>
                </div>
                <div  className="col-sm-12 col-md-12 col-lg-12">
                    <div className="notifi_card">
                        <h6 className="card-title" style={{marginBottom:'1px'}}>{item.heading}</h6>
                        <p>{item.message}</p>
                        <p style={{textAlign:"right", margin:"0px"}}>{item.dated}</p>
                    </div>
                </div>
              </React.Fragment>: 
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="notifi_card">
                  
                    <h6 className="card-title" style={{marginBottom:'1px'}}>{item.heading}</h6>
                    <p>{item.message}</p>
                    <p style={{textAlign:"right", margin:"0px"}}>{item.dated}</p>
                </div>
              </div>
            }
          </React.Fragment>
          )}

      </div>
    );
  }

  const Student = this.props.Loading ? <i>Loading......!</i> : Card(this.props.List);
  
    return (

      <React.Fragment>
        
        {Student}

      </React.Fragment>
      
    );

  }
}

function mapStateToProps(state) {
  return ({
    List: state.parentNotification.List,
    Loading:state.parentNotification.Loading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      changeStateToReducer: () => {
        dispatch(getNotifitionList());
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(CardView);


