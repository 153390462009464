import React, { Component } from 'react';
import { connect } from 'react-redux';
import {hideMessage} from '../../../../store/action/MessageAction';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import {SchoolLogo} from '../../../utils/SchoolLogo';
import Navbar from '../../../Navbar';
import {ReactMessage} from '../../../utils/ReactMessage';
import {CheckSession} from '../../../utils/CheckSession';

import FeeList from './RecordList';
import FeeReportFilter from './filter';

class FeeReportMaster extends Component {

  constructor(props){
    super(props);
    CheckSession(this.props);
  }

render() {

    return (

      <div className="container mp0">

        <SchoolLogo SchoolName="Inzeal Infotech" />

        {this.props.messageShow ? <ReactMessage func={this.props.hideMessage} messageType={this.props.messageType} Message={this.props.Message} /> : null}

        <Navbar />

        <div className="form-wrapper">
          <h2>Fee Report</h2>
          <div className="row">
            <FeeReportFilter />
          </div>
        </div>

        {!this.props.feeReportLoading ? <div className="form-wrapper" style={{width:"100%"}}>
          <h2>Fee List </h2>
          <div style={{overflow:"auto"}}>
            <FeeList /> 
          </div>
        </div>: null}
       

      </div>

    );
  }
}

function mapStateToProps(state) {
    return ({
      messageShow: state.messageReducer.messageShow,
      messageType: state.messageReducer.messageType,
      Message: state.messageReducer.Message,
      feeReportLoading: state.feeReportReducer.feeReportLoading
    });
}

function mapDispatchToProps(dispatch) {
  return ({
      hideMessage: () => {
        dispatch(hideMessage());
      }
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(FeeReportMaster);

