import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {getClassList, showHideForm} from '../../../../store/action/ClassAction';
import {showMessage} from '../../../../store/action/MessageAction';
import TeacherDrop from '../../../utils/TeacherDrop';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class ClassForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      classId:0,
      isUpdate:false,
      FormGroup: {
        className:'',
        classNumeric: '',
        classTeacherId:'0',
      }
    };

  }

  clearForm = () => {
    const UserInput = {};
    UserInput['className'] = '';
    UserInput['classNumeric'] = '';
    UserInput['classTeacherId'] = '0';

    this.setState({
        FormGroup: UserInput,
        classId:0,
        isUpdate: false
    });
    
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevProps.classDetail !== this.props.classDetail) {
      this.setState({
        FormGroup:this.props.classDetail
      })

      if(this.props.classDetail !== ''){
        this.setState({
          classId:this.props.classDetail._id,
          isUpdate: true
        })
      }

    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    
    const Class = this.state.FormGroup;
   
    let url = `${process.env.PUBLIC_URL}/api/SaveClass`;

    if(this.state.isUpdate){
      url = `${process.env.PUBLIC_URL}/api/updateClassById/${this.state.classId}`;
    }

    axios.post(url, Class)
    .then(res => {

        if(res.data.resType === 'success'){
          this.clearForm();
          this.props.getClassList();
        }

        this.props.showMessage({
          messageType: res.data.resType,
          Message:res.data.message
        });

     }).catch(err => console.log(err));

  }

render() {

    return (
      <div className='form-modle'>
          
          <form onSubmit={this.handleSubmit}>
            <h2 className="head_h2"> Class </h2>
            <i onClick={this.props.showHideForm} className="fas fa-arrow-left close-btn"></i>

            <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
              <input type="text" 
                    className="form-control" 
                    placeholder="Class Name" 
                    name="className" 
                    value={this.state.FormGroup.className} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
              <input type="text" 
                    className="form-control" 
                    placeholder="Class Numeric" 
                    name="classNumeric" 
                    value={this.state.FormGroup.classNumeric} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <label>Class Teacher</label>
                <TeacherDrop 
                  InputName='classTeacherId' 
                  TeacherId={this.state.FormGroup.classTeacherId} 
                  func={this.handleInputChange}/>
            </div>


            <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>

          </form>

      </div>

      
       
    );
  }
}

function mapStateToProps(state) {
  return ({
    classDetail: state.classReducer.classDetail
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      getClassList: () => {
        dispatch(getClassList());
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(false));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(ClassForm);

