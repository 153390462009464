import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {getFeeSetupList, showHideForm} from '../../../../store/action/FeeSetupAction';
import {showMessage} from '../../../../store/action/MessageAction';
import ClassDrop from '../../../utils/ClassDrop';
import FeeTypeDrop from '../../../utils/FeeTypeDrop';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class FeeSetupForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      feeSetupId:0,
      isUpdate:false,
      FormGroup: {
        classId: '0',
        feeTypeId: '0',
        Fee: '',
        feeDate: '',
        Fine: '',
        Remark: ''
      }
    };

  }

  clearForm = () => {
    const UserInput = {};
    UserInput['classId'] = '';
    UserInput['feeTypeId'] = '';
    UserInput['Fee'] = '';
    UserInput['feeDate'] = '';
    UserInput['Fine'] = '';
    UserInput['Remark'] = '';

    this.setState({
        FormGroup: UserInput,
        feeSetupId:0,
        isUpdate: false
    });
    
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevProps.feeSetupDetail !== this.props.feeSetupDetail) {

      if(this.props.feeSetupDetail !== ''){
        const UserInput = this.props.feeSetupDetail;
        UserInput['feeDate'] = this.GetFormattedDate(this.props.feeSetupDetail.feeDate);

        this.setState({
          FormGroup: UserInput,
          feeSetupId:this.props.feeSetupDetail._id,
          isUpdate: true
        })
      }

    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    
    const FeeSetup = this.state.FormGroup;
   
    let url = `${process.env.PUBLIC_URL}/api/SaveFeeSetup`;

    if(this.state.isUpdate){
      url = `${process.env.PUBLIC_URL}/api/updateFeeSetupById/${this.state.feeSetupId}`;
    }

    axios.post(url, FeeSetup)
    .then(res => {

        if(res.data.resType === 'success'){
          this.clearForm();
          this.props.getFeeSetupList();
        }

        this.props.showMessage({
          messageType: res.data.resType,
          Message: res.data.message
        });

     }).catch(err => console.log(err));

  }

render() {

    return (
      <div className='form-modle'>
          
          <form onSubmit={this.handleSubmit}>
          <h2 className="head_h2"> Fee Setup </h2>
          <i onClick={this.props.showHideForm} className="fas fa-arrow-left close-btn"></i>

              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <label>Class</label>
                <ClassDrop 
                  InputName='classId' 
                  ClassId={this.state.FormGroup.classId} 
                  func={this.handleInputChange} />
              </div>

              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <label>Fee Type</label>
                <FeeTypeDrop 
                  InputName='feeTypeId' 
                  FeeTypeId={this.state.FormGroup.feeTypeId} 
                  func={this.handleInputChange} />
              </div>

              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <label>Fee</label>
                <input type="number" 
                    className="form-control" 
                    placeholder="Fee" 
                    name="Fee" 
                    value={this.state.FormGroup.Fee} 
                    onChange={this.handleInputChange} />
              </div>

              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <label>Fee Date</label>
                <input type="date" 
                    className="form-control" 
                    placeholder="Fee Date" 
                    name="feeDate" 
                    value={this.state.FormGroup.feeDate} 
                    onChange={this.handleInputChange} />
              </div>

              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <label>Fine</label>
                <input type="number" 
                    className="form-control" 
                    placeholder="Fine" 
                    name="Fine" 
                    value={this.state.FormGroup.Fine} 
                    onChange={this.handleInputChange} />
              </div>
              

              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <label>Remark</label>
                <input type="text" 
                    className="form-control" 
                    placeholder="Remark" 
                    name="Remark" 
                    value={this.state.FormGroup.Remark} 
                    onChange={this.handleInputChange} />
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
                  <button type="submit" className="btn btn-primary">Submit</button>
              </div>

          </form>

      </div>

    );
  }
}

function mapStateToProps(state) {
  return ({
    feeSetupDetail: state.feeSetupReducer.feeSetupDetail
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      getFeeSetupList: () => {
        dispatch(getFeeSetupList());
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(false));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(FeeSetupForm);