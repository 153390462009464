import axios from 'axios';

export function getTeacherDetail(id) {
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/getTeacherById/${id}`)
        .then(response =>

            getTeacherDetailRes(dispatch, response)

        ).catch(err => console.log(err));
    }
}

function getTeacherDetailRes(dispatch, response) {
    
    try {
       if(response.data.resType === 'success'){

            return dispatch({
                type: 'UPDATE_TEACHER_DETAIL',
                payload: response.data.teacher
            })
       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}

export function getTeacherList() {
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/getTeacher`)
        .then(response =>

            getTeacherListRes(dispatch, response)

        ).catch(err => console.log(err));
    }
}

function getTeacherListRes(dispatch, response) {
   
    try {
       if(response.data.resType === 'success'){

            return dispatch({
                type: 'UPDATE_TEACHER_LIST',
                payload: response.data.teacher
            })
       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data.message
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}

export function showHideForm(data) {
  
    return dispatch => {

        return dispatch({
            type: 'SHOW_HIDE_TEACHER_FORM',
            payload: data
        })
    }
}

export function showHideCardView(data) {
  
    return dispatch => {

        return dispatch({
            type: 'SHOW_HIDE_TEACHER_CARDVIEW',
            payload: data
        })
    }
}




