const INITIAL_STATE = {
  marksLoading: true,
  marksList: [],
  isFilter: true
}

export default (states = INITIAL_STATE, action) => {

switch (action.type) {

      case 'UPDATE_MARKS_SHEET_LIST':
          return ({
              ...states,
              marksList: action.payload,
              marksLoading: false,
              isFilter: false
          });

          case 'UPDATE_HOME_WORK_FILTER_FLAG':
          return({
              ...states,
              isFilter:action.payload
          });

    
      default:
        return states;

}
}
