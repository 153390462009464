import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {getParentList, showHideForm} from '../../../../store/action/ParentMasterAction';
import {showMessage} from '../../../../store/action/MessageAction';
import StateDrop from '../../../utils/StateDrop';
import CityDrop from '../../../utils/CityDrop';
import ReligionDrop from '../../../utils/ReligionDrop';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class ParentForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      parentId:0,
      isUpdate:false,
      images: [],
      FormGroup: {
        gardiunName: '',
        fatherName: '',
        Profession: '',
        motherName: '',
        Email: '',
        Phone: '',
        nationalId: '',
        religionId: '0',
        stateId: '0',
        cityId: '0',
        Address: '',
        Password: ''
      }
    };

  }

  clearForm = () => {
    const UserInput = {};
    
        UserInput['gardiunName'] = '';
        UserInput['fatherName'] = '';
        UserInput['Profession'] = '';
        UserInput['motherName'] = '';
        UserInput['Email'] = '';
        UserInput['Phone'] = '';
        UserInput['nationalId'] = '';
        UserInput['religionId'] = '0';
        UserInput['stateId'] = '0';
        UserInput['cityId'] = '0';
        UserInput['Address'] = '';
        UserInput['Password'] = '';

    this.setState({
        FormGroup: UserInput,
        parentId:0,
        isUpdate: false
    });
    
  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

  }

  selectImages = (event) => {
    let images = []
    for (var i = 0; i < event.target.files.length; i++) {
      images[i] = event.target.files.item(i);
    }
    images = images.filter(image => image.name.match(/\.(jpg|jpeg|png|gif|rar)$/))
    //let message = `${images.length} valid image(s) selected`
    this.setState({ images })

  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevProps.parentDetail !== this.props.parentDetail) {
      if(this.props.parentDetail !== ''){

        this.setState({
          FormGroup: this.props.parentDetail,
          parentId:this.props.parentDetail._id,
          isUpdate: true
        })

      }
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData();

    this.state.images.forEach(image => {
      data.append("image", image, image.name);
    });

     data.append('gardiunName',this.state.FormGroup.gardiunName);
     data.append('fatherName',this.state.FormGroup.fatherName);
     data.append('Profession',this.state.FormGroup.Profession);
     data.append('motherName',this.state.FormGroup.motherName);
     data.append('Email',this.state.FormGroup.Email);
     data.append('Phone',this.state.FormGroup.Phone);
     data.append('nationalId',this.state.FormGroup.nationalId);
     data.append('religionId',this.state.FormGroup.religionId);
     data.append('stateId',this.state.FormGroup.stateId);
     data.append('cityId',this.state.FormGroup.cityId);
     data.append('Address',this.state.FormGroup.Address);
     data.append('Password',this.state.FormGroup.Password);

   
    let url = `${process.env.PUBLIC_URL}/api/SaveParent`;

    if(this.state.isUpdate){
      url = `${process.env.PUBLIC_URL}/api/updateParentById/${this.state.parentId}`;
    }

    axios.post(url, data)
    .then(res => {

        if(res.data.resType === 'success'){
          this.clearForm();
          this.props.getParentList();
        }

        this.props.showMessage({
          messageType: res.data.resType,
          Message:res.data.message
        });

     }).catch(err => console.log(err));

  }

render() {

    return (
      <div className='form-modle'>
          
          <form onSubmit={this.handleSubmit} style={{maxWidth: '800px'}}>
            <h2 className="head_h2"> Parents </h2>
            <i onClick={this.props.showHideForm} className="fas fa-arrow-left close-btn"></i>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
              <label>Gardiun Name</label>
              <input type="text" 
                    className="form-control" 
                    placeholder="Gardiun Name" 
                    name="gardiunName" 
                    value={this.state.FormGroup.gardiunName} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
              <label>Father Name</label>
              <input type="text" 
                    className="form-control" 
                    placeholder="fatherName" 
                    name="fatherName" 
                    value={this.state.FormGroup.fatherName} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
              <label>Profession</label>
              <input type="text" 
                    className="form-control" 
                    placeholder="Profession" 
                    name="Profession" 
                    value={this.state.FormGroup.Profession} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
              <label>Mother Name</label>
              <input type="text" 
                    className="form-control" 
                    placeholder="Mother Name" 
                    name="motherName" 
                    value={this.state.FormGroup.motherName} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
              <label>Email</label>
              <input type="email" 
                    className="form-control" 
                    placeholder="Email" 
                    name="Email" 
                    value={this.state.FormGroup.Email} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
              <label>Phone</label>
              <input type="text" 
                    className="form-control" 
                    placeholder="Phone" 
                    name="Phone" 
                    value={this.state.FormGroup.Phone} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
              <label>National Id</label>
              <input type="text" 
                    className="form-control" 
                    placeholder="National Id" 
                    name="nationalId" 
                    value={this.state.FormGroup.nationalId} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Religion</label>
                <ReligionDrop 
                  ReligionId={this.state.FormGroup.religionId} 
                  func={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>State</label>
                <StateDrop 
                  StateId={this.state.FormGroup.stateId} 
                  func={this.handleInputChange}/>
            </div>


            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>City</label>
                <CityDrop 
                  StateId={this.state.FormGroup.stateId} 
                  CityId={this.state.FormGroup.cityId} 
                  func={this.handleInputChange}/>
            </div>

             <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
             <label>Address</label>
              <input type="text" 
                    className="form-control" 
                    placeholder="Address" 
                    name="Address" 
                    value={this.state.FormGroup.Address} 
                    onChange={this.handleInputChange} />
            </div>

             <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
             <label>Password</label>
              <input type="text" 
                    className="form-control" 
                    placeholder="Password" 
                    name="Password" 
                    value={this.state.FormGroup.Password} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-3 col-md-3 col-lg-3 float-left">
                <label>Photo</label>
                <input className="form-control " type="file" onChange={this.selectImages} />
                <p className="text-info">{this.state.message}</p>
            </div>

            <div className="col-sm-4 col-md-4 col-lg-4" style={{clear:"both"}}>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>

          </form>

      </div>

      
       
    );
  }
}

function mapStateToProps(state) {
  return ({
    parentDetail: state.parentMasterReducer.parentDetail
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      getParentList: () => {
        dispatch(getParentList());
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(false));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(ParentForm);

