import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {getTransportList, getTransportDetail, showHideForm} from '../../../../store/action/AddTransportAction';
import {showMessage} from '../../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';


class TransportList extends Component {

  componentDidMount() {
    this.props.changeStateToReducer();
  }

  deleteTransport = (id) => {
    
    axios.delete(`${process.env.PUBLIC_URL}/api/deleteTransport/${id}`)
    .then(res => {

      if(res.data.resType === 'success') {
        this.props.changeStateToReducer();
      }

      this.props.showMessage({
        messageType: res.data.resType,
        Message:res.data.message
      });

    }).catch(err => console.log(err));
  }

  getTransportDetail = (id) => {
    this.props.getTransportDetail(id);
    this.props.showHideForm();
  }
  
render() {

  const renderTable  = (List) =>{
    
    return(
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Destination</th>
              <th>VehicleNo</th>
              <th>Branch</th>
              <th>Remark</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {List.map((item, index) => 
              <tr key={index}>
                <td>{item.Destination}</td>
                <td>{item.VehicleNo}</td>
                <td>{item.branchId === null ? null : item.branchId.branchName}</td>
                <td>{item.Remark}</td>
                <td>
                  <button className="btn btn-sm btn-info" onClick={this.getTransportDetail.bind(this, item._id)}>Edit</button>
                  <button className="btn btn-sm btn-danger" onClick={this.deleteTransport.bind(this, item._id)}>Delete</button>
                </td>
              </tr>
            )}
          </tbody>
        </table>

    );

  }

  const Transport = this.props.transportloading ? <div className="loader"><img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} /></div> : renderTable(this.props.transportList)

    return (

      <React.Fragment>
        {Transport}
      </React.Fragment>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    transportList: state.addTransportReducer.transportList,
    transportloading:state.addTransportReducer.transportloading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      changeStateToReducer: () => {
        dispatch(getTransportList());
      },
      getTransportDetail: (id) => {
        dispatch(getTransportDetail(id));
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(TransportList);


