import React, { Component } from 'react';
import { connect } from 'react-redux';
import {showHideForm, updateSelectFlag, updateStudentList} from './Action';
import {hideMessage} from '../../../../store/action/MessageAction';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import {SchoolLogo} from '../../../utils/SchoolLogo';
import Navbar from '../../../Navbar';
import {ReactMessage} from '../../../utils/ReactMessage';
import {CheckSession} from '../../../utils/CheckSession';

import HomeWorkForm from './Form';
import StudentCardView from './CardView';
import StudentFilter from './filter';

class AssignHomeWork extends Component {

  constructor(props){
    super(props);
    CheckSession(this.props);
    this.state={
      isAllSelected: false
    }
  }

  selectAll = () => {

    let list = [];
    
    for(let i=0; i < this.props.studentList.length; i++){
      const item = this.props.studentList[i];
      item.isSelect = !this.state.isAllSelected;
      list.push(item);
    }

    this.setState({
      isAllSelected: !this.state.isAllSelected
    })

    this.props.updateStudentList(list);
    //this.forceUpdate();
  }

  showHideSelect = () => {
    if(this.props.isSelectedFlag){
      this.props.updateSelectFlag(false);
    }
    else{
      this.props.updateSelectFlag(true);
    }
    
  }

  showHideForm = () =>{
    this.props.showHideForm();
  }


render() {

    const selectedStudent = [...new Set( this.props.studentList.map(obj => obj.isSelect)) ];

    console.log(selectedStudent);

    return (

      <div className="container mp0">

        <SchoolLogo SchoolName="Inzeal Infotech" />

        {this.props.messageShow ? <ReactMessage func={this.props.hideMessage} messageType={this.props.messageType} Message={this.props.Message} /> : null}

        <Navbar />

        {this.props.isFilter ? <div className="form-wrapper">
          <h2>Home Work</h2>
          <StudentFilter />
          
        </div>

        :<div className="form-wrapper" style={{width:"100%"}}>

          <h2 style={{paddingLeft:"36px"}}> Back 
            <i onClick={this.props.updateFilterFlag} className="fas fa-arrow-left close-btn2"></i>

            <span onClick={this.showHideSelect} style={{margin:'0px 4px', float: "right"}}>
              {this.props.isSelectedFlag ? <i className="far fa-times-circle icon_style"></i> : 
              <i className="far fa-check-square icon_style"><span style={{fontSize: "20px"}}> Select</span></i>}
            </span>
    
            {this.props.isSelectedFlag ? 
              <React.Fragment>
                <span onClick={this.selectAll} style={{margin:'0px 4px', float: "right"}}>
                  {selectedStudent.includes(true) ? 
                  <i className="far fa-check-square icon_style"></i> : 
                  <i className="far fa-square icon_style"></i>}
                </span>
                {selectedStudent.includes(true) ? <span onClick={this.showHideForm} style={{margin:'0px 4px', float: "right"}}>
                  <i className="far fa-edit icon_style"></i>
                </span>: null}
              </React.Fragment>: null}
          </h2>

          

          {this.props.isFormShow ? <HomeWorkForm /> : null}
          
          <StudentCardView />
            
        </div>}
       
      </div>

    );
  }
}

function mapStateToProps(state) {
    return ({
      messageShow: state.messageReducer.messageShow,
      messageType: state.messageReducer.messageType,
      Message: state.messageReducer.Message,
      isFormShow: state.assignHomeWork.isStudentFormShow,
      isCardViewShow: state.assignHomeWork.isStudentCardViewShow,
      studentList: state.assignHomeWork.studentList,
      isSelectedFlag: state.assignHomeWork.isSelectedFlag,
      isFilter: state.assignHomeWork.isFilter
    });
}

function mapDispatchToProps(dispatch) {
  return ({
      hideMessage: () => {
        dispatch(hideMessage());
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      },
      updateSelectFlag: (data) =>{
        dispatch(updateSelectFlag(data));
      },
      updateStudentList: (list) => {
        dispatch(updateStudentList(list));
      },
      updateFilterFlag: () => {
        dispatch({
          type: 'UPDATE_HOME_WORK_FILTER_FLAG',
          payload: true
        });
      }
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignHomeWork);

