import React, { useState, useEffect }from 'react';
import axios from 'axios';

export const TransportDrop = ({BranchId, TransportId, func}) => {

    const [transportList, setTransportList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log("trans-----",BranchId)
        fillTransport(BranchId);
    }, [BranchId]);

    const fillTransport = (id) => {
        if(id === '' || id === null){
            id = '0'
        }
        
        axios.get(`${process.env.PUBLIC_URL}/api/getTransportDrop/${id}`)
        .then(response => {
            if(response.data.resType === 'success'){
                setTransportList(response.data.transport)
                setLoading(false)
            }
            
        });
    }

    const renderlist = (List) => {
            
        return (
            <select name="transportId" value={TransportId} onChange={func} className="form-control">
                <option value="">Select Transport</option>
                {List.map(C =>
                    <option key={C._id} value={C._id}>{C.VehicleNo} --/- {C.Destination}</option>
                )}
            </select>
        )
    }

    let Drop = loading
        ? <p><em>Loading...</em></p>
        : renderlist(transportList);

    return (
        <div>
            {Drop}
        </div>
    )

}
