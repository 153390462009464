import React, { Component } from 'react';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import {AttendanceService} from '../../../Services/master.js'
import {SchoolLogo} from '../../utils/SchoolLogo';
import Navbar from '../../Navbar';
import {CheckSession} from '../../utils/CheckSession';
import {ReactMessage} from '../../utils/ReactMessage';
import ClassDrop from '../../utils/ClassDrop';
import BranchDrop from '../../utils/BranchDrop';
import {User} from '../../utils/ValidateUser';
import {currentDate} from '../../utils/CurrentDate';

export class Attendance extends Component {

  constructor(props){
    super(props);
    CheckSession(this.props);
  }

  state = {
    alertMessage:{messageShow:false, messageType:'success', Message: ''},
    studentList:[],
    formGroup:{classId:'', attendanceDate: currentDate(), branchId:''}
  }

  //fill class wise student list 

  getStudent = (date, branchId, classId) => {
    if(date === ""){
      date=currentDate();
    }

    if(branchId === ""){
      branchId="0";
    }

    if(classId !== ""){
      AttendanceService.getStudentAttendanceList(date, branchId, classId).then(res => {
        console.log(res.data);
        if(res.data.resType === 'success'){
          var list = res.data.student;
          
          this.setState({
            studentList:list,
            alertMessage:{messageShow:true, messageType:res.data.resType, Message: res.data.message}
          });
  
        }
        else{
          this.setState({
            alertMessage:{messageShow:true, messageType:res.data.resType, Message: res.data.message}
          });
        }
      });
    }
    else{
      this.setState({
        studentList:[],
        alertMessage:{messageShow:true, messageType:'failed', Message: "select class"}
      });
    }
    
  }

  //select a class 
  handleInputChange = (e) => {
    
    let name = e.target.name;
    let value = e.target.value;
    const userInput = this.state.formGroup;
    userInput[name] = value;

    this.setState({
      formGroup:userInput
    });

    const {attendanceDate, branchId, classId} = this.state.formGroup

    this.getStudent(attendanceDate, branchId, classId);

  }

  hideMessage = () => {
    this.setState({
      alertMessage:{messageShow:false, messageType:'success', Message: ''}
    });
  }

  // check uncheck checkboxes
  checkboxChange = (e, index) => {
   
    let list = this.state.studentList;

    if(list[index].isPresent){
      list[index].isPresent = false
    }
    else{
      list[index].isPresent = true
    }

    this.setState({
      studentList:list
    });

  }

  handleSubmit = () => {
    const {classId, attendanceDate} = this.state.formGroup;

    let url = `${process.env.PUBLIC_URL}/api/SaveAttendance/${classId}&${attendanceDate}`;
    const list = this.state.studentList;

    AttendanceService.saveAttendance(url, list).then(res => {
      
      this.setState({
        alertMessage:{messageShow:true, messageType:res.data.resType, Message: res.data.message}
      });

    })
  }

  render(){

    const renderTable  = (List) => {

      return(
        <React.Fragment>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Student Code</th>
                <th>Student Name</th>
                <th>Present</th>
              </tr>
            </thead>
            <tbody>
              {List.map((item, index) => 
                
                <tr key={index}>
                  <td>{item.studentCode}</td>
                  <td>{item.studentName}</td>
                  <td><input type="checkbox" onChange={(e) => {this.checkboxChange(e, index)}} checked={item.isPresent} /></td>
                </tr>
  
              )}
            </tbody>
          </table>
  
          <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
              <button onClick={this.handleSubmit} className="btn btn-primary">Submit</button>
          </div>
        </React.Fragment>
      );
  
    }

    const Student = renderTable(this.state.studentList);

    return (
    
    <div className="container mp0">

      <SchoolLogo SchoolName="Inzeal Infotech" />

      {this.state.alertMessage.messageShow ? 
        <ReactMessage func={this.hideMessage} 
        messageType={this.state.alertMessage.messageType} 
        Message={this.state.alertMessage.Message} /> : null}

      <Navbar />

      
        <div className="form-wrapper" style={{width:"100%"}}>
            <h2>Attendance</h2>
            <div className="row">
              <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Date</label>
                <input 
                  type="date" 
                  name="attendanceDate" 
                  value={this.state.formGroup.attendanceDate} 
                  onChange={this.handleInputChange}
                  className="form-control" /> 
              </div>

              {User.isAdmin() ? 
              <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                  <label>Branch</label>
                  <BranchDrop 
                    BranchId={this.state.formGroup.branchId} 
                    func={this.handleInputChange} />
              </div>: null}

              <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Class</label>
                <ClassDrop 
                  InputName='classId' 
                  ClassId={this.state.formGroup.classId} 
                  func={this.handleInputChange} /> 

              </div>
            </div>
            {Student}
        </div>
      </div>
   
  );}
}
