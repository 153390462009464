import React, { Component } from 'react';

export default class TermDrop extends Component {

    constructor(props) {

      super(props);
      this.state = {
          TermList: ["Half Yearly", "Yearly"],
          loading: false,
          Message: 'wait....!'
      };

      //this.fillDesignation();
        
    }


    // fillDesignation = () => {

    //   axios.get(`${process.env.PUBLIC_URL}/api/getDesignationDrop`)
    //   .then(response => {
    //     this.setState({ DesignationList: response.data.designation, loading: false });
      // });

    // }
    
    render() {

      const renderlist = (List) => {
        
        return (
          <select name="term" value={this.props.Term} onChange={this.props.func} className="form-control" 
          required={this.props.isRequred}>
            <option value="">Select</option>
            {List.map(D =>
              <option key={D} value={D}>{D}</option>
            )}
          </select>
        );
      }
        
      let Droplist = this.state.loading
          ? <p><em>Loading...</em></p>
          : renderlist(this.state.TermList);
        
      return (
          
          <div>
              {Droplist}
          </div>
              
    );
  }
}

TermDrop.defaultProps = {
  Term: "",
  isRequred: false
}
