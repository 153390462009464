import React, { Component } from 'react';
import axios from 'axios';

export default class ParentDrop extends Component {

    constructor(props) {

        super(props);
        this.state = {
            ParentList: [],
            loading: true,
            Message: 'wait....!'
        };

        axios.get(`${process.env.PUBLIC_URL}/api/getParentDrop`)
        .then(response => {
            this.setState({ ParentList: response.data.parent, loading: false });
        });

    }

    render(){

        const renderlist = (List) => {
            return (
                <select name={this.props.InputName} 
                    value={this.props.ParentId} 
                    onChange={this.props.func} 
                    className="form-control">

                    <option value="">Select</option>
                    {List.map(S =>
                        <option key={S._id} value={S._id}>{S.fatherName} -/- {S.motherName}</option>
                    )}
                </select>
            );
        }

        let ParentList = this.state.loading
            ? <p><em>Loading...</em></p>
            : renderlist(this.state.ParentList);

        return (
            <div>
                {ParentList}
            </div>
                
        );
    }
}

ParentDrop.defaultProps = {
    ParentId: "0",
    InputName: "parentId"
}
