import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {getDesignationList, showHideForm} from '../../../../store/action/DesignationAction';
import {showMessage} from '../../../../store/action/MessageAction';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class DesignationForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      designationId:0,
      isUpdate:false,
      FormGroup: {
        designationName: ''
      }
    };

  }

  clearForm = () => {
    const UserInput = {};
    UserInput['designationName'] = '';

    this.setState({
        FormGroup: UserInput,
        designationId:0,
        isUpdate: false
    });
    
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevProps.designationDetail !== this.props.designationDetail) {
      this.setState({
        FormGroup:this.props.designationDetail
      })

      if(this.props.designationDetail !== ''){
        this.setState({
          designationId:this.props.designationDetail._id,
          isUpdate: true
        })
      }

    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    
    const Designation = this.state.FormGroup;
   
    let url = `${process.env.PUBLIC_URL}/api/SaveDesignation`;

    if(this.state.isUpdate){
      url = `${process.env.PUBLIC_URL}/api/updateDesignationById/${this.state.designationId}`;
    }

    axios.post(url, Designation)
    .then(res => {

        if(res.data.resType === 'success'){
          this.clearForm();
          this.props.getDesignationList();
        }

        this.props.showMessage({
          messageType: res.data.resType,
          Message:res.data.message
        });

     }).catch(err => console.log(err));

  }

render() {

    return (
      <div className='form-modle'>
          
          <form onSubmit={this.handleSubmit}>
            <h2 className="head_h2"> Designation </h2>
            <i onClick={this.props.showHideForm} className="fas fa-arrow-left close-btn"></i>


            <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
              <input type="text" 
                  className="form-control" 
                  placeholder="Designation Name" 
                  name="designationName" 
                  value={this.state.FormGroup.designationName} 
                  onChange={this.handleInputChange} />
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>

          </form>

      </div>

    );
  }
}

function mapStateToProps(state) {
  return ({
    designationDetail: state.designationReducer.designationDetail
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      getDesignationList: () => {
        dispatch(getDesignationList());
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(false));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(DesignationForm);

