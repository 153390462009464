import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {getSubjectList, getSubjectDetail, showHideForm} from './Action';
import {showMessage} from '../../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';


class SubjectList extends Component {

  componentDidMount() {
    this.props.changeStateToReducer();
  }

  deleteSubject = (id) => {
    
    axios.delete(`${process.env.PUBLIC_URL}/api/deleteSubject/${id}`)
    .then(res => {

      if(res.data.resType === 'success'){
        this.props.changeStateToReducer();
      }

      this.props.showMessage({
        messageType: res.data.resType,
        Message:res.data.message
      });

    }).catch(err => console.log(err));
  }

  getSubjectDet = (id) =>{
    this.props.getSubjectDetail(id);
    this.props.showHideForm();
  }

  
render() {

  const renderTable  = (List) =>{
    
    return(
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Subject Name</th>
              <th>Group Name</th>
              <th>Class Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {List.map((item, index) => 

              <tr key={index}>
                <td>{item.subjectName}</td>
                <td>{item.subjectGroupId.subjectGroupName}</td>
                <td>{item.classId.className}</td>
                <td>
                  <button className="btn btn-sm btn-info" onClick={this.getSubjectDet.bind(this, item._id)}>Edit</button>
                  <button className="btn btn-sm btn-danger" onClick={this.deleteSubject.bind(this, item._id)}>Delete</button>
                </td>
              </tr>
              
            )}
          </tbody>
        </table>

    );

  }

  const SubjectList = this.props.subjectLoading ? <div className="loader">
    <img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} />
    </div> : renderTable(this.props.subjectList);
  
    return (

      <React.Fragment>
        {SubjectList}
      </React.Fragment>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    subjectList: state.subjectsReducer.subjectList,
    subjectLoading:state.subjectsReducer.subjectLoading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      changeStateToReducer: () => {
        dispatch(getSubjectList());
      },
      getSubjectDetail: (id) => {
        dispatch(getSubjectDetail(id));
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      }
  });
}



export default connect(mapStateToProps,mapDispatchToProps)(SubjectList);


