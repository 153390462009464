import React, { Component } from 'react';
import { connect } from 'react-redux';
import {showMessage} from '../../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class CardView extends Component {

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  


render() {


  const Card  = (List) => {
    
    return(
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Subject</th>
                <th>Min Marks</th>
                <th>Max Marks</th>
                <th>Obtained Marks</th>
              </tr>
            </thead>
            <tbody>
              {List.map((item, index) => 
                <tr key={index}>
                  <td>{item.subjectName}</td>
                  <td>{item.minMarks}</td>
                  <td>{item.maxMarks}</td>
                  <td>{item.obtainedMarks}</td>
                </tr>
              )}
            </tbody>
          </table>

    );
  }

  const Student = this.props.marksLoading ? <i>No Data</i> : Card(this.props.marksList);

  console.log(this.props.marksList);

    return (

      <div style={{overflow: "auto"}}>
        {Student}
      </div>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    marksList: state.markSheetReducer.marksList,
    marksLoading: state.markSheetReducer.marksLoading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      showMessage: (data) => {
        dispatch(showMessage(data));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(CardView);