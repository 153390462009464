import React, { Component } from 'react';
import { connect } from 'react-redux';

class StudentDetail extends Component {

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    console.log(name, value)

  }
  
render() {

  const renderTable  = (List) => {
    
    return(
        <div className="row">

            <div className="col-sm-2 col-md-2 col-lg-2 float-left">
              <img src={process.env.PUBLIC_URL+"/Students/ProfilePhoto/"+List[0].photoPath} 
                alt="Card" style={{width:"100%"}} />
            </div>

            <div className="col-sm-10 col-md-10 col-lg-10 float-left">
            <div className="row">
              <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Name</label>
                <input type="text" 
                    className="form-control" 
                    placeholder="Name" 
                    name="studentName" 
                    onChange={this.handleInputChange} 
                    value={List[0].studentName} /> 
              </div>

              <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Class</label>
                <input type="text" 
                    className="form-control" 
                    placeholder="className" 
                    onChange={this.handleInputChange} 
                    name="className" 
                    value={List[0].classId === null ? null : List[0].classId.className} /> 
              </div>

              <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Father Name</label>
                <input type="text" 
                    className="form-control" 
                    placeholder="fatherName" 
                    onChange={this.handleInputChange} 
                    name="fatherName" 
                    value={List[0].parentId === null ? null : List[0].parentId.fatherName} /> 
              </div>

              <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Mother Name</label>
                <input type="text" 
                    className="form-control" 
                    placeholder="Mother Name" 
                    onChange={this.handleInputChange} 
                    name="motherName" 
                    value={List[0].parentId === null ? null : List[0].parentId.motherName} /> 
              </div>
              </div>
            </div>
        </div>
    );
  }

  const studentDetail = this.props.studentChargeloading ? 
        <div className="loader"><img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" 
        style={{width:"200px"}} /></div> : renderTable(this.props.studentDetail)

    return (

      <React.Fragment>
        {studentDetail}
      </React.Fragment>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    studentDetail: state.studentChargeReducer.studentFilterDetail,
    studentChargeloading:state.studentChargeReducer.studentChargeloading
  });
}

export default connect(mapStateToProps)(StudentDetail);


