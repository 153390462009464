const INITIAL_STATE = {
    isStudentFormShow: false,
    isStudentCardViewShow:false,
    studentLoading:true,
    studentDetail:'',
    studentList:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_STUDENT_DETAIL':
          return ({
              ...states,
            studentDetail: action.payload
          });

        case 'UPDATE_STUDENT_LIST':
            return ({
                ...states,
                studentList: action.payload,
                studentLoading:false
            });

        case 'SHOW_HIDE_STUDENT_FORM':
            return({
                ...states,
                isStudentFormShow:action.payload
            });

        case 'SHOW_HIDE_STUDENT_CARDVIEW':
            return({
                ...states,
                isStudentCardViewShow:action.payload
            });

        default:
          return states;

  }
}
