const INITIAL_STATE = {
  studentId: '',
  subjectId: '',
  isStudentFormShow: false,
  studentLoading: true,
  studentList: [],
  homeWorkList: [],
  isSelectedFlag: false,
  isFilter: true
}

export default (states = INITIAL_STATE, action) => {
  
switch (action.type) {

      case 'UPDATE_HOMEWORK_STUDENT_LIST':
          return ({
              ...states,
              studentList: action.payload,
              studentLoading: false,
              isFilter: false
          });

      case 'SHOW_HIDE_ASSIGN_HOMEWORK_FORM':
          return({
              ...states,
              isStudentFormShow:action.payload
          });

      case 'UPDATE_STUDENT_ID_FOR_HOMEWORK':
          return({
              ...states,
              studentId:action.payload
          });

      case 'UPDATE_SUBJECT_ID_FOR_HOMEWORK':
          return({
              ...states,
              subjectId:action.payload
          });

      case 'UPDATE_HOME_WORK_LIST':
          return({
              ...states,
              homeWorkList:action.payload
          });

      case 'UPDATE_SELECTED_FLAG_FOR_HOMEWORK':
          return({
              ...states,
              isSelectedFlag:action.payload
          });

          case 'UPDATE_HOME_WORK_FILTER_FLAG':
          return({
              ...states,
              isFilter:action.payload
          });

    
      default:
        return states;

}
}
