const INITIAL_STATE = {
  branchId: '',
  classId: '',
  term: '',
  subjectLoading: true,
  subjectList: [],
  isFilter: true
}

export default (states = INITIAL_STATE, action) => {

switch (action.type) {

      case 'UPDATE_MARKS_SETUP_SUBJECT_LIST':
          return ({
              ...states,
              subjectList: action.payload,
              subjectLoading: false,
              isFilter: false
          });

      case 'UPDATE_MARKS_SETUP_HEAD_ID':
          return({
              ...states,
              branchId: action.payload.branchId,
              classId: action.payload.classId,
              term: action.payload.term
          });

          case 'UPDATE_HOME_WORK_FILTER_FLAG':
          return({
              ...states,
              isFilter:action.payload
          });

    
      default:
        return states;

}
}
