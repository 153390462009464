import React, { Component } from 'react';
import { connect } from 'react-redux';
import {showHideForm} from './Action';
import {showMessage} from '../../../store/action/MessageAction';
//import * as $ from 'jquery';
import {GoogleAdd} from '../GoogleAdd';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class ResultList extends Component {

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  render() {
    const Card  = (List) => {
    
      return(

        <React.Fragment>
          {List.length > 0 ? <React.Fragment>
          {List.map((item, index) => 
            <div key={index}>
              <p style={{textAlign: "center", fontWeight: "bold"}}>{item._id}</p>
              <div style={{overflow: "auto"}}>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Subject</th>
                      <th>Max Marks</th>
                      <th>Min Marks</th>
                      <th>Obtained Marks</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.result.map((item2, index2) => 

                      <tr key={index2}>
                        <td>{item2.subjectName}</td>
                        <td>{item2.maxMarks}</td>
                        <td>{item2.minMarks}</td>
                        <td>{item2.obtainedMarks}</td>
                        <td>{item2.minMarks <= item2.obtainedMarks ? 'Pass' : 'Fail'}</td>
                      </tr>
                      
                    )}
                  </tbody>
                </table>
              </div>
            </div> 
          )}</React.Fragment> : 'Uploading Soon....'}
        </React.Fragment>

      );
    }

  const Result = this.props.Loading ? <i>Loading</i> : Card(this.props.resultList);
 
    return (

      <React.Fragment>
        <div>
          {Result}
          <div className="col-sm-12 col-md-12 col-lg-12">
            <GoogleAdd />
          </div>
        </div>
      </React.Fragment>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    resultList: state.parentExamination.List,
    Loading: state.parentExamination.Loading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(ResultList);