import axios from 'axios';

export function getWardList() {
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/parent/getWardList`)
        .then(response =>

            getWardListRes(dispatch, response)

        ).catch(err => console.log(err));
    }
}

function getWardListRes(dispatch, response) {
    
    try {
       if(response.data.resType === 'success'){

            return dispatch({
                type: 'UPDATE_WARD_LIST',
                payload: response.data.student
            })
       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data.message
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}

export function showHideCardView(data) {
  
    return dispatch => {

        return dispatch({
            type: 'SHOW_HIDE_WARD_CARDVIEW',
            payload: data
        })
    }
}




