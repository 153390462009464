import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getStudentList, updateHeadId} from './Action';
import ClassDrop from '../../../utils/ClassDrop';
import BranchDrop from '../../../utils/BranchDrop';
import TermDrop from '../../../utils/TermDrop';
import SubjectDrop from '../../../utils/SubjectDrop';
import {User} from '../../../utils/ValidateUser';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class Filter extends Component {

  constructor(props){
    super(props);
    this.state = {
      FormGroup: {
        branchId: '', 
        classId: '', 
        subjectId: '',
        term: ''
      }
    };
  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

  }

  filterList = (event) => {
    event.preventDefault();

    let {branchId, classId, subjectId, term} = this.state.FormGroup;

    this.props.applyFilter(branchId, classId, subjectId, term);
    this.props.updateHeadId({branchId: branchId, classId: classId, subjectId:subjectId, term: term})

  }

render() {

    return (
      <React.Fragment>
        <form className="row" onSubmit={this.filterList}>
        {User.isAdmin() ? 
        <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
            <label>Branch</label>
            <BranchDrop 
              isRequred={true}
              BranchId={this.state.FormGroup.branchId} 
              func={this.handleInputChange} />
        </div>: null}

        <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
          <label>Class</label>
          <ClassDrop 
            isRequred={true}
            InputName='classId' 
            ClassId={this.state.FormGroup.classId} 
            func={this.handleInputChange} />
        </div>

        <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
          <label>Subject</label>
          <SubjectDrop 
            isRequred={true}
            InputName='subjectId' 
            ClassId={this.state.FormGroup.classId} 
            BranchId={this.state.FormGroup.branchId}
            SubjectId={this.state.FormGroup.subjectId} 
            func={this.handleInputChange} />
        </div>

        <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
          <label>Term</label>
          <TermDrop 
            isRequred={true}
            Term={this.state.FormGroup.term}
            func={this.handleInputChange} />
        </div>

        <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
            <button type="submit" className="btn btn-primary">Search</button>
        </div>
        </form>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return ({
    isFilter: state.marksAssignReducer.isFilter
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      applyFilter: (branchId, classId, subjectId, term) => {
        dispatch(getStudentList(branchId, classId, subjectId, term));
      },
      updateHeadId: (data) => {
        dispatch(updateHeadId(data));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(Filter);
