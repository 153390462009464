import React, { Component } from 'react';
import axios from 'axios';

export default class ReligionDrop extends Component {

    constructor(props) {

        super(props);
        this.state = {
            ReligionList: [],
            loading: true,
            Message: 'wait....!'
        };

        axios.get(`${process.env.PUBLIC_URL}/api/getReligion`)
        .then(response => {
            this.setState({ ReligionList: response.data.religion, loading: false });
        });

    }

    render(){

        const renderlist = (ReligionList) => {
            return (
                <select name='religionId' value={this.props.ReligionId} onChange={this.props.func} className="form-control">
                    <option value="">Select</option>
                    {ReligionList.map(S =>
                        <option key={S._id} value={S._id}>{S.religionName}</option>
                    )}
                </select>
            );
        }

        let Religionlist = this.state.loading
            ? <p><em>Loading...</em></p>
            : renderlist(this.state.ReligionList);

        return (
            <div>
                {Religionlist}
            </div>
                
        );
    }
}

ReligionDrop.defaultProps = {
    ReligionId: "0"
}
