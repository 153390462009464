import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {getSubjectList, showHideForm} from './Action';
import {showMessage} from '../../../../store/action/MessageAction';

import {User} from '../../../utils/ValidateUser';
import BranchDrop from '../../../utils/BranchDrop';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class SubjectForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      subjectGroupId:0,
      isUpdate:false,
      FormGroup: {
        branchId: '',
        subjectGroupName: ''
      }
    };

  }

  clearForm = () => {
    const UserInput = {};
    UserInput['branchId'] = '';
    UserInput['subjectGroupName'] = '';

    this.setState({
        FormGroup: UserInput,
        subjectGroupId: 0,
        isUpdate: false
    });
    
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevProps.subjectDetail !== this.props.subjectDetail) {
      this.setState({
        FormGroup:this.props.subjectDetail
      })

      if(this.props.subjectDetail !== ''){
        this.setState({
          subjectGroupId:this.props.subjectDetail._id,
          isUpdate: true
        })
      }

    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    
    const Subjects = this.state.FormGroup;
   
    let url = `${process.env.PUBLIC_URL}/api/SaveSubjectGroup`;

    if(this.state.isUpdate){
      url = `${process.env.PUBLIC_URL}/api/updateSubjectGroupById/${this.state.subjectGroupId}`;
    }

    axios.post(url, Subjects)
    .then(res => {

        if(res.data.resType === 'success'){
          this.clearForm();
          this.props.getSubjectList();
        }

        this.props.showMessage({
          messageType: res.data.resType,
          Message:res.data.message
        });

     }).catch(err => console.log(err));

  }

render() {

    return (
      <div className='form-modle'>
          
          <form onSubmit={this.handleSubmit}>
            <h2 className="head_h2"> Subjects </h2>
            <i onClick={this.props.showHideForm} className="fas fa-arrow-left close-btn"></i>

            {User.isAdmin() ? 
            <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <BranchDrop 
                  BranchId={this.state.FormGroup.branchId} 
                  func={this.handleInputChange} isRequred={true} />
            </div>: null}

            <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
              <input type="text" 
                  className="form-control" 
                  placeholder="Subject Group Name" 
                  name="subjectGroupName" 
                  value={this.state.FormGroup.subjectGroupName} 
                  onChange={this.handleInputChange} />
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>

          </form>

      </div>

    );
  }
}

function mapStateToProps(state) {
  return ({
    subjectDetail: state.subjectGroupReducer.subjectDetail
  });
}

function mapDispatchToProps(dispatch) {
  return ({
    getSubjectList: () => {
        dispatch(getSubjectList());
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(false));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(SubjectForm);

