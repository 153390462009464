const INITIAL_STATE = {
    Loading:true,
    List:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_PARENT_NOTIFICATION_LIST':
            return ({
                ...states,
                List: action.payload,
                Loading: false
            });

        default:
          return states;

  }
}
