const INITIAL_STATE = {
    isTeacherFormShow: false,
    isTeacherCardViewShow:false,
    teacherLoading:true,
    teacherDetail:'',
    teacherList:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_TEACHER_DETAIL':
          return ({
              ...states,
              teacherDetail: action.payload
          });

        case 'UPDATE_TEACHER_LIST':
            return ({
                ...states,
                teacherList: action.payload,
                teacherLoading:false
            });

        case 'SHOW_HIDE_TEACHER_FORM':
            return({
                ...states,
                isTeacherFormShow:action.payload
            });

        case 'SHOW_HIDE_TEACHER_CARDVIEW':
            return({
                ...states,
                isTeacherCardViewShow:action.payload
            });

        default:
          return states;

  }
}
