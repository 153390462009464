import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import {getFeeTypeList, showHideForm} from '../../../../store/action/FeeTypeAction';
import {showMessage} from '../../../../store/action/MessageAction';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class FeeTypeForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      feeTypeId:0,
      isUpdate:false,
      FormGroup: {
        feeTypeName: '',
        Remark: ''
      }
    };

  }

  clearForm = () => {
    const UserInput = {};
    UserInput['feeTypeName'] = '';
    UserInput['Remark'] = '';

    this.setState({
        FormGroup: UserInput,
        feeTypeId:0,
        isUpdate: false
    });
    
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevProps.feeTypeDetail !== this.props.feeTypeDetail) {

      this.setState({
        FormGroup:this.props.feeTypeDetail
      })

      if(this.props.feeTypeDetail !== ''){
        this.setState({
          feeTypeId:this.props.feeTypeDetail._id,
          isUpdate: true
        })
      }

    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    
    const FeeType = this.state.FormGroup;
   
    let url = `${process.env.PUBLIC_URL}/api/SaveFeeType`;

    if(this.state.isUpdate){
      url = `${process.env.PUBLIC_URL}/api/updateFeeTypeById/${this.state.feeTypeId}`;
    }

    axios.post(url, FeeType)
    .then(res => {

        if(res.data.resType === 'success'){
          this.clearForm();
          this.props.getFeeTypeList();
        }

        this.props.showMessage({
          messageType: res.data.resType,
          Message: res.data.message
        });

     }).catch(err => console.log(err));

  }

render() {

    return (
      <div className='form-modle'>
          
          <form onSubmit={this.handleSubmit}>
          <h2 className="head_h2"> Fee Type </h2>
          <i onClick={this.props.showHideForm} className="fas fa-arrow-left close-btn"></i>
              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <input type="text" 
                    className="form-control" 
                    placeholder="Fee Type" 
                    name="feeTypeName" 
                    value={this.state.FormGroup.feeTypeName} 
                    onChange={this.handleInputChange} />
              </div>

              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <input type="text" 
                    className="form-control" 
                    placeholder="Remark" 
                    name="Remark" 
                    value={this.state.FormGroup.Remark} 
                    onChange={this.handleInputChange} />
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
                  <button type="submit" className="btn btn-primary">Submit</button>
              </div>

          </form>

      </div>

    );
  }
}

function mapStateToProps(state) {
  return ({
    feeTypeDetail: state.feeTypeReducer.feeTypeDetail
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      getFeeTypeList: () => {
        dispatch(getFeeTypeList());
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(false));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(FeeTypeForm);

