const INITIAL_STATE = {
    classNotificationLoading:true,
    classNotificationList:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_CLASS_WISE_NOTIFICATION_LIST':
            return ({
                ...states,
                classNotificationList: action.payload,
                classNotificationLoading: false
            });

        default:
          return states;

  }
}
