import axios from 'axios';

export function getAttendanceList(studentId, fromDate, toDate) {
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/parent/getAttendanceList?studentId=${studentId}&fromDate=${fromDate}&toDate=${toDate}`)
        .then(response =>

            getAttendanceListRes(dispatch, response)

        ).catch(err => console.log(err));
    }
}

function getAttendanceListRes(dispatch, response) {
    console.log(response);
    try {
       if(response.data.resType === 'success'){

            return dispatch({
                type: 'UPDATE_WARD_ATTEND_LIST',
                payload: response.data.attendance
            })
       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data.message
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}

export function showHideCardView(data) {
  
    return dispatch => {

        return dispatch({
            type: 'SHOW_HIDE_WARD_CARDVIEW',
            payload: data
        })
    }
}




