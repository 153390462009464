const INITIAL_STATE = {
    isStudentChargeFormShow: false,
    studentChargeloading: true,
    studentChargeDetail: '',
    studentChargeList: [],
    studentFilterDetail: [],
    studentFilterLoading: true,
    feeChargeInfo: {}
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_STUDENT_CHARGE_DETAIL':
          return ({
              ...states,
              studentChargeDetail: action.payload
          });

        case 'UPDATE_STUDENT_CHARGE_LIST':
            return ({
                ...states,
                studentChargeList: action.payload.studentCharge,
                studentFilterDetail: action.payload.student,
                studentChargeloading: false
            });

        case 'SHOW_HIDE_STUDENT_CHARGE_FORM':
            return({
                ...states,
                isStudentChargeFormShow:action.payload
            });

        case 'UPDATE_STUDENT_FILTER':
            return({
                ...states,
                studentFilterLoading:action.payload[0],
                feeChargeInfo: action.payload[1]
            })

        default:
          return states;

  }
}
