import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {hideMessage, showMessage} from '../../../../store/action/MessageAction';
import {getNotificationList} from './Action';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import BranchDrop from '../../../utils/BranchDrop';
import {User} from '../../../utils/ValidateUser';
import ClassDrop from '../../../utils/ClassDrop';
import {SchoolLogo} from '../../../utils/SchoolLogo';
import Navbar from '../../../Navbar';
import {ReactMessage} from '../../../utils/ReactMessage';
import {CheckSession} from '../../../utils/CheckSession';

import NotificationList from './List'

class ClassWisePushNotification extends Component {

  constructor(props){

    super(props);
    this.state = {
      FormGroup: {
        branchId: '',
        classId: '',
        title: '',
        body: ''
      }
    };

    CheckSession(this.props);
  }

  
  clearForm = () => {
    const UserInput = {};
    UserInput['title'] = '';
    UserInput['body'] = '';

    this.setState({
        FormGroup: UserInput
    });

    this.props.getNotificationList();
  }

  
  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

  }


  handleSubmit = (event) => {
    event.preventDefault();
    
    const SMS = this.state.FormGroup;
   
    let url = `${process.env.PUBLIC_URL}/api/notifications/sendClassWise`;

    axios.post(url, SMS)
    .then(res => {

        if(res.data.resType === 'success'){
          this.clearForm();
        }

        this.props.showMessage({
          messageType: res.data.resType,
          Message: res.data.message
        });

     }).catch(err => console.log(err));

  }


render() {

    return (

      <div className="container mp0">

        <SchoolLogo SchoolName="Inzeal Infotech" />

        {this.props.messageShow ? 
          <ReactMessage 
            func={this.props.hideMessage} 
            messageType={this.props.messageType} 
            Message={this.props.Message} /> : null}

        <Navbar />

        
          <div className="form-wrapper" style={{width:"100%"}}>

              <h2>Send Notification</h2>

              <form onSubmit={this.handleSubmit}>
              <div className="row">
                {User.isAdmin() ? 
                <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                    <label>Branch</label>
                    <BranchDrop 
                      BranchId={this.state.FormGroup.branchId} 
                      func={this.handleInputChange} isRequred={true} />
                </div>: null}

                <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                  <label>Class</label>
                  <ClassDrop 
                    InputName='classId' 
                    ClassId={this.state.FormGroup.classId} 
                    func={this.handleInputChange} isRequred={true} />
                </div>

                <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                  <label>Title</label>
                  <input 
                    type='text' 
                    className="form-control" 
                    placeholder="title" 
                    name="title" 
                    value={this.state.FormGroup.title} 
                    onChange={this.handleInputChange} />
                </div>
                
                <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                
                  <textarea 
                    className="form-control" 
                    placeholder="Type Message..." 
                    name="body" 
                    value={this.state.FormGroup.body} 
                    onChange={this.handleInputChange} ></textarea>
                    
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </div>
                </div>
              </form>

          </div>

          <div className="form-wrapper" style={{width:"100%"}}>
            <h2>List</h2>
            <div style={{overflow: "auto"}}>
              <NotificationList />
            </div>
          </div>
        
      </div>

    );
  }
}

function mapStateToProps(state) {
    return ({
      messageShow: state.messageReducer.messageShow,
      messageType: state.messageReducer.messageType,
      Message: state.messageReducer.Message,
      List: state.classWiseNotification.classNotificationList
    });
}

function mapDispatchToProps(dispatch) {
  return ({
    getNotificationList : () => {
      dispatch(getNotificationList());
    },
    hideMessage: () => {
      dispatch(hideMessage());
    },
    showMessage: (data) => {
      dispatch(showMessage(data));
    }
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassWisePushNotification);

