import React, { Component } from 'react';
import { connect } from 'react-redux';
//import {showHideCardView} from './Action';
import {hideMessage} from '../../../store/action/MessageAction';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import {SchoolLogo} from '../../utils/SchoolLogo';
import Navbar from '../../ParentNavbar';
import {ReactMessage} from '../../utils/ReactMessage';
import {CheckParentSession} from '../../utils/CheckSession';

import CardView from './CardView';
import HomeWorkForm from './Form';

class HomeWorkIndex extends Component {

  constructor(props){
    super(props);
    CheckParentSession(this.props);
  }

render() {

    return (

      <div className="container mp0">
        
        <SchoolLogo SchoolName="Inzeal Infotech" />

        {this.props.messageShow ? <ReactMessage func={this.props.hideMessage} messageType={this.props.messageType} Message={this.props.Message} /> : null}

        <Navbar />

        <div className="form-wrapper mp0" style={{width:"100%"}}>

          <h2>Home Work</h2>

          {this.props.isFormShow ? <HomeWorkForm /> : null}
          
          <CardView /> 
            
        </div>

      </div>

    );
  }
}

function mapStateToProps(state) {
    return ({
      messageShow: state.messageReducer.messageShow,
      messageType: state.messageReducer.messageType,
      Message: state.messageReducer.Message,
      isFormShow: state.homeWorkReducer.isFormShow
    });
}

function mapDispatchToProps(dispatch) {
  return ({
      hideMessage: () => {
        dispatch(hideMessage());
      }
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeWorkIndex);

