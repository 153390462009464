import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {getStudentChargeList, getStudentChargeDetail, showHideForm} from '../../../../store/action/StudentChargeAction';
import {showMessage} from '../../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class StudentChargeList extends Component {

  deleteStudentCharge = (id) => {
    
    axios.delete(`${process.env.PUBLIC_URL}/api/deleteStudentCharge/${id}`)
    .then(res => {

      if(res.data.resType === 'success') {
        this.props.changeStateToReducer();
      }

      this.props.showMessage({
        messageType: res.data.resType,
        Message:res.data.message
      });

    }).catch(err => console.log(err));
    
  }

  getStudentChargeDetail = (id) => {
    this.props.getStudentChargeDetail(id);
    this.props.showHideForm();
  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }
  
render() {

  const renderTable  = (List) =>{
    
    return(
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Amount</th>
              <th>Fee Type</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {List.map((item, index) => 
              <tr key={index}>
                <td>₹ {item.Amount}</td>
                <td>{item.feeTypeId === null ? null : item.feeTypeId.feeTypeName}</td>
                <td>{this.GetFormattedDate(item.Date)}</td>
                <td>{item.Remark}</td>
                <td>
                  <button className="btn btn-sm btn-info" onClick={this.getStudentChargeDetail.bind(this, item._id)}>Edit</button>
                  <button className="btn btn-sm btn-danger" onClick={this.deleteStudentCharge.bind(this, item._id)}>Delete</button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
    );
  }

  const studentCharge = this.props.studentChargeloading ? <div className="loader"><img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} /></div> : renderTable(this.props.studentChargeList)

    return (

      <React.Fragment>
        {studentCharge}
      </React.Fragment>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    studentChargeList: state.studentChargeReducer.studentChargeList,
    studentChargeloading:state.studentChargeReducer.studentChargeloading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      changeStateToReducer: () => {
        dispatch(getStudentChargeList('0'));
      },
      getStudentChargeDetail: (id) => {
        dispatch(getStudentChargeDetail(id));
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(StudentChargeList);


