const INITIAL_STATE = {
  branchId: '',
  classId: '',
  subjectId: '',
  term: '',
  studentLoading: true,
  studentList: [],
  isFilter: true
}

export default (states = INITIAL_STATE, action) => {

switch (action.type) {

      case 'UPDATE_MARKS_ASSIGN_STUDENT_LIST':
          return ({
              ...states,
              studentList: action.payload,
              studentLoading: false,
              isFilter: false
          });

      case 'UPDATE_MARKS_SETUP_HEAD_ID':
          return({
              ...states,
              branchId: action.payload.branchId,
              classId: action.payload.classId,
              term: action.payload.term,
              subjectId: action.payload.subjectId
          });

          case 'UPDATE_HOME_WORK_FILTER_FLAG':
          return({
              ...states,
              isFilter:action.payload
          });

    
      default:
        return states;

}
}
