const INITIAL_STATE = {
    isWardCardShow:false,
    wardLoading:true,
    wardList:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_WARD_LIST':
            return ({
                ...states,
                wardList: action.payload,
                wardLoading:false
            });

        case 'SHOW_HIDE_WARD_CARDVIEW':
            return({
                ...states,
                isWardCardShow:action.payload
            });

        default:
          return states;

  }
}
