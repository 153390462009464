import React, { Component } from 'react';
import { connect } from 'react-redux';
import {updateStudentList} from './Action';
import {showMessage} from '../../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class CardView extends Component {

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  handleInputChange = (i, Name, event) => {

    const target = event.target;
    const value = target.value;

    let list = [...this.props.studentList];
    list[i][Name] = value;

    this.props.updateStudentList(list)

  }


render() {


  const Card  = (List) => {
    
    return(
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Student</th>
                <th>Obtained Marks</th>
              </tr>
            </thead>
            <tbody>
              {List.map((item, index) => 
                <tr key={index}>
                  <td>{item.studentName}</td>
                  <td><input type="text" 
                    className="form-control" 
                    placeholder="Marks"
                    value={item.obtainedMarks} 
                    onChange={this.handleInputChange.bind(this, index, 'obtainedMarks')} /></td>
                </tr>
              )}
            </tbody>
          </table>

    );
  }

  const Student = this.props.studentLoading ? <i>No Data</i> : Card(this.props.studentList);
 
  
    return (

      <React.Fragment>
        {Student}
      </React.Fragment>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    studentList: state.marksAssignReducer.studentList,
    studentLoading: state.marksAssignReducer.studentLoading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      updateStudentList: (list) => {
        dispatch(updateStudentList(list));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(CardView);