import React, { Component } from 'react';
import axios from 'axios';
//import { Link } from 'react-router-dom'
import {MenuCard} from '../UtilComponent/MenuCard';
// import Chat from '../UtilComponent/Chatbox/index'
import {SchoolLogo} from '../utils/SchoolLogo';
import Navbar from '../Navbar';
import {CheckSession} from '../utils/CheckSession';

 class AdminHome extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      cardList:[],
      loading: true
    };
    this.getMenu(this.props.match.params.id);
  }

  componentWillMount(){
    CheckSession(this.props);
  }

  componentWillReceiveProps(nextProps){

    this.setState({
      loading:true
    });

    this.getMenu(nextProps.match.params.id);
  }

  getMenu = (id) => {
    axios.get(`${process.env.PUBLIC_URL}/api/getAdminMenuById/${id}`)
    .then(res => {
      console.log(res)
       if(res.data.resType === 'success'){
          this.setState({
            cardList: res.data.adminMenu,
            loading: false
          });
       }

    }).catch(err => console.log(err));
  }

render() {

    const renderCards = (cardList) => {
      return(
        <React.Fragment>
          {cardList.map((card, index) => 
            <MenuCard key={index} icon={card.icon} title={card.title} Url={card.url+'/'+card._id} />
          )}
        </React.Fragment>
      )
    }

    const cardList = renderCards(this.state.cardList);

    return (
      <div className="container"> 
        <SchoolLogo SchoolName="Inzeal Infotech" /> 
        <Navbar /> 
        <div className="row"> 
          {this.state.loading ? 
            <div className="loader"> 
              <img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} /> 
            </div> : cardList} 
        </div> 

        {/* <Chat /> */} 
      </div> 
    );
  }
}

export default AdminHome;