import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {getParentList, getParentDetail, showHideForm} from '../../../../store/action/ParentMasterAction';
import {showMessage} from '../../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';


class ParentList extends Component {

  componentDidMount() {
    this.props.changeStateToReducer();
  }

  deleteParent = (id) => {
    
    axios.delete(`${process.env.PUBLIC_URL}/api/deleteParent/${id}`)
    .then(res => {

      if(res.data.resType === 'success'){
        this.props.changeStateToReducer();
      }

      this.props.showMessage({
        messageType: res.data.resType,
        Message:res.data.message
      });

    }).catch(err => console.log(err));
  }

  getParentDet = (id) =>{
    this.props.getParentDetail(id);
    this.props.showHideForm();
  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  
render() {

  const renderTable  = (List) => {
    console.log(List);
    return(
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Gardiun Name</th>
              <th>Father Name</th>
              <th>Mother Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>National Id</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {List.map((item, index) => 

              <tr key={index}>
                <td>{item.gardiunName}</td>
                <td>{item.fatherName}</td>
                <td>{item.motherName}</td>
                <td>{item.Phone}</td>
                <td>{item.Email}</td>
                <td>{item.nationalId}</td>
                <td>
                    <i className="fas fa-edit" onClick={this.getParentDet.bind(this, item._id)} style={{margin:"2px", color:"green"}}></i>
                    <i className="fas fa-trash-alt" onClick={this.deleteParent.bind(this, item._id)} style={{margin:"2px", color:"red"}}></i>
                </td>
              </tr>
              
            )}
          </tbody>
        </table>

    );

  }

  const Parent = this.props.parentloading ? <div className="loader"><img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} /></div> : renderTable(this.props.parentList);
  
    return (

      <React.Fragment>
        {Parent}
      </React.Fragment>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    parentList: state.parentMasterReducer.parentList,
    parentloading:state.parentMasterReducer.parentloading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      changeStateToReducer: () => {
        dispatch(getParentList());
      },
      getParentDetail: (id) => {
        dispatch(getParentDetail(id));
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      }
  });
}



export default connect(mapStateToProps,mapDispatchToProps)(ParentList);


