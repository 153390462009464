import React, { Component } from 'react';
import {AttendanceService} from '../../Services/master.js'

export default class StudentDrop extends Component {

    constructor(props) {

        super(props);
        this.state = {
            StudentList: [],
            loading: false,
            Message: 'wait....!'
        };
        
        this.fillStudent = this.fillStudent.bind(this);

        this.fillStudent(this.props.BranchId,this.props.ClassId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ClassId !== this.props.ClassId || nextProps.BranchId !== this.props.BranchId) {
            //Perform some operation
            this.fillStudent(nextProps.BranchId,nextProps.ClassId);
        }
    }

    fillStudent(BranchId, ClassId) {

        if(!this.props.isParent){

            if(ClassId === '' || ClassId === null || ClassId === '0'){
                ClassId = "0";
            } 
            if(BranchId === '' || BranchId === null || BranchId === '0'){
                BranchId = "0";
            } 
            
            AttendanceService.getStudentByClass(BranchId, ClassId)
            .then(response => {
            
                this.setState({ StudentList: response.data.student, loading: false });
            });

        }
        else{
            AttendanceService.getStudentByParent()
            .then(response => {
            
                this.setState({ StudentList: response.data.student, loading: false });
            });
        }

        
    }

    render() {

        const renderlist = (List) => {
            
        return (
            <select name="studentId" value={this.props.StudentId} onChange={this.props.func} className="form-control">
                <option value="">Select Student</option>
                {List.map(C =>
                    <option key={C._id} value={C._id}>{C.studentName}</option>
                )}
            </select>
        );}


        let StudentList = this.state.loading
            ? null
            : renderlist(this.state.StudentList);

        return (
            
            <div>
                {StudentList}
            </div>
                
        );
    }
}

StudentDrop.defaultProps = {
    ClassId: "0",
    BranchId: "0",
    isParent: false
}