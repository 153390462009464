const INITIAL_STATE = {
    isTransportMemberFormShow: false,
    transportMemberLoading: true,
    transportMemberDetail: '',
    transportMemberList: [],
    isTransportMemberComponent: false,
    transportMemberFilter:{},
    transportMemberId: ''
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_TRANSPORT_MEMBER_DETAIL':
          return ({
              ...states,
              transportMemberDetail: action.payload
          });

        case 'UPDATE_TRANSPORT_MEMBER_LIST':
            return ({
                ...states,
                transportMemberList: action.payload,
                transportMemberLoading: false
            });

        case 'SHOW_HIDE_TRANSPORT_MEMBER_FORM':
            return({
                ...states,
                isTransportMemberFormShow:action.payload
            });

        case 'UPDATE_TRANSPORT_MEMBER_FILTER':
            return({
                ...states,
                isTransportMemberComponent:action.payload[0],
                transportMemberFilter:action.payload[1]
            });

        case "UPDATE_TRANS_MEMBER_ID":
            return({
                ...states,
                transportMemberId: action.payload
            })

        default:
          return states;

  }
}
