import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getSubjectList} from './Action';
import BranchDrop from '../../../utils/BranchDrop';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class Filter extends Component {

  constructor(props){
    super(props);
    this.state = {
      FormGroup: {
        branchId: '' 
      }
    };
  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

  }

  filterList = () => {
    let {branchId} = this.state.FormGroup;
    this.props.applyFilter(branchId)
  }

render() {

    return (
      <React.Fragment>

        <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
          <label>Branch</label>
          <BranchDrop 
            isRequred={true}
            BranchId={this.state.FormGroup.branchId} 
            func={this.handleInputChange} />
        </div>

        <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
            <button onClick={this.filterList} className="btn btn-primary">Search</button>
        </div>

      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return ({
    subjectList: state.subjectGroupReducer.subjectList
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      applyFilter: (branchId) => {
        dispatch(getSubjectList(branchId));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(Filter);
