import React, { Component } from 'react';
import {teacher} from '../../Services/teacher';

export default class TeacherDrop extends Component {

    constructor(props) {

        super(props);
        this.state = {
            TeacherList: [],
            loading: true,
            Message: 'wait....!'
        };

        this.fetchTeacherDrop(this.props.BranchId)
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.BranchId !== this.props.BranchId) {
            //Perform some operation
            this.fetchTeacherDrop(nextProps.BranchId)
        }
    }

    fetchTeacherDrop = (id) => {
    
        if(id === '' || id === null || id === '1'){
            console.log(id)
        }
        else{
            teacher.getTeacherDrop(id).then(response => {
            
                this.setState({ TeacherList: response.data.teacher, loading: false });
            });
        }
    }


    render(){

        const renderlist = (List) => {
            return (
                <select name={this.props.InputName} value={this.props.TeacherId} onChange={this.props.func} className="form-control">
                    <option value="">Select</option>
                    {List.map(S =>
                        <option key={S._id} value={S._id}>{S.teacherName}</option>
                    )}
                </select>
            );
        }

        let TeacherList = this.state.loading
            ? <p><em>Loading...</em></p>
            : renderlist(this.state.TeacherList);

        return (
            <div>
                {TeacherList}
            </div>
                
        );
    }
}

TeacherDrop.defaultProps = {
    TeacherId: "0",
    InputName: "teacherId",
    BranchId: "0"
}
