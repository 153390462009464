const INITIAL_STATE = {
    isReligionFormShow: false,
    religionLoading:true,
    religionDetail:'',
    religionList:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_RELIGION_DETAIL':
          return ({
              ...states,
              religionDetail: action.payload
          });

        case 'UPDATE_RELIGION_LIST':
            return ({
                ...states,
                religionList: action.payload,
                religionLoading:false
            });


        case 'SHOW_HIDE_RELIGION_FORM':
            return({
                ...states,
                isReligionFormShow:action.payload
            });

        default:
          return states;

  }
}
