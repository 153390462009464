import React, { Component } from 'react';
import { connect } from 'react-redux';
import {showHideForm, showHideCardView} from '../../../../store/action/ParentMasterAction';
import {hideMessage} from '../../../../store/action/MessageAction';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import {SchoolLogo} from '../../../utils/SchoolLogo';
import Navbar from '../../../Navbar';
import {ReactMessage} from '../../../utils/ReactMessage';
import {CheckSession} from '../../../utils/CheckSession';

import ParentForm from './ParentForm';
import ParentList from './ParentList';
import ParentCardView from './ParentCardView';


class ParentMaster extends Component {

  constructor(props){
    super(props);
    CheckSession(this.props);
  }

  toggleCardView = () => {
    if(this.props.isCardViewShow === false){
      this.props.showHideCardView(true);
    }
    else{
      this.props.showHideCardView(false);
    }
  }


render() {

    return (

      <div className="container mp0">

        <SchoolLogo SchoolName="Inzeal Infotech" />

        {this.props.messageShow ? 
        <ReactMessage func={this.props.hideMessage} messageType={this.props.messageType} Message={this.props.Message} /> : null}

        <Navbar />

          <div className="form-wrapper" style={{width:"100%"}}>

            <h2>Add Parents 
              
              <button 
                onClick={this.props.showHideForm} 
                className="btn btn-sm btn-info float-right" style={{margin:'0px 2px'}}>Add New</button> 

              <button 
                onClick={this.toggleCardView} 
                className="btn btn-sm btn-info float-right">Toggle</button>

            </h2>
            
            {this.props.isFormShow ? <ParentForm /> : null}

            <div style={{overflow:"auto"}}>
              {this.props.isCardViewShow ? <ParentCardView /> : <ParentList />}
            </div>

          </div>
        

      </div>

    );
  }
}

function mapStateToProps(state) {
    return ({
      messageShow: state.messageReducer.messageShow,
      messageType: state.messageReducer.messageType,
      Message: state.messageReducer.Message,
      isFormShow: state.parentMasterReducer.isParentFormShow,
      isCardViewShow: state.parentMasterReducer.isParentCardViewShow,
    });
}

function mapDispatchToProps(dispatch) {
  return ({
      hideMessage: () => {
        dispatch(hideMessage());
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      },
      showHideCardView: (data) =>{
        dispatch(showHideCardView(data));
      }
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ParentMaster);

