import React, { Component } from 'react';
import '../assets/css/login.css'
import { Link } from 'react-router-dom';
import {SchoolLogo} from './utils/SchoolLogo';


class MasterPage extends Component {


  componentWillMount(){
    if(localStorage.getItem("x-token") != null){
      this.props.history.push(localStorage.getItem("redirectTo"));
    }
  }

render() {

    return (
      <div className="main_container">
        <SchoolLogo />
        <div className="login_container">

          <Link className="login_card" to="/MasterLogin">
            <img 
                src={process.env.PUBLIC_URL+"/Images/icon/schoolIcon.png"} 
                alt="Card Icon" />

            <span>SCHOOL LOGIN</span>

          </Link>

          <Link className="login_card" to="/ParentLogin">
            <img
                src={process.env.PUBLIC_URL+"/Images/icon/parentsIcon.png"} 
                alt="Card Icon" />

            <span>PARENT LOGIN</span>

          </Link>
            
        </div>
        
      </div>
    );
  }
}

export default MasterPage;
