import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import axios from 'axios';
//import { connect } from 'react-redux';
import LoginComponent from './component/MasterLogin';
import MasterPage from './component/MasterPage';
import AdminHome from './component/Admin/AdminHome';
import SchoolSetup from './component/Admin/Masters/SchoolSetup/SchoolSetup';
import SchoolSetupList from './component/Admin/Masters/SchoolSetup/SchoolSetupList';
import BranchSetup from './component/Admin/Masters/BranchSetup/BranchSetup';
import BranchSetupList from './component/Admin/Masters/BranchSetup/BranchSetupList';
import StateMaster from './component/Admin/Masters/StateMaster/StateMaster';
import CityMaster from './component/Admin/Masters/CityMaster/CityMaster';
import DesignationMaster from './component/Admin/Masters/DesignationMaster/DesignationMaster';
import ReligionMaster from './component/Admin/Masters/ReligionMaster/ReligionMaster';
import TeacherMaster from './component/Admin/Teachers/TeacherMaster/TeacherMaster';
import ParentMaster from './component/Admin/Parents/ParentMaster/ParentMaster';
import ClassMaster from './component/Admin/Masters/ClassMaster/ClassMaster';
import StudentMaster from './component/Admin/Students/StudentMaster/StudentMaster';
import FeeTypeMaster from './component/Admin/Accounts/FeeType/FeeTypeMaster';
import FeeSetupMaster from './component/Admin/Accounts/FeeSetup/FeeSetupMaster';
import ExpenseMaster from './component/Admin/Accounts/Expense/index';
import StudentChargeMaster from './component/Admin/Accounts/StudentCharge/StudentChargeMaster';
import SendSMSMaster from './component/Admin/SendSMS/ToAllParent/SendSMSMaster';
import SMSIndividualMaster from './component/Admin/SendSMS/ToIndividual/SendSMSMaster';
import SMSClassMaster from './component/Admin/SendSMS/ToClass/SendSMSMaster';
import {Attendance} from './component/Admin/Attendance/index';
import TeacherHome from './component/Teacher/Home/index';
import PermissionMaster from './component/Admin/Masters/PermissionMaster/UserPermission';
import ParentPermission from './component/Admin/Masters/PermissionMaster/ParentPermission';
import AddTransport from './component/Admin/Transport/AddTransport/index';
import AddTransportMember from './component/Admin/Transport/AddMember/index';
import FeeReportMaster from './component/Admin/Reports/FeeReport/index';
import PushNotification from './component/Admin/SendNotification/AllParent/index';
import ClassWisePushNotification from './component/Admin/SendNotification/ClassWise/index';
import AssignHomeWork from './component/Admin/Students/AssignHomeWork';
import SubjectGroupComponent from './component/Admin/Masters/SubjectGroup/index';
import SubjectsComponent from './component/Admin/Masters/Subjects/index';
import AttendanceReport from './component/Admin/Reports/Attendance/index';
import MarksSetup from './component/Admin/Examination/ExamMarksSetup/index';
import AssignMarks from './component/Admin/Examination/AssignMarks/index';
import MarkSheet from './component/Admin/Examination/MarkSheet/index';


//Parent Section
import ParentLogin from './component/ParentLogin';
import ParentDirectLogin from './component/ParentDirectLogin';
import ParentHome from './component/Parent/ParentHome';
import WardIndex from './component/Parent/Ward/index';
import AttendanceIndex from './component/Parent/Attendance/index';
import ParentNotificationIndex from './component/Parent/Notification/index';
import HomeWorkIndex from './component/Parent/HomeWork/index';
import ExaminationIndex from './component/Parent/Examination/index';


class App extends Component {

render() {
    return (
      <div>
        <Switch>
          <Route exact path='/' component={MasterPage}/>
          <Route exact path='/login' component={MasterPage}/>
          <Route path='/AdminHome/:id' component={AdminHome}/>
          <Route path='/TeacherHome/:id' component={TeacherHome}/>
          <Route path='/MasterLogin' component={LoginComponent}/>
          <Route path='/SchoolSetup/:id' component={SchoolSetup}/>
          <Route path='/SchoolSetupList' component={SchoolSetupList}/>
          <Route path='/BranchSetup/:id' component={BranchSetup}/>
          <Route path='/BranchSetupList' component={BranchSetupList}/>
          <Route path='/StateMaster/:id' component={StateMaster}/>
          <Route path='/CityMaster/:id' component={CityMaster}/>
          <Route path='/DesignationMaster/:id' component={DesignationMaster}/>
          <Route path='/ReligionMaster/:id' component={ReligionMaster}/>
          <Route path='/TeacherMaster/:id' component={TeacherMaster}/>
          <Route path='/ParentMaster/:id' component={ParentMaster}/>
          <Route path='/ClassMaster/:id' component={ClassMaster}/>
          <Route path='/StudentMaster/:id' component={StudentMaster}/>
          <Route path='/FeeTypeMaster/:id' component={FeeTypeMaster}/>
          <Route path='/FeeSetupMaster/:id' component={FeeSetupMaster}/>
          <Route path='/ExpenseMaster/:id' component={ExpenseMaster}/>
          <Route path='/StudentChargeMaster/:id' component={StudentChargeMaster}/>
          <Route path='/SendSMS/:id' component={SendSMSMaster}/>
          <Route path='/SMSIndividualMaster/:id' component={SMSIndividualMaster}/>
          <Route path='/SMSClassMaster/:id' component={SMSClassMaster}/>
          <Route path='/Attendance/:id' component={Attendance}/>
          <Route path='/TeacherPermission/:id' component={PermissionMaster}/>
          <Route path='/ParentPermission/:id' component={ParentPermission}/>
          <Route path='/AddTransport/:id' component={AddTransport}/>
          <Route path='/AddTransportMember/:id' component={AddTransportMember}/>
          <Route path='/FeeReportMaster/:id' component={FeeReportMaster}/>
          <Route path='/PushNotification' component={PushNotification}/>
          <Route path='/ClassWisePushNotification' component={ClassWisePushNotification}/>
          <Route path='/AssignHomeWork' component={AssignHomeWork}/>
          <Route path='/SubjectGroup' component={SubjectGroupComponent}/>
          <Route path='/Subjects' component={SubjectsComponent}/>
          <Route path='/AttendanceReport' component={AttendanceReport}/>
          <Route path='/MarksSetup' component={MarksSetup}/>
          <Route path='/AssignMarks' component={AssignMarks}/>
          <Route path='/MarkSheet' component={MarkSheet}/>

          <Route path='/ParentLogin' component={ParentLogin}/>
          <Route path='/P/:id' component={ParentDirectLogin}/>
          <Route path='/ParentHome/:id' component={ParentHome}/>
          <Route path='/Ward/:id' component={WardIndex}/>
          <Route path='/WardAttendance/:id' component={AttendanceIndex}/>
          <Route path='/ParentNotification/:id' component={ParentNotificationIndex}/>
          <Route path='/HomeWork/:id' component={HomeWorkIndex}/>
          <Route path='/Result' component={ExaminationIndex}/>
          
          

        </Switch>
      </div>
       
    );
  }
}

//  function mapStateToProps(state) {
//     return ({
//       isLogin: state.loginReducer.isLogin
//     });
//  }

//export default connect(mapStateToProps)(App);

export default App;

(function getSession(){

  //console.log(sessionStorage.getItem("x-token"));
  if(localStorage.getItem("x-token") != null){
    axios.defaults.headers.common['x-token'] = localStorage.getItem("x-token");
  }

})();

