import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {getNotificationList, showHideForm} from './Action';
import {showMessage} from '../../../../store/action/MessageAction';
import {User} from '../../../utils/ValidateUser';
import BranchDrop from '../../../utils/BranchDrop';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class NotifitionForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      FormGroup: {
        branchId: '',
        title: '',
        body: ''
      }
    };

  }

  clearForm = () => {
    const UserInput = {};
    UserInput['branchId'] = '';
    UserInput['title'] = '';
    UserInput['body'] = '';

    this.setState({
        FormGroup: UserInput
    });

    this.props.getNotificationList();
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    const SMS = this.state.FormGroup;
   
    let url = `${process.env.PUBLIC_URL}/api/notifications/send`;

    axios.post(url, SMS)
    .then(res => {

        if(res.data.resType === 'success'){
          this.clearForm();
        }

        this.props.showMessage({
          messageType: res.data.resType,
          Message: res.data.message
        });

     }).catch(err => console.log(err));

  }

render() {

    return (
      <div className='form-modle'>
          
          <form onSubmit={this.handleSubmit}>
              <h2 className="head_h2"> Send </h2>
              <i onClick={this.props.showHideForm} className="fas fa-arrow-left close-btn"></i>
 
                  {User.isAdmin() ? 
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                      
                      <BranchDrop 
                        BranchId={this.state.FormGroup.branchId} 
                        func={this.handleInputChange} isRequred={true} />
                  </div>: null}

                  <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                  
                    <input 
                      type='text' 
                      className="form-control" 
                      placeholder="title" 
                      name="title" 
                      value={this.state.FormGroup.title} 
                      onChange={this.handleInputChange} />
                  </div>
                  
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                  
                    <textarea 
                      className="form-control" 
                      placeholder="Type Message..." 
                      name="body" 
                      value={this.state.FormGroup.body} 
                      onChange={this.handleInputChange} ></textarea>
                      
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
                      <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                
              </form>

      </div>

    );
  }
}

function mapStateToProps(state) {
  return ({
    isFormShow: state.allNotification.isFormShow
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      getNotificationList : () => {
        dispatch(getNotificationList());
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(false));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(NotifitionForm);

