import axios from 'axios';

export function getSMSList() {
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/getIndividualSMSList`)
        .then(response =>

            getSMSListRes(dispatch, response)

        ).catch(err => console.log(err));
    }
}

function getSMSListRes(dispatch, response) {
   
    try {
       if(response.data.resType === 'success'){

            return dispatch({
                type: 'UPDATE_INDIVIDUAL_SMS_LIST',
                payload: response.data.smsList
            })
       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data.message
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}

export function showHideForm(data) {
  
    return dispatch => {

        return dispatch({
            type: 'SHOW_HIDE_INDIVIDUAL_SMS_FORM',
            payload: data
        })
    }
}




