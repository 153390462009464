import React, { Component } from 'react';
import axios from 'axios';

export default class StateDrop extends Component {

    constructor(props) {

        super(props);
        this.state = {
            StateList: [],
            loading: true,
            Message: 'wait....!'
        };

        axios.get(`${process.env.PUBLIC_URL}/api/getStateDrop`)
        .then(response => {
            this.setState({ StateList: response.data.state, loading: false });
        });

    }

    render(){

        const renderStatelist = (StateList) => {
            return (
                <select name='stateId' value={this.props.StateId} onChange={this.props.func} className="form-control">
                    <option value="">Select State</option>
                    {StateList.map(S =>
                        <option key={S._id} value={S._id}>{S.stateName}</option>
                    )}
                </select>
            );
        }

        let Statelist = this.state.loading
            ? <p><em>Loading...</em></p>
            : renderStatelist(this.state.StateList);

        return (
            <div>
                {Statelist}
            </div>
                
        );
    }
}

StateDrop.defaultProps = {
    StateId: "0"
}
