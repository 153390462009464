import React, { Component } from 'react';
import { connect } from 'react-redux';
import {showMessage} from '../../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class AttendList extends Component {
  
  state = {
    attendanceList:this.props.attendanceList,
    search:""
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevProps.attendanceList !== this.props.attendanceList) {
      this.setState({
        attendanceList: this.props.attendanceList
      })
    }
  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

render() {

  const renderTable  = (List) => {
   
    return(
        <table className="table table-bordered table_sticky_head">
          <thead className="t_head">
            <tr>
              <th>Date</th>
              <th>Student</th>
              <th>Class</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {List.map((item, index) => 

              <tr key={index}>
                <td>{item.attendanceDate}</td>
                <td>{item.studentName}</td>
                <td>{item.className}</td>
                <td>{item.isPresent ? 'Present' : 'Absent'}</td>
              </tr>
              
            )}
          </tbody>
        </table>
    );
  }

  const Student = this.props.attendanceLoading ? 
            <div className="loader"><img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} /></div> : 
            renderTable(this.state.attendanceList);
  
    return (

      <div style={{overflow: "auto"}}>
        
        {Student}
      </div>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    attendanceList: state.attendanceReportReducer.attendanceList,
    attendanceLoading:state.attendanceReportReducer.attendanceLoading
  });
}


function mapDispatchToProps(dispatch) {
  return ({
      showMessage: (data) => {
        dispatch(showMessage(data));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(AttendList);


