import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {getExpenseList, getExpenseDetail, showHideForm} from '../../../../store/action/ExpenseAction';
import {showMessage} from '../../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';


class ExpenseList extends Component {

  componentDidMount() {
    this.props.changeStateToReducer();
  }

  deleteExpense = (id) => {
    
    axios.delete(`${process.env.PUBLIC_URL}/api/deleteExpense/${id}`)
    .then(res => {

      if(res.data.resType === 'success') {
        this.props.changeStateToReducer();
      }

      this.props.showMessage({
        messageType: res.data.resType,
        Message:res.data.message
      });

    }).catch(err => console.log(err));
  }

  getDetail = (id) =>{
    this.props.getExpenseDetail(id);
    this.props.showHideForm();
  }

  
render() {

  const renderTable  = (List) =>{
    return(
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Issue</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Remark</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {List.map((item, index) => 
              <tr key={index}>
                <td>{item.Issue}</td>
                <td>{item.Date}</td>
                <td>{item.Amount}</td>
                <td>{item.Remark}</td>
                <td>
                  <button className="btn btn-sm btn-info" onClick={this.getDetail.bind(this, item._id)}>Edit</button>
                  <button className="btn btn-sm btn-danger" onClick={this.deleteExpense.bind(this, item._id)}>Delete</button>
                </td>
              </tr>
            )}
          </tbody>
        </table>

    );

  }

  const Expense = this.props.expenseLoading ? <div className="loader"><img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} /></div> : renderTable(this.props.expenseList)

    return (

      <React.Fragment>
        {Expense}
      </React.Fragment>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    expenseList: state.expenseReducer.expenseList,
    expenseLoading: state.expenseReducer.expenseLoading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      changeStateToReducer: () => {
        dispatch(getExpenseList());
      },
      getExpenseDetail: (id) => {
        dispatch(getExpenseDetail(id));
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      }
  });
}



export default connect(mapStateToProps,mapDispatchToProps)(ExpenseList);


