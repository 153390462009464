import axios from 'axios';


export function getStudentList(branchId, classId, subjectId, term) {
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/AssignMarks/getStudent?branchId=${branchId}&classId=${classId}&term=${term}&subjectId=${subjectId}`)
        .then(response =>

            getStudentListRes(dispatch, response)

        ).catch(err => console.log(err));
    }

}

function getStudentListRes(dispatch, response) {
   
    try {
       if(response.data.resType === 'success'){

            return dispatch({
                type: 'UPDATE_MARKS_ASSIGN_STUDENT_LIST',
                payload: response.data.assignMarks
            })

       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data.message
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}

export function updateHeadId(data) {
  
    return dispatch => {

        return dispatch({
            type: 'UPDATE_MARKS_SETUP_HEAD_ID',
            payload: data
        })
    }
}


export function updateStudentList(data) {
  
    return dispatch => {

        return dispatch({
            type: 'UPDATE_MARKS_ASSIGN_STUDENT_LIST',
            payload: data
        })
    }
}
