import React, { Component } from 'react';
import axios from 'axios';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import {SchoolLogo} from '../../../utils/SchoolLogo';
import Navbar from '../../../Navbar';
import {ReactMessage} from '../../../utils/ReactMessage';
import {CheckSession} from '../../../utils/CheckSession';

class SchoolSetup extends Component {

  constructor(props){
    super(props);
    this.state = {
      id: '',
      isUpdate:false,
      images: [],
      FormGroup: {
        schoolCode: '',
        schoolName: '',
        email: '',
        contactNo: '',
        Address: ''
      },
      messageShow: false,
      messageType: 'success',
      Message:'',
    };

    this.mounted = true;
    CheckSession(this.props);
  }

  componentWillUnmount(){
    this.mounted = false;
  }

  componentDidMount() {
    this.getSchoolDetail();
  }

  hideMessage = () => {
    this.setState({
      messageShow: false,
      messageType: 'success',
      Message: '',
    });
  }

  getSchoolDetail = () => {
  
    axios.get(`${process.env.PUBLIC_URL}/api/getSchoolById`)
    .then(res => {
       console.log(res.data.school);
        if(res.data.resType === 'success'){
          const UserInput = this.state.FormGroup;
          UserInput['schoolCode'] = res.data.school.schoolCode;
          UserInput['schoolName'] = res.data.school.schoolName;
          UserInput['email'] = res.data.school.email;
          UserInput['contactNo'] = res.data.school.contactNo;
          UserInput['Address'] = res.data.school.Address;

          if(this.mounted){
            this.setState({
                FormGroup: UserInput,
                isUpdate: true,
                id: res.data.school._id
            });
          }
          
        }
        else{
          if(this.mounted){
            this.clearForm();
            
          }
        }

    }).catch(err => console.log(err));
  }

  handleInputChange = (event) => {
        
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
        FormGroup: UserInput
    });

  }

  handleSubmit = (event) => {
    event.preventDefault();
     //const School = this.state.FormGroup
    const data = new FormData();

    this.state.images.forEach(image => {
      data.append("image", image, image.name);
    });

     data.append('schoolCode',this.state.FormGroup.schoolCode);
     data.append('schoolName',this.state.FormGroup.schoolName);
     data.append('email',this.state.FormGroup.email);
     data.append('contactNo',this.state.FormGroup.contactNo);
     data.append('Address',this.state.FormGroup.Address);

     let url = `${process.env.PUBLIC_URL}/api/SaveSchool`;

     if(this.state.isUpdate){
        url = `${process.env.PUBLIC_URL}/api/updateSchoolById/${this.state.id}`;
     }
    
     axios.post(url, data)
     .then(res => {

      this.setState({
        messageShow: true,
        messageType: res.data.resType,
        Message:res.data.message,
      });

     }).catch(err => console.log(err));

  }

  selectImages = (event) => {
    let images = []
    for (var i = 0; i < event.target.files.length; i++) {
      images[i] = event.target.files.item(i);
    }
    images = images.filter(image => image.name.match(/\.(jpg|jpeg|png|gif|rar)$/))
    //let message = `${images.length} valid image(s) selected`
    this.setState({ images })

  }

render() {

    return (

      <div className="container mp0">

        <SchoolLogo SchoolName="Inzeal Infotech" />

        {this.state.messageShow ? <ReactMessage func={this.hideMessage} messageType={this.state.messageType} Message={this.state.Message} /> : null}

        <Navbar />

        
          <div className="form-wrapper" style={{width:"100%"}}>
              <h2>School Setup</h2>
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group col-sm-3 col-md-3 col-lg-3 float-left">
                    <input type="text" 
                            className="form-control" 
                            placeholder="School Code" 
                            name="schoolCode"
                            value={this.state.FormGroup.schoolCode}
                            onChange={this.handleInputChange} />
                  </div>

                  <div className="form-group col-sm-3 col-md-3 col-lg-3 float-left">
                    <input type="text" 
                            className="form-control" 
                            placeholder="School Name" 
                            name="schoolName" 
                            value={this.state.FormGroup.schoolName} 
                            onChange={this.handleInputChange} />
                  </div>

                  <div className="form-group col-sm-3 col-md-3 col-lg-3 float-left">
                    <input type="email" 
                            className="form-control" 
                            placeholder="Enter Email" 
                            name="email" 
                            value={this.state.FormGroup.email} 
                            onChange={this.handleInputChange} />
                  </div>

                  <div className="form-group col-sm-3 col-md-3 col-lg-3 float-left">
                    <input type="text" 
                            className="form-control" 
                            placeholder="Contect No" 
                            name="contactNo" 
                            value={this.state.FormGroup.contactNo} 
                            onChange={this.handleInputChange} />
                  </div>

                  <div className="form-group col-sm-3 col-md-3 col-lg-3 float-left">
                    <input type="text" 
                            className="form-control" 
                            placeholder="Address" 
                            name="Address" 
                            value={this.state.FormGroup.Address} 
                            onChange={this.handleInputChange} />
                  </div>

                  <div className="form-group col-sm-3 col-md-3 col-lg-3 float-left">
                      <input className="form-control " type="file" onChange={this.selectImages} />
                      <p className="text-info">{this.state.message}</p>
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
                      <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                </div>
              </form>
          </div>
      </div>
      

    );
  }
}
export default SchoolSetup;

