import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getWardList} from './WardAction';
import {showMessage} from '../../../store/action/MessageAction';
import {filter} from '../../utils/FilterJson';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class WardList extends Component {
  
  state = {
    studentList:this.props.studentList,
    search:""
  }

  componentDidMount() {
    this.props.changeStateToReducer();
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevProps.studentList !== this.props.studentList) {
      this.setState({
        studentList: this.props.studentList
      })
    }
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;

    this.setState({
      search:value,
      studentList: filter.arrayOfObject(this.props.studentList, value)
    })

  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

render() {

  const renderTable  = (List) => {
   
    return(
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Student Code</th>
              <th>Student Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Father Name</th>
              <th>Mother Name</th>
              <th>Class Name</th>
              <th>Roll No</th>
              <th>Password</th>
            </tr>
          </thead>
          <tbody>
            {List.map((item, index) => 

              <tr key={index}>
                <td>{item.studentCode}</td>
                <td>{item.studentName}</td>
                <td>{item.Phone}</td>
                <td>{item.Email}</td>
                <td>{item.fatherName}</td>
                <td>{item.motherName}</td>
                <td>{item.className}</td>
                <td>{item.rollNo}</td>
                <td>{item.Password}</td>
               
              </tr>
              
            )}
          </tbody>
        </table>
    );
  }

  const Student = this.props.studentLoading ? 
            <div className="loader"><img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} /></div> : 
            renderTable(this.state.studentList);
  
    return (

      <div style={{overflow: "auto"}}>
        {/* <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
          <input type="text" 
              className="form-control" 
              placeholder="Search......" 
              name="search" 
              value={this.state.search} 
              onChange={this.handleInputChange} />
        </div> */}
        {Student}
      </div>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    studentList: state.wardReducer.wardList,
    studentLoading:state.wardReducer.wardLoading
  });
}


function mapDispatchToProps(dispatch) {
  return ({
      changeStateToReducer: () => {
        dispatch(getWardList());
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(WardList);


