import React, { Component } from 'react';
import axios from 'axios';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import {SchoolLogo} from '../../../utils/SchoolLogo';
import Navbar from '../../../Navbar';
import {ReactMessage} from '../../../utils/ReactMessage';
import {CheckSession} from '../../../utils/CheckSession';

class BranchSetup extends Component {

  constructor(props){
    super(props);
    this.state = {
      isUpdate:false,
      images: [],
      FormGroup: {
        branchCode: '',
        branchName: '',
        branchEmail: '',
        branchContactNo: '',
        branchAddress: '',
        inchargePerson: '',
        inchargePersonContact: ''
      },
      messageShow: false,
      messageType: 'success',
      Message:'',
    };

    this.mounted = true;
    CheckSession(this.props);

  }

  componentWillUnmount(){
    this.mounted = false;
  }

  componentDidMount() {
    this.getBranchDetail();
  }

  hideMessage = () => {
    this.setState({
      messageShow: false,
      messageType: 'success',
      Message: '',
    });
  }

  getBranchDetail = () => {
  
    axios.get(`${process.env.PUBLIC_URL}/api/getBranchById/${this.props.match.params.id}`)
    .then(res => {

        if(res.data.resType === 'success'){
          const UserInput = this.state.FormGroup;
          UserInput['branchCode'] = res.data.branch.branchCode;
          UserInput['branchName'] = res.data.branch.branchName;
          UserInput['branchEmail'] = res.data.branch.branchEmail;
          UserInput['branchContactNo'] = res.data.branch.branchContactNo;
          UserInput['branchAddress'] = res.data.branch.branchAddress;
          UserInput['inchargePerson'] = res.data.branch.inchargePerson;
          UserInput['inchargePersonContact'] = res.data.branch.inchargePersonContact;
  
          if(this.mounted){
            this.setState({
              FormGroup: UserInput,
              isUpdate: true
            });
          }
          
        }
        else{
          if(this.mounted){
            this.clearForm();

          }
        }

    }).catch(err => console.log(err));
  }

  clearForm = () =>{
    const UserInput = this.state.FormGroup;
    UserInput['branchCode'] = '';
    UserInput['branchName'] = '';
    UserInput['branchEmail'] = '';
    UserInput['branchContactNo'] = '';
    UserInput['branchAddress'] = '';
    UserInput['inchargePerson'] = '';
    UserInput['inchargePersonContact'] = '';
  
    this.setState({
        FormGroup: UserInput,
        isUpdate: false
    });
  }

  handleInputChange = (event) => {
        
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
        FormGroup: UserInput
    });

  }


  handleSubmit = (event) => {
    event.preventDefault();

     const Branch = this.state.FormGroup

     let url = `${process.env.PUBLIC_URL}/api/SaveBranch`;

     if(this.state.isUpdate){
        url = `${process.env.PUBLIC_URL}/api/updateBranchById/${this.props.match.params.id}`;
     }
    
     axios.post(url, Branch)
     .then(res => {

        if(res.data.resType === 'success'){
          this.clearForm();
        }

        this.setState({
          messageShow: true,
          messageType: res.data.resType,
          Message:res.data.message,
        });

     }).catch(err => console.log(err));

  }

render() {

    return (

      <div className="container mp0">

        <SchoolLogo SchoolName="Inzeal Infotech" />

        {this.state.messageShow ? <ReactMessage func={this.hideMessage} messageType={this.state.messageType} Message={this.state.Message} /> : null}

        <Navbar />

        
          <div className="form-wrapper" style={{width:"100%"}}>
              <h2>Branch Setup</h2>
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group col-sm-3 col-md-3 col-lg-3 float-left">
                    <input type="text" 
                            className="form-control" 
                            placeholder="Branch Code" 
                            name="branchCode"
                            value={this.state.FormGroup.branchCode}
                            onChange={this.handleInputChange} />
                  </div>

                  <div className="form-group col-sm-3 col-md-3 col-lg-3 float-left">
                    <input type="text" 
                            className="form-control" 
                            placeholder="Branch Name" 
                            name="branchName" 
                            value={this.state.FormGroup.branchName} 
                            onChange={this.handleInputChange} />
                  </div>

                  <div className="form-group col-sm-3 col-md-3 col-lg-3 float-left">
                    <input type="email" 
                            className="form-control" 
                            placeholder="Enter Email" 
                            name="branchEmail" 
                            value={this.state.FormGroup.branchEmail} 
                            onChange={this.handleInputChange} />
                  </div>

                  <div className="form-group col-sm-3 col-md-3 col-lg-3 float-left">
                    <input type="text" 
                            className="form-control" 
                            placeholder="Branch Contect No" 
                            name="branchContactNo" 
                            value={this.state.FormGroup.branchContactNo} 
                            onChange={this.handleInputChange} />
                  </div>

                  <div className="form-group col-sm-3 col-md-3 col-lg-3 float-left">
                    <input type="text" 
                            className="form-control" 
                            placeholder="Address" 
                            name="branchAddress" 
                            value={this.state.FormGroup.branchAddress} 
                            onChange={this.handleInputChange} />
                  </div>

                  <div className="form-group col-sm-3 col-md-3 col-lg-3 float-left">
                    <input type="text" 
                            className="form-control" 
                            placeholder="Incharge Person" 
                            name="inchargePerson" 
                            value={this.state.FormGroup.inchargePerson} 
                            onChange={this.handleInputChange} />
                  </div>

                  <div className="form-group col-sm-3 col-md-3 col-lg-3 float-left">
                    <input type="text" 
                            className="form-control" 
                            placeholder="Incharge Person Contact" 
                            name="inchargePersonContact" 
                            value={this.state.FormGroup.inchargePersonContact} 
                            onChange={this.handleInputChange} />
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
                      <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                </div>
              </form>
          </div>
        </div>
      

    );
  }
}
export default BranchSetup

