import React, { Component } from 'react';
import { connect } from 'react-redux';
import {showHideForm, getStudentList, updateStudentId, updateStudentList} from './Action';
import {showMessage} from '../../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class StudentCardView extends Component {

  getHomeWorkDet = (id, index) => {
    //this.props.getStudentDetail(id);
    if(!this.props.isSelectedFlag){
      this.props.showHideForm();
      this.props.updateStudentId(id)
    }
    else{
      this.checkboxChange(index)
    }
    
  }


  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  checkboxChange = (index) => {
   
    let list = [...this.props.studentList];

    if(list[index].isSelect){
      list[index].isSelect = false
    }
    else{
      list[index].isSelect = true
    }

    this.props.updateStudentList(list)

  }


render() {


  const Card  = (List) => {
    
    return(

      <div className="row">

        {List.map((item, index) => 

          <div key={index} className="col-sm-6 col-md-6 col-lg-4"> 
            
              <div onClick={this.getHomeWorkDet.bind(this, item._id, index)} 
                    className={`thum_card_con ${item.isSelect ? 'active' : ''}`} style={{height: "100px"}}> 

                <img className="card_img" 
                  src={process.env.PUBLIC_URL+"/Students/ProfilePhoto/"+item.photoPath} 
                  alt="Card" /> 
                  
                <div className="card_body" style={{padding:"5px", overflow:"hidden"}}> 
                  <h6 className="card_title" style={{marginBottom:'1px'}}>{item.studentName}</h6> 
                  <p className="card_p"> Code : {item.studentCode}</p> 
                  <p className="card_p">Class : {item.className}</p> 

                  {!this.props.isSelectedFlag ? 
                    <span className="card_p">
                      Assign Home Work <i style={{fontSize: "13px"}} className="far fa-edit"></i>
                    </span> : 
                    <span className="card_p">
                      <input type="checkbox" onChange={(e) => {console.log('checked')}} checked={item.isSelect} /> Select
                    </span> }
                  
                </div>

                <i className="clearfix"></i>

              </div>
            
          </div>

        )}

      </div>
    );
  }

  const Student = this.props.studentLoading ? <i>No Data</i> : Card(this.props.studentList);
 
  
    return (

      <React.Fragment>
        {Student}
      </React.Fragment>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    studentList: state.assignHomeWork.studentList,
    studentLoading: state.assignHomeWork.studentLoading,
    isSelectedFlag: state.assignHomeWork.isSelectedFlag
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      changeStateToReducer: () => {
        dispatch(getStudentList());
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      },
      updateStudentId: (id) => {
        dispatch(updateStudentId(id));
      },
      updateStudentList: (list) => {
        dispatch(updateStudentList(list));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(StudentCardView);