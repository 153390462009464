import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {getHomeWorkList, showHideForm} from './Action';
import {showMessage} from '../../../../store/action/MessageAction';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class HomeWorkForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      images: [],
      selectedImage: "",
      FormGroup: {
        homeWork: '',
      }
    };

  }

  componentDidMount(){
    if(!this.props.isSelectedFlag) {
      this.props.getHomeWorkList(this.props.studentId, this.props.subjectId);
    }
  }

  componentDidUpdate(){
    this.refs.messages.scrollTop = this.refs.messages.scrollHeight
  }

  componentWillUnmount(){
    this.props.clearHomeWork();
  }

  clearForm = () => {

    const UserInput = {};
    UserInput['homeWork'] = '';

    this.setState({
        FormGroup: UserInput,
        images: [],
        selectedImage: ""
    });
    
  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

  }

  selectImages = (event) => {
    let images = [];
    let selectedImage = "";

    for (var i = 0; i < event.target.files.length; i++) {
      images[i] = event.target.files.item(i);
      selectedImage += event.target.files.item(i).name;
    }

    images = images.filter(image => image.name.match(/\.(jpg|jpeg|png|gif|rar|pdf|mp3)$/))
    //let message = `${images.length} valid image(s) selected`
    this.setState({ images, selectedImage:selectedImage })

  }

  // componentDidUpdate(prevProps, prevState) {
  //   // only update chart if the data has changed
  //   if (prevProps.studentDetail !== this.props.studentDetail) {
      

  //     if(this.props.studentDetail !== ''){
  //       const UserInput = this.props.studentDetail;
  //        //UserInput['dateOfBirth'] = this.GetFormattedDate(this.props.studentDetail.dateOfBirth);

  //       this.setState({
  //         FormGroup: UserInput,
  //         studentId:this.props.studentDetail._id,
  //         isUpdate: true
  //       })

  //     }
  //   }
  // }

  handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData();

    this.state.images.forEach(image => {
      data.append("image", image, image.name);
    });
    
    data.append('subjectId',this.props.subjectId);
    data.append('homeWork',this.state.FormGroup.homeWork);

    //if all student selected

    let url = "";
    if(this.props.isSelectedFlag){
      data.append('studentId',JSON.stringify(this.props.studentList));
      url = `${process.env.PUBLIC_URL}/api/homeWork/SaveToAll`;
    }
    else{
      data.append('studentId',this.props.studentId);
      url = `${process.env.PUBLIC_URL}/api/homeWork/Save`;
    }

    axios.post(url, data)
    .then(res => {

        if(res.data.resType === 'success'){
          this.clearForm();
          if(this.props.isSelectedFlag){
            this.props.showHideForm();
          }
          else{
            this.props.getHomeWorkList(this.props.studentId, this.props.subjectId);
          }
          
        }

        this.props.showMessage({
          messageType: res.data.resType,
          Message:res.data.message
        });

     }).catch(err => console.log(err));

  }

render() {

  const extension = (data) => {
    return data.split('.').pop();
  }

  const Card  = (List) => {
    
    return(

      <div className="card_list_area" ref="messages" style={{overflow: "auto"}}>

        {List.map((item, index) => 
          
          <div key={index} className="notifi_card" style={{width:"100%"}}>

            {'photoPath' in item ?(item.photoPath !== "" ? (

              ['pdf', 'rar', 'mp3'].includes(extension(item.photoPath)) ? 
                (['pdf', 'rar'].includes(extension(item.photoPath)) ? 
                <div style={{maxWidth:"400px", display:"block", border:"5px solid white", borderRadius: "10px", backgroundColor:"white"}}>
                  
                  <div style={{display:"block", position:"relative", paddingLeft:"60px"}}>
                      <i style={{position:"absolute", left:"0", top:"5px", fontSize: "40px"}} className="fas fa-cloud-download-alt"></i>
                      <span style={{display:"block", whiteSpace:"nowrap",width:"100%",overflow: "hidden", textOverflow:"ellipsis"}}>{item.photoPath}</span>
                      <a href={process.env.PUBLIC_URL+"/Students/HomeWork/"+item.photoPath} download> Download</a>
                  </div>

                </div>: 
                <audio controls>
                    <source src={process.env.PUBLIC_URL+"/Students/HomeWork/"+item.photoPath} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
                ) : 
                <div style={{maxWidth:"200px", display:"inline-block", border:"5px solid white", borderRadius: "10px", overflow:"hidden"}}>
                  <a style={{borderRadius: "10px"}} href={process.env.PUBLIC_URL+"/Students/HomeWork/"+item.photoPath} target="_blank">
                    <img src={process.env.PUBLIC_URL+"/Students/HomeWork/"+item.photoPath} 
                      style={{borderRadius: "5px", width:"100%", hight:"auto"}} />
                  </a>
                </div>

              ) : null ):null}

            <p className="title">{item.subjectName}</p>
            <p className="message">{item.homeWork}</p>
            <p className="date">{item.entryOn}</p>
          </div>
          
        )}

      </div>
    );
  }

  const CardList = Card(this.props.homeWorkList);
  
    return (
      <div className='form-modle'>
          
          <form onSubmit={this.handleSubmit} style={{maxWidth: '500px', marginTop: "0px", height: "100%"}}>
            <h2 className="head_h2"> Home Work</h2>
            <i onClick={this.props.showHideForm} className="fas fa-arrow-left close-btn"></i>
            
            {CardList}

            <div className="typer_con">
              {this.state.selectedImage !== "" ? <p>{this.state.selectedImage}</p> : null}
              <div className="textarea_con">
                <textarea
                  placeholder="Assign Home work ......." 
                  rows="1"
                  name="homeWork" 
                  value={this.state.FormGroup.homeWork} 
                  onChange={this.handleInputChange} />

                <label htmlFor="imag" className="fa fa-paperclip" style={{cursor:"pointer", position:"absolute", right:"5px", top:"5px"}}></label>
                <input id="imag" style={{display:"none"}} type="file" onChange={this.selectImages} />
              </div>
              
              <button type="submit" className="btn btn-sm btn-primary"><i className="far fa-paper-plane"></i></button>

            </div>

          </form>

      </div>

      
       
    );
  }
}

function mapStateToProps(state) {
  return ({
    studentId: state.assignHomeWork.studentId,
    subjectId: state.assignHomeWork.subjectId,
    homeWorkList: state.assignHomeWork.homeWorkList,
    isSelectedFlag: state.assignHomeWork.isSelectedFlag,
    studentList: state.assignHomeWork.studentList
  });
}

function mapDispatchToProps(dispatch) {
  return ({
    getHomeWorkList: (id, subjectId) => {
        dispatch(getHomeWorkList(id, subjectId));
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(false));
      },
      clearHomeWork: () => {
        dispatch({
          type: 'UPDATE_HOME_WORK_LIST',
          payload: []
        })
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(HomeWorkForm);

