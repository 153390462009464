import React, { Component } from 'react';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import {SchoolLogo} from '../../../utils/SchoolLogo';
import Navbar from '../../../Navbar';
import {ReactMessage} from '../../../utils/ReactMessage';
import {CheckSession} from '../../../utils/CheckSession';
import BranchDrop from '../../../utils/BranchDrop';
import {User} from '../../../utils/ValidateUser';
import TeacherDrop from '../../../utils/TeacherDrop';
import {PermissionService} from '../../../../Services/master'

class PermissionMaster extends Component {

  constructor(props){
    super(props);

    this.state = {
      listLoading: true,
      list:[],
      alertMessage:{messageShow:false, messageType:'success', Message: ''},
      FormGroup:{branchId: '0', teacherId: '0'}
    }

    CheckSession(this.props);
  }

  hideMessage = () => {

    const alertMessage = this.state.alertMessage;
    alertMessage['Message'] = '';
    alertMessage['messageShow'] = false;
    alertMessage['messageType'] = 'success';

    this.setState({
      alertMessage:alertMessage
    });

  }

  handleInputChange = (event) => {
        
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
        FormGroup: UserInput
    });

    if(name === "teacherId"){
      this.getPermissionList(this.state.FormGroup.teacherId);
    }

  }

  getPermissionList = (id) => {
    if(id === '' || id === null){
      id='0'
    }
    PermissionService.getPermissionList(id).then(res => {
      
      if(res.data.resType === 'success'){
        console.log(res.data)
        this.setState({
          list: res.data.permission,
          listLoading: false,
        })
      }
    });
  }

  // check uncheck checkboxes
  checkboxChange = (e, index, key) => {
   
    var list = this.state.list;

    if(list[index][key]){
      list[index][key] = false
    }
    else{
      list[index][key] = true
    }

    this.setState({
      list:list
    });

  }

  // final submit 
  handleSubmit = () => {
    
    const list = this.state.list;
    let id = this.state.FormGroup.teacherId

    if(id === ''){
      this.setState({
        alertMessage:{messageShow:true, messageType: 'failed', Message: 'select teacher'}
      });
    }
    else if(id === null){
      this.setState({
        alertMessage:{messageShow:true, messageType: 'failed', Message: 'select teacher'}
      });
    }
    else{
      PermissionService.savePermission(id, list).then(res => {
    
        this.setState({
          alertMessage:{messageShow:true, messageType:res.data.resType, Message: res.data.message}
        });
      })
    }

  }

render() {

  const renderTable  = (List) => {
    return(
      <React.Fragment>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Menu</th>
              <th>View</th>
              <th>Save</th>
              <th>Update</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {List.map((item, index) => 
              
              <tr key={index}>
                <td>{item.menuName}</td>
                <td><input type="checkbox" onChange={(e) => {this.checkboxChange(e, index, 'isView')}} checked={item.isView} /></td>
                <td><input type="checkbox" onChange={(e) => {this.checkboxChange(e, index, 'isSave')}} checked={item.isSave} /></td>
                <td><input type="checkbox" onChange={(e) => {this.checkboxChange(e, index, 'isUpdate')}} checked={item.isUpdate} /></td>
                <td><input type="checkbox" onChange={(e) => {this.checkboxChange(e, index, 'isDelete')}} checked={item.isDelete} /></td>
              </tr>

            )}
          </tbody>
        </table>

        <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
            <button onClick={this.handleSubmit} className="btn btn-sm btn-primary">Submit</button>
        </div>

      </React.Fragment>
    );

  }

  const PermissionList = this.state.listLoading ? <i>loading.......</i> : renderTable(this.state.list)
  const {alertMessage} = this.state;

    return (

      <div className="container mp0">
        
        <SchoolLogo SchoolName="Inzeal Infotech" />

        {alertMessage.messageShow ? <ReactMessage func={this.hideMessage} messageType={alertMessage.messageType} Message={alertMessage.Message} /> : null}

        <Navbar />

        
        <div className="form-wrapper" style={{width:"100%"}}>

          <h2>Permission</h2>
          <div className="row">
            {User.isAdmin() ? 
            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Branch</label>
                <BranchDrop 
                  BranchId={this.state.FormGroup.branchId} 
                  func={this.handleInputChange} />
            </div>: null}

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Teacher</label>
                <TeacherDrop 
                  BranchId={this.state.FormGroup.branchId} 
                  TeacherId={this.state.FormGroup.teacherId} 
                  func={this.handleInputChange} />
            </div>
          </div>
        </div>

        <div className="form-wrapper" style={{width:"100%"}}>
            <h2>Menu List</h2>
            <div style={{display: 'block', width:'100%', clear: 'both', overflow:"auto"}}>
                {PermissionList}
            </div>
        </div>

      </div>

    );
  }
}

export default PermissionMaster;

