import axios from 'axios';

export function getFeeList(branchId, classId, studentId, feeTypeId, fromDate, toDate) {
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/getFeeReportList/${branchId}&${classId}&${studentId}&${feeTypeId}&${fromDate}&${toDate}`)
        .then(response =>

            getFeeListRes(dispatch, response)

        ).catch(err => console.log(err));
    }
}

function getFeeListRes(dispatch, response) {
   
    try {
        console.log(response.data);
       if(response.data.resType === 'success'){

            return dispatch({
                type: 'UPDATE_FEE_REPORT_LIST',
                payload: response.data.feeReport
            })
       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data.message
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}





