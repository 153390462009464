import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {getTeacherList, showHideForm} from '../../../../store/action/TeacherMasterAction';
import {showMessage} from '../../../../store/action/MessageAction';
import StateDrop from '../../../utils/StateDrop';
import CityDrop from '../../../utils/CityDrop';
import DesignationDrop from '../../../utils/DesignationDrop';
import GenderDrop from '../../../utils/GenderDrop';
import ReligionDrop from '../../../utils/ReligionDrop';
import BranchDrop from '../../../utils/BranchDrop';
import {User} from '../../../utils/ValidateUser';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class TeacherForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      teacherId:0,
      isUpdate:false,
      images: [],
      FormGroup: {
        teacherName: '',
        teacherEmail: '',
        teacherPhone: '',
        branchId:'0',
        nationalId: '',
        designationId: '0',
        dateOfBirth: '',
        Gender: 'Male',
        religionId: '0',
        joiningDate: '',
        stateId: '0',
        cityId: '0',
        Address: '',
        Password: ''
      }
    };

  }

  clearForm = () => {
    const UserInput = {};
    
        UserInput['teacherName'] = '';
        UserInput['teacherEmail'] = '';
        UserInput['teacherPhone'] = '';
        UserInput['branchId'] = '';
        UserInput['nationalId'] = '';
        UserInput['designationId'] = '';
        UserInput['dateOfBirth'] = '';
        UserInput['Gender'] = 'Male';
        UserInput['religionId'] = '0';
        UserInput['joiningDate'] = '';
        UserInput['stateId'] = '0';
        UserInput['cityId'] = '0';
        UserInput['Address'] = '';
        UserInput['Password'] = '';

    this.setState({
        FormGroup: UserInput,
        teacherId:0,
        isUpdate: false
    });
    
  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

  }

  selectImages = (event) => {
    let images = []
    for (var i = 0; i < event.target.files.length; i++) {
      images[i] = event.target.files.item(i);
    }
    images = images.filter(image => image.name.match(/\.(jpg|jpeg|png|gif|rar)$/))
    //let message = `${images.length} valid image(s) selected`
    this.setState({ images })

  }

  componentDidUpdate(prevProps, prevState) {
    
    // only update chart if the data has changed
    if (prevProps.teacherDetail !== this.props.teacherDetail) {
      
      if(this.props.teacherDetail !== ''){
     
         const UserInput = this.props.teacherDetail;
         UserInput['dateOfBirth'] = this.GetFormattedDate(this.props.teacherDetail.dateOfBirth);
         UserInput['joiningDate'] = this.GetFormattedDate(this.props.teacherDetail.joiningDate);

        this.setState({
          FormGroup: UserInput,
          teacherId:this.props.teacherDetail._id,
          isUpdate: true
        })

      }
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData();

    this.state.images.forEach(image => {
      data.append("image", image, image.name);
    });

     data.append('teacherName',this.state.FormGroup.teacherName);
     data.append('teacherEmail',this.state.FormGroup.teacherEmail);
     data.append('teacherPhone',this.state.FormGroup.teacherPhone);
     data.append('branchId',this.state.FormGroup.branchId);
     data.append('nationalId',this.state.FormGroup.nationalId);
     data.append('designationId',this.state.FormGroup.designationId);
     data.append('dateOfBirth',this.state.FormGroup.dateOfBirth);
     data.append('Gender',this.state.FormGroup.Gender);
     data.append('religionId',this.state.FormGroup.religionId);
     data.append('joiningDate',this.state.FormGroup.joiningDate);
     data.append('stateId',this.state.FormGroup.stateId);
     data.append('cityId',this.state.FormGroup.cityId);
     data.append('Address',this.state.FormGroup.Address);
     data.append('Password',this.state.FormGroup.Password);

   
    let url = `${process.env.PUBLIC_URL}/api/SaveTeacher`;

    if(this.state.isUpdate){
      url = `${process.env.PUBLIC_URL}/api/updateTeacherById/${this.state.teacherId}`;
    }

    axios.post(url, data)
    .then(res => {

        if(res.data.resType === 'success'){
          this.clearForm();
          this.props.getTeacherList();
        }

        this.props.showMessage({
          messageType: res.data.resType,
          Message:res.data.message
        });

     }).catch(err => console.log(err));

  }

render() {

    return (
      <div className='form-modle'>
          
          <form onSubmit={this.handleSubmit} style={{maxWidth: '800px'}}>
            <h2 className="head_h2"> Teacher </h2>
            <i onClick={this.props.showHideForm} className="fas fa-arrow-left close-btn"></i>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
              <label>Full Name</label>
              <input type="text" 
                    className="form-control" 
                    placeholder="Full Name" 
                    name="teacherName" 
                    value={this.state.FormGroup.teacherName} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
              <label>Email</label>
              <input type="text" 
                    className="form-control" 
                    placeholder="Email" 
                    name="teacherEmail" 
                    value={this.state.FormGroup.teacherEmail} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
              <label>Phone No.</label>
              <input type="text" 
                    className="form-control" 
                    placeholder="Phone No." 
                    name="teacherPhone" 
                    value={this.state.FormGroup.teacherPhone} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
              <label>National Id</label>
              <input type="text" 
                    className="form-control" 
                    placeholder="National Id" 
                    name="nationalId" 
                    value={this.state.FormGroup.nationalId} 
                    onChange={this.handleInputChange} />
            </div>
            {User.isAdmin() ? 
            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Branch</label>
                <BranchDrop 
                  BranchId={this.state.FormGroup.branchId} 
                  func={this.handleInputChange} />
            </div>: null}

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Designation</label>
                <DesignationDrop 
                  DesignationId={this.state.FormGroup.designationId} 
                  func={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
              <label>Date of Birth</label>
              <input type="date" 
                    className="form-control" 
                    placeholder="Date Of Birth" 
                    name="dateOfBirth" 
                    value={this.state.FormGroup.dateOfBirth} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Gender</label>
                <GenderDrop 
                  Gender={this.state.FormGroup.Gender} 
                  func={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Religion</label>
                <ReligionDrop 
                  ReligionId={this.state.FormGroup.religionId} 
                  func={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
              <label>Date of Joining</label>
              <input type="date" 
                    className="form-control" 
                    placeholder="Date of Joining" 
                    name="joiningDate" 
                    value={this.state.FormGroup.joiningDate} 
                    onChange={this.handleInputChange} />
            </div>
            

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>State</label>
                <StateDrop 
                  StateId={this.state.FormGroup.stateId} 
                  func={this.handleInputChange}/>
            </div>


            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>City</label>
                <CityDrop 
                  StateId={this.state.FormGroup.stateId} 
                  CityId={this.state.FormGroup.cityId} 
                  func={this.handleInputChange}/>
            </div>

             <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
             <label>Address</label>
              <input type="text" 
                    className="form-control" 
                    placeholder="Address" 
                    name="Address" 
                    value={this.state.FormGroup.Address} 
                    onChange={this.handleInputChange} />
            </div>

             <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
             <label>Password</label>
              <input type="text" 
                    className="form-control" 
                    placeholder="Password" 
                    name="Password" 
                    value={this.state.FormGroup.Password} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-3 col-md-3 col-lg-3 float-left">
                <label>Photo</label>
                <input className="form-control " type="file" onChange={this.selectImages} />
                <p className="text-info">{this.state.message}</p>
            </div>

            <div className="col-sm-4 col-md-4 col-lg-4" style={{clear:"both"}}>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>

          </form>

      </div>

      
       
    );
  }
}

function mapStateToProps(state) {
  return ({
    teacherDetail: state.teacherMasterReducer.teacherDetail
  });
}

function mapDispatchToProps(dispatch) {
  return ({
    getTeacherList: () => {
        dispatch(getTeacherList());
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(false));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(TeacherForm);

