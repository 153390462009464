import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {getStudentList, showHideForm} from '../../../../store/action/StudentMasterAction';
import {showMessage} from '../../../../store/action/MessageAction';
import GenderDrop from '../../../utils/GenderDrop';
import ReligionDrop from '../../../utils/ReligionDrop';
import ParentDrop from '../../../utils/ParentDrop';
import ClassDrop from '../../../utils/ClassDrop';
import BranchDrop from '../../../utils/BranchDrop';
import {User} from '../../../utils/ValidateUser';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class StudentForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      studentId:0,
      isUpdate:false,
      images: [],
      FormGroup: {
        parentId: '0',
        studentName: '',
        Email: '',
        Phone: '',
        Address: '',
        dateOfBirth: '',
        Gender: 'Male',
        branchId: '0',
        religionId: '0',
        classId: '0',
        rollNo: '',
        Password: ''
      }
    };

  }

  clearForm = () => {
    const UserInput = {};
    
        UserInput['parentId'] = '';
        UserInput['studentName'] = '';
        UserInput['Email'] = '';
        UserInput['Phone'] = '';
        UserInput['Address'] = '';
        UserInput['dateOfBirth'] = '';
        UserInput['classId'] = '0';
        UserInput['rollNo'] = '';
        UserInput['Gender'] = 'Male';
        UserInput['branchId'] = '';
        UserInput['religionId'] = '';
        UserInput['Password'] = '';

    this.setState({
        FormGroup: UserInput,
        studentId:0,
        isUpdate: false
    });
    
  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

  }

  selectImages = (event) => {
    let images = []
    for (var i = 0; i < event.target.files.length; i++) {
      images[i] = event.target.files.item(i);
    }
    images = images.filter(image => image.name.match(/\.(jpg|jpeg|png|gif|rar)$/))
    //let message = `${images.length} valid image(s) selected`
    this.setState({ images })

  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevProps.studentDetail !== this.props.studentDetail) {
      

      if(this.props.studentDetail !== ''){
        const UserInput = this.props.studentDetail;
         UserInput['dateOfBirth'] = this.GetFormattedDate(this.props.studentDetail.dateOfBirth);

        this.setState({
          FormGroup: UserInput,
          studentId:this.props.studentDetail._id,
          isUpdate: true
        })

      }
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData();

    this.state.images.forEach(image => {
      data.append("image", image, image.name);
    });

        data.append('parentId',this.state.FormGroup.parentId);
        data.append('studentName',this.state.FormGroup.studentName);
        data.append('Email',this.state.FormGroup.Email);
        data.append('Phone',this.state.FormGroup.Phone);
        data.append('Address',this.state.FormGroup.Address);
        data.append('dateOfBirth',this.state.FormGroup.dateOfBirth);
        data.append('classId',this.state.FormGroup.classId);
        data.append('rollNo',this.state.FormGroup.rollNo);
        data.append('Gender',this.state.FormGroup.Gender);
        data.append('branchId',this.state.FormGroup.branchId);
        data.append('religionId',this.state.FormGroup.religionId);
        data.append('Password',this.state.FormGroup.Password);

   
    let url = `${process.env.PUBLIC_URL}/api/SaveStudent`;

    if(this.state.isUpdate){
      url = `${process.env.PUBLIC_URL}/api/updateStudentById/${this.state.studentId}`;
    }

    axios.post(url, data)
    .then(res => {

        if(res.data.resType === 'success'){
          this.clearForm();
          this.props.getStudentList();
        }

        this.props.showMessage({
          messageType: res.data.resType,
          Message:res.data.message
        });

     }).catch(err => console.log(err));

  }

render() {

    return (
      <div className='form-modle'>
          
          <form onSubmit={this.handleSubmit} style={{maxWidth: '800px'}}>
            <h2 className="head_h2"> Students </h2>
            <i onClick={this.props.showHideForm} className="fas fa-arrow-left close-btn"></i>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Parent</label>
                <ParentDrop 
                  InputName='parentId' 
                  ParentId={this.state.FormGroup.parentId} 
                  func={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
              <label>Student Name</label>
              <input type="text" 
                    className="form-control" 
                    placeholder="Student Name" 
                    name="studentName" 
                    value={this.state.FormGroup.studentName} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
              <label>Email</label>
              <input type="email" 
                    className="form-control" 
                    placeholder="Email" 
                    name="Email" 
                    value={this.state.FormGroup.Email} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
              <label>Phone</label>
              <input type="text" 
                    className="form-control" 
                    placeholder="Phone" 
                    name="Phone" 
                    value={this.state.FormGroup.Phone} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
              <label>Address</label>
              <input type="text" 
                    className="form-control" 
                    placeholder="Address" 
                    name="Address" 
                    value={this.state.FormGroup.Address} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
              <label>Date Of Birth</label>
              <input type="date" 
                    className="form-control" 
                    placeholder="Date Of Birth" 
                    name="dateOfBirth" 
                    value={this.state.FormGroup.dateOfBirth} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Gender</label>
                <GenderDrop 
                  Gender={this.state.FormGroup.Gender} 
                  func={this.handleInputChange} />
            </div>

            {User.isAdmin() ? 
            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Branch</label>
                <BranchDrop 
                  BranchId={this.state.FormGroup.branchId} 
                  func={this.handleInputChange} />
            </div>: null}

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Religion</label>
                <ReligionDrop 
                  ReligionId={this.state.FormGroup.religionId} 
                  func={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Class</label>
                <ClassDrop 
                  InputName='classId' 
                  ClassId={this.state.FormGroup.classId} 
                  func={this.handleInputChange} />
            </div>

             <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
                <label>Roll No</label>
                <input type="text" 
                      className="form-control" 
                      placeholder="Roll No." 
                      name="rollNo" 
                      value={this.state.FormGroup.rollNo} 
                      onChange={this.handleInputChange} />
              </div>

             <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
             <label>Password</label>
              <input type="text" 
                    className="form-control" 
                    placeholder="Password" 
                    name="Password" 
                    value={this.state.FormGroup.Password} 
                    onChange={this.handleInputChange} />
            </div>

            <div className="form-group col-sm-3 col-md-3 col-lg-3 float-left">
                <label>Photo</label>
                <input className="form-control " type="file" onChange={this.selectImages} />
                <p className="text-info">{this.state.message}</p>
            </div>

            <div className="col-sm-4 col-md-4 col-lg-4" style={{clear:"both"}}>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>

          </form>

      </div>

      
       
    );
  }
}

function mapStateToProps(state) {
  return ({
    studentDetail: state.studentMasterReducer.studentDetail
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      getStudentList: () => {
        dispatch(getStudentList());
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(false));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(StudentForm);

