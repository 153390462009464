const INITIAL_STATE = {
    isSubjectFormShow: false,
    subjectLoading:true,
    subjectDetail:'',
    subjectList:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_SUBJECT_DETAIL':
          return ({
              ...states,
              subjectDetail: action.payload
          });

        case 'UPDATE_SUBJECT_LIST':
            return ({
                ...states,
                subjectList: action.payload,
                subjectLoading : false
            });


        case 'SHOW_HIDE_SUBJECT_FORM':
            return({
                ...states,
                isSubjectFormShow:action.payload
            });

        default:
          return states;

  }
}
