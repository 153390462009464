const INITIAL_STATE = {
    allNotificationLoading:true,
    allNotificationList:[],
    isFormShow: false
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_ALL_NOTIFICATION_LIST':
            return ({
                ...states,
                allNotificationList: action.payload,
                allNotificationLoading: false
            });

            case 'UPDATE_ALL_NOTIFICATION_FORM_FLAG':
            return ({
                ...states,
                isFormShow: action.payload
            });

        default:
          return states;

  }
}
