const INITIAL_STATE = {
    isFeeSetupFormShow: false,
    feeSetuploading:true,
    feeSetupDetail:'',
    feeSetupList:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_FEE_SETUP_DETAIL':
          return ({
              ...states,
              feeSetupDetail: action.payload
          });

        case 'UPDATE_FEE_SETUP_LIST':
            return ({
                ...states,
                feeSetupList: action.payload,
                feeSetuploading: false
            });

        case 'SHOW_HIDE_FEE_SETUP_FORM':
            return({
                ...states,
                isFeeSetupFormShow:action.payload
            });

        default:
          return states;

  }
}
