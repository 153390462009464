import React, { Component } from 'react';
import { connect } from 'react-redux';
import {showHideForm} from './Action';
import {showMessage} from '../../../store/action/MessageAction';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import {GoogleAdd} from '../GoogleAdd';

class HomeWorkForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      studentId:0,
      isUpdate:false,
      images: [],
      FormGroup: {
        subjectId: 0,
        studentId:0,
        homeWork: '',
      }
    };

  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }


  // componentDidUpdate(prevProps, prevState) {
  //   // only update chart if the data has changed
  //   if (prevProps.studentDetail !== this.props.studentDetail) {
      

  //     if(this.props.studentDetail !== ''){
  //       const UserInput = this.props.studentDetail;
  //        //UserInput['dateOfBirth'] = this.GetFormattedDate(this.props.studentDetail.dateOfBirth);

  //       this.setState({
  //         FormGroup: UserInput,
  //         studentId:this.props.studentDetail._id,
  //         isUpdate: true
  //       })

  //     }
  //   }
  // }

  

render() {

  const placeForAd = (i) => {
    return i % 4
  }

  const extension = (data) => {
    return data.split('.').pop();
  }

  const Card  = (List) => {
    
    return(

      <div className="card_list_area">
          
          {List.map((item, index) => 
          <React.Fragment key={index}>
            {placeForAd(index) === 0 ? 
              <React.Fragment>
                <div className="notifi_card">
                  <div> 
                    <GoogleAdd />
                  </div>
                </div>
                <div className="notifi_card">
                    {'photoPath' in item ?(item.photoPath !== "" ? (

                    ['pdf', 'rar', 'mp3'].includes(extension(item.photoPath)) ? 
                      (['pdf', 'rar'].includes(extension(item.photoPath)) ? 
                      <div style={{maxWidth:"400px", display:"block", border:"5px solid white", borderRadius: "10px", backgroundColor:"white"}}>
                        
                        <div style={{display:"block", position:"relative", paddingLeft:"60px"}}>
                            <i style={{position:"absolute", left:"0", top:"5px", fontSize: "40px"}} className="fas fa-cloud-download-alt"></i>
                            <span style={{display:"block", whiteSpace:"nowrap",width:"100%",overflow: "hidden", textOverflow:"ellipsis"}}>{item.photoPath}</span>
                            <a href={process.env.PUBLIC_URL+"/Students/HomeWork/"+item.photoPath} download> Download</a>
                        </div>
      
                      </div>: 
                      <audio controls>
                          <source src={process.env.PUBLIC_URL+"/Students/HomeWork/"+item.photoPath} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                      ) : 
                      <div style={{maxWidth:"200px", display:"inline-block", border:"5px solid white", borderRadius: "10px", overflow:"hidden"}}>
                        <a style={{borderRadius: "10px"}} href={process.env.PUBLIC_URL+"/Students/HomeWork/"+item.photoPath} target="_blank">
                          <img src={process.env.PUBLIC_URL+"/Students/HomeWork/"+item.photoPath} 
                            style={{borderRadius: "5px", width:"100%", hight:"auto"}} />
                        </a>
                      </div>

                    ) : null ):null}

                    <p className="title" style={{marginBottom:'1px'}}>{item.subjectName}</p>
                    <p>{item.homeWork}</p>
                    <p className="date">{item.entryOn}</p>
                </div>
                
              </React.Fragment>: 

              <div className="notifi_card">

                {'photoPath' in item ?(item.photoPath !== "" ? (

                ['pdf', 'rar'].includes(extension(item.photoPath)) ? 
                  <div style={{maxWidth:"400px", display:"block", border:"5px solid white", borderRadius: "10px", backgroundColor:"white"}}>
                    
                    <div style={{display:"block", position:"relative", paddingLeft:"60px"}}>
                        <i style={{position:"absolute", left:"0", top:"5px", fontSize: "40px"}} className="fas fa-cloud-download-alt"></i>
                        <span style={{display:"block", whiteSpace:"nowrap",width:"100%",overflow: "hidden", textOverflow:"ellipsis"}}>{item.photoPath}</span>
                        <a href={process.env.PUBLIC_URL+"/Students/HomeWork/"+item.photoPath} download> Download</a>
                    </div>
                    

                  </div> : 
                  <div style={{maxWidth:"200px", display:"inline-block", border:"5px solid white", borderRadius: "10px", overflow:"hidden"}}>
                    <a style={{borderRadius: "10px"}} href={process.env.PUBLIC_URL+"/Students/HomeWork/"+item.photoPath} target="_blank">
                      <img src={process.env.PUBLIC_URL+"/Students/HomeWork/"+item.photoPath} 
                        style={{borderRadius: "5px", width:"100%", hight:"auto"}} />
                    </a>
                  </div>

                ) : null ):null}
                
                <p className="title" style={{marginBottom:'1px'}}>{item.subjectName}</p>
                <p className="message">{item.homeWork}</p>
                <p className="date">{item.entryOn}</p>

              </div>
              
            }
          </React.Fragment>
          )}

      </div>
    );
  }

  const CardList = Card(this.props.homeWorkList);
  

    return (
      <div className='form-modle'>
          
          <form style={{maxWidth:'500px', marginTop:"0px", height:"100%"}}>
            <h2 className="head_h2"> Home Work</h2>
            <i onClick={this.props.showHideForm} className="fas fa-arrow-left close-btn"></i>
            
            {CardList}

          </form>

      </div>
    );
  }
}

function mapStateToProps(state) {
  return ({
    homeWorkList: state.homeWorkReducer.List,
    Loading: state.homeWorkReducer.Loading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(false));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(HomeWorkForm);

