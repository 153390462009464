import React, { Component } from 'react';
import { connect } from 'react-redux';
import {showHideForm} from '../../../../store/action/StateAction';
import {hideMessage} from '../../../../store/action/MessageAction';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import {SchoolLogo} from '../../../utils/SchoolLogo';
import Navbar from '../../../Navbar';
import {ReactMessage} from '../../../utils/ReactMessage';
import {CheckSession} from '../../../utils/CheckSession';

import StateForm from './StateForm';
import StateList from './StateList';


class StateMaster extends Component {

  constructor(props){
    super(props);

    CheckSession(this.props);
  }


render() {

    return (

      <div className="container mp0">

        <SchoolLogo SchoolName="Inzeal Infotech" />

        {this.props.messageShow ? <ReactMessage func={this.props.hideMessage} messageType={this.props.messageType} Message={this.props.Message} /> : null}

        <Navbar />

        
          <div className="form-wrapper" style={{width:"100%"}}>

            <h2>State <button onClick={this.props.showHideForm} className="btn btn-sm btn-info float-right">Add New</button></h2>
            
            {this.props.isFormShow ? <StateForm /> : null}
            <div style={{overflow: "auto"}}>
              <StateList />
            </div>
              
          </div>
        
      </div>

    );
  }
}

function mapStateToProps(state) {
    return ({
      messageShow: state.messageReducer.messageShow,
      messageType: state.messageReducer.messageType,
      Message: state.messageReducer.Message,
      isFormShow: state.stateReducer.isStateFormShow
    });
}

function mapDispatchToProps(dispatch) {
  return ({
    hideMessage: () => {
        dispatch(hideMessage());
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      }
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(StateMaster);

