import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getWardList} from './WardAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class WardCardView extends Component {

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }
  
render() {

  const Card  = (List) => {
    
    return(
      <div className="row">
          {List.map((item, index) => 

            <div key={index} className="col-sm-4 col-md-3 col-lg-2">
                <div className="card">
                  <img className="card-img-top" 
                  src={process.env.PUBLIC_URL+"/Students/ProfilePhoto/"+item.photoPath} 
                  alt="Card" style={{width:"100%"}} />
                   
                  <div className="card-body" style={{padding:"5px"}}>
                    <h6 className="card-title" style={{marginBottom:'1px'}}>{item.studentName}</h6>
                    
                  </div>

                </div>
            </div>

          )}

      </div>
    );
  }

  const Student = this.props.studentLoading ? <i>Loading......!</i> : Card(this.props.studentList);
  
    return (

      <React.Fragment>
        {Student}
      </React.Fragment>
      
    );

  }
}

function mapStateToProps(state) {
  return ({
    studentList: state.wardReducer.wardList,
    studentLoading:state.wardReducer.wardLoading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      changeStateToReducer: () => {
        dispatch(getWardList());
      }
  });
}



export default connect(mapStateToProps,mapDispatchToProps)(WardCardView);


