import axios from 'axios';

export function getNotificationList() {
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/notifications/getClassWiseList`)
        .then(response =>

            getListRes(dispatch, response)

        ).catch(err => console.log(err));
    }
}

function getListRes(dispatch, response) {
    
    try {
       if(response.data.resType === 'success'){

            return dispatch({
                type: 'UPDATE_CLASS_WISE_NOTIFICATION_LIST',
                payload: response.data.list
            })
       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data.message
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}
