import axios from 'axios';

export function getTransportMemberDetail(id) {
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/getTransportMemberById/${id}`)
        .then(response =>{
            console.log(response);
            getTransportMemberDetailRes(dispatch, response)
           
        }).catch(err => console.log(err));
    }
}

function getTransportMemberDetailRes(dispatch, response) {
    
    try {
       if(response.data.resType === 'success'){

            return dispatch({
                type: 'UPDATE_TRANSPORT_MEMBER_DETAIL',
                payload: response.data.member
            })
       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}

export function getTransportMemberList(branchId, classId) {
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/getTransportMember/${branchId}&${classId}`)
        .then(response => {
            console.log(response);
            getTransportMemberRes(dispatch, response)

        }).catch(err => console.log(err));
    }
}

function getTransportMemberRes(dispatch, response) {
   
    try {
       if(response.data.resType === 'success'){

            return dispatch({
                type: 'UPDATE_TRANSPORT_MEMBER_LIST',
                payload: response.data.member
            })
       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data.message
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}

export function showHideForm(data) {
  
    return dispatch => {

        return dispatch({
            type: 'SHOW_HIDE_TRANSPORT_MEMBER_FORM',
            payload: data
        })
    }
}




