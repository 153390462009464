import React, { Component } from 'react';
import { connect } from 'react-redux';
import {hideMessage} from '../../../../store/action/MessageAction';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import {SchoolLogo} from '../../../utils/SchoolLogo';
import Navbar from '../../../Navbar';
import {ReactMessage} from '../../../utils/ReactMessage';
import {CheckSession} from '../../../utils/CheckSession';

import AttendList from './List';
import Filter from './filter';

class AttendanceReport extends Component {

  constructor(props){
    super(props);
    CheckSession(this.props);
  }


render() {

    return (

      <div className="container mp0">
        
            <SchoolLogo SchoolName="Inzeal Infotech" />

            {this.props.messageShow ? <ReactMessage func={this.props.hideMessage} messageType={this.props.messageType} Message={this.props.Message} /> : null}

            <Navbar />

            <div className="form-wrapper" style={{width:"100%"}}>
              <h2>Attendance</h2>
                <div className="row">
                  <Filter />
                </div>
            </div>

            <div className="form-wrapper" style={{width:"100%"}}>

              <h2>List</h2>
              <div style={{overflow:"auto"}}>
                <AttendList />
              </div>

            </div>
          
      </div>

    );
  }
}

function mapStateToProps(state) {
    return ({
      messageShow: state.messageReducer.messageShow,
      messageType: state.messageReducer.messageType,
      Message: state.messageReducer.Message
    });
}

function mapDispatchToProps(dispatch) {
  return ({
      hideMessage: () => {
        dispatch(hideMessage());
      }
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceReport);

