import React, { Component } from 'react';
import { connect } from 'react-redux';
import {showHideForm, showHideCardView} from '../../../../store/action/StudentMasterAction';
import {hideMessage} from '../../../../store/action/MessageAction';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import {SchoolLogo} from '../../../utils/SchoolLogo';
import Navbar from '../../../Navbar';
import {ReactMessage} from '../../../utils/ReactMessage';
import {CheckSession} from '../../../utils/CheckSession';

import StudentForm from './StudentForm';
import StudentList from './StudentList';
import StudentCardView from './StudentCardView';
import StudentFilter from './filter';

class StudentMaster extends Component {

  constructor(props){
    super(props);
    CheckSession(this.props);
  }

  toggleCardView = () => {
    if(this.props.isCardViewShow === false){
      this.props.showHideCardView(true);
    }
    else{
      this.props.showHideCardView(false);
    }
  }


render() {

    return (

      <div className="container mp0">

        <SchoolLogo SchoolName="Inzeal Infotech" />

        {this.props.messageShow ? <ReactMessage func={this.props.hideMessage} messageType={this.props.messageType} Message={this.props.Message} /> : null}

        <Navbar />
          <div className="form-wrapper">
            <div className="row">
              <StudentFilter />
            </div>
          </div>

          <div className="form-wrapper" style={{width:"100%"}}>

            <div className="row">
              <h2 className="col-sm-6 col-md-6 col-lg-6">Student List </h2>

              <div className="col-sm-6 col-md-6 col-lg-6" style={{textAlign:'right'}}>
                <button 
                    onClick={this.props.showHideForm} 
                    className="btn btn-sm btn-info" style={{margin:'0px 2px'}}>Add New</button>

                <button 
                  onClick={this.toggleCardView} 
                  className="btn btn-sm btn-info">Toggle</button>
              </div>

            </div>
            
            {this.props.isFormShow ? <StudentForm /> : null}
            
            {this.props.isCardViewShow ? <StudentCardView /> : <StudentList />}
              
          </div>
       

      </div>

    );
  }
}

function mapStateToProps(state) {
    return ({
      messageShow: state.messageReducer.messageShow,
      messageType: state.messageReducer.messageType,
      Message: state.messageReducer.Message,
      isFormShow: state.studentMasterReducer.isStudentFormShow,
      isCardViewShow: state.studentMasterReducer.isStudentCardViewShow,
    });
}

function mapDispatchToProps(dispatch) {
  return ({
      hideMessage: () => {
        dispatch(hideMessage());
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      },
      showHideCardView: (data) =>{
        dispatch(showHideCardView(data));
      }
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentMaster);

