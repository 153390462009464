const INITIAL_STATE = {
    isExpenseFormShow: false,
    expenseLoading:true,
    expenseDetail:'',
    expenseList:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_EXPENSE_DETAIL':
          return ({
              ...states,
              expenseDetail: action.payload
          });

        case 'UPDATE_EXPENSE_LIST':
            return ({
                ...states,
                expenseList: action.payload,
                expenseLoading: false
            });

        case 'SHOW_HIDE_EXPENSE_FORM':
            return({
                ...states,
                isExpenseFormShow:action.payload
            });

        default:
          return states;

  }
}
