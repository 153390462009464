import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getFeeList} from '../../../../store/action/FeeReportAction';
import ClassDrop from '../../../utils/ClassDrop';
import BranchDrop from '../../../utils/BranchDrop';
import StudentDrop from '../../../utils/StudentDrop';
import FeeTypeDrop from '../../../utils/FeeTypeDrop';
import {User} from '../../../utils/ValidateUser';
import {currentDate} from '../../../utils/CurrentDate';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class FeeReportFilter extends Component {

  constructor(props){
    super(props);
    this.state = {
      FormGroup: {
        branchId: '0', 
        classId: '0', 
        studentId: '0', 
        feeTypeId: '0', 
        fromDate: currentDate(), 
        toDate: currentDate()
      }
    };
  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

  }

  filterList = () => {
    let {branchId, classId, studentId, feeTypeId, fromDate, toDate} = this.state.FormGroup;
    console.log(this.state.FormGroup);
    //console.log(fromDate)
    if(branchId === "" || branchId === null){
      branchId = "0"
    }

    if(classId === "" || classId === null){
      classId = "0"
    }

    if(studentId === "" || studentId === null){
      studentId = "0"
    }

    if(feeTypeId === "" || feeTypeId === null){
      feeTypeId = "0"
    }

    this.props.applyFilter(branchId, classId, studentId, feeTypeId, fromDate, toDate)
  }

render() {

    return (
      <React.Fragment>

        {User.isAdmin() ? 
        <div className="form-group col-sm-4 col-md-3 col-lg-3 float-left">
            <label>Branch</label>
            <BranchDrop 
              BranchId={this.state.FormGroup.branchId} 
              func={this.handleInputChange} />
        </div>: null}

        <div className="form-group col-sm-4 col-md-3 col-lg-3 float-left">
          <label>Class</label>
          <ClassDrop 
            InputName='classId' 
            ClassId={this.state.FormGroup.classId} 
            func={this.handleInputChange} />
        </div>

        <div className="form-group col-sm-4 col-md-3 col-lg-3 float-left">
            <label>Student</label>
            <StudentDrop 
              StudentId={this.state.FormGroup.studentId} 
              BranchId={this.state.FormGroup.branchId} 
              ClassId={this.state.FormGroup.classId} 
              func={this.handleInputChange} />
        </div>

        <div className="form-group col-sm-4 col-md-3 col-lg-3 float-left">
          <label>Fee Type</label>
          <FeeTypeDrop 
            InputName='feeTypeId' 
            FeeTypeId={this.state.FormGroup.feeTypeId} 
            func={this.handleInputChange} />
        </div>

        <div className="form-group col-sm-4 col-md-3 col-lg-3 float-left">
          <label>From Date</label>
          <input 
            type="date" 
            name="fromDate" 
            className="form-control"
            value={this.state.FormGroup.fromDate}
            onChange={this.handleInputChange} />
        </div> 

        <div className="form-group col-sm-4 col-md-3 col-lg-3 float-left">
          <label>To Date</label>
          <input 
            type="date" 
            name="toDate" 
            className="form-control"
            value={this.state.FormGroup.toDate}
            onChange={this.handleInputChange} />
        </div>

        <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
            <button onClick={this.filterList} className="btn btn-primary">Search</button>
        </div>

      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return ({
    feeReportLoading: state.feeReportReducer.feeReportLoading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      applyFilter: (branchId, classId, studentId, feeTypeId, fromDate, toDate) => {
        dispatch(getFeeList(branchId, classId, studentId, feeTypeId, fromDate, toDate));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(FeeReportFilter);
