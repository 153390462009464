import React, { Component } from 'react';
import axios from 'axios';
//import { Link } from 'react-router-dom';
import {GoogleAdd} from './GoogleAdd';
import {MenuCard} from '../UtilComponent/MenuCard';
// import Chat from '../UtilComponent/Chatbox/index'
import {SchoolLogo} from '../utils/SchoolLogo';
import Navbar from '../ParentNavbar';
import {CheckParentSession} from '../utils/CheckSession';


 class ParentHome extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      cardList: [],
      loading: true
    };

    this.getMenu(this.props.match.params.id);
    console.log(navigator)
    
  }

  componentWillMount(){
      CheckParentSession(this.props);
  }

  componentWillReceiveProps(nextProps){

    this.setState({
      loading:true
    });

    this.getMenu(nextProps.match.params.id);
     
  }

  getMenu = (id) => {
    axios.get(`${process.env.PUBLIC_URL}/api/parent/getMenuById/${id}`)
    .then(res => {
      
      if(res.data.resType === 'success'){
        this.setState({
          cardList: res.data.parentMenu,
          loading: false
        });
      }

    }).catch(err => console.log(err));
  }

render() {

    const renderCards = (cardList) => {
      return (
        <React.Fragment>
          {cardList.map((card, index) => 
            <MenuCard key={index} icon={card.icon} title={card.title} Url={card.url+'/'+card._id} />
          )}
        </React.Fragment>
      )
    }

    const cardList = renderCards(this.state.cardList);

    return (
      <div className="container"> 
        
        <SchoolLogo SchoolName="Inzeal Infotech" /> 
        <Navbar /> 

        <div className="row"> 
          {this.state.loading ? 
            <div className="loader"> 
              <img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} /> 
            </div> : cardList} 

            <div style={{marginTop: "20px"}} className="col-sm-12 col-md-12 col-lg-12">
              <GoogleAdd /> 
            </div> 
        </div> 
        
        {/* <Chat /> */} 
      </div> 
    );
  }
}
export default ParentHome;