const INITIAL_STATE = {
    isFeeTypeFormShow: false,
    feeTypeloading:true,
    feeTypeDetail:'',
    feeTypeList:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_FEETYPE_DETAIL':
          return ({
              ...states,
              feeTypeDetail: action.payload
          });

        case 'UPDATE_FEETYPE_LIST':
            return ({
                ...states,
                feeTypeList: action.payload,
                feeTypeloading: false
            });

        case 'SHOW_HIDE_FEETYPE_FORM':
            return({
                ...states,
                isFeeTypeFormShow:action.payload
            });

        default:
          return states;

  }
}
