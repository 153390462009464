const INITIAL_STATE = {
    isTransportFormShow: false,
    transportloading:true,
    transportDetail:'',
    transportList:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_TRANSPORT_DETAIL':
          return ({
              ...states,
              transportDetail: action.payload
          });

        case 'UPDATE_TRANSPORT_LIST':
            return ({
                ...states,
                transportList: action.payload,
                transportloading: false
            });

        case 'SHOW_HIDE_TRANSPORT_FORM':
            return({
                ...states,
                isTransportFormShow:action.payload
            });

        default:
          return states;

  }
}
