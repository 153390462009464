const INITIAL_STATE = {
    FormGroup: {senderId:'test', message: ''}, 
    Message: '', 
    feedback: '', 
    feedbackLoading: true, 
    ChatMessage:[], 
    onlineUsers:[], 
    activeUser:{name:'Live Chat', id: 'authifosdf'}, 
    showOnlineUser: true 
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

    case 'HIDE_CHAT_BOX':

        return Object.assign({}, states, {
            activeUser:{name:'Live Chat', id: ''},
            ChatMessage:[],
            showOnlineUser: true
        });

    case 'UPDATE_ONLINE_USER':

        return Object.assign({}, states, {
            onlineUsers: action.payload
        });
    

    case 'UPDATE_CHAT_MESSAGE_INPUT':

        return Object.assign({}, states, {
            Message: action.payload
        });

    case 'CHANGE_USER':
       
        return Object.assign({}, states, {
            activeUser:{name:action.payload.name, id: action.payload.id},
            showOnlineUser: false,
            feedbackLoading:true
        });

    case 'UPDATE_CHAT_MESSAGE':
      
        return Object.assign({}, states, {
            ChatMessage: action.payload,
            feedbackLoading:true
        });

    case 'UPDATE_FEEDBACK_LOADING':
      
        return Object.assign({}, states, {
            feedbackLoading:action.payload
        });

    default:
        return states;

  }
}
