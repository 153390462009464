import React, { useEffect  } from 'react';
//import { Link } from 'react-router-dom'

export const GoogleAdd = () => {

    useEffect(() => {
        // Update the document title using the browser API
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, [0]);

    return (
        <ins className="adsbygoogle"
            style={{display: "block"}}
            data-ad-client="ca-pub-4849711273511804"
            data-ad-slot="1002940691"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
    )
   
}
