import React, { Component } from 'react';
import axios from 'axios';

export default class SubjectDrop extends Component {

    constructor(props) {

        super(props);
        this.state = {
            GroupList: [],
            loading: true,
            Message: 'wait....!'
        };

        this.fillList = this.fillList.bind(this);
        this.fillList(this.props.ClassId, this.props.BranchId);

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ClassId !== this.props.ClassId || nextProps.BranchId !== this.props.BranchId) {
            //Perform some operation
            this.fillList(nextProps.ClassId, nextProps.BranchId);
        }
    }

    fillList(id, branchId) {
        
        axios.get(`${process.env.PUBLIC_URL}/api/getSubjectDrop?id=${id}&branchId=${branchId}`)
        .then(response => {
            this.setState({ GroupList: response.data.subject, loading: false });
        });
        
    }

    render(){

        const renderlist = (List) => {
            return (
                <select name={this.props.InputName} 
                        value={this.props.SubjectId} 
                        onChange={this.props.func} 
                        className="form-control" required={this.props.isRequred}>

                        <option value="">Select</option>
                        {List.map(S =>
                            <option key={S._id} value={S._id}>{S.subjectName}</option>
                        )}

                </select>
            );
        }

        let GroupList = this.state.loading
            ? <p><em>Loading...</em></p>
            : renderlist(this.state.GroupList);

        return (
            <div>
                {GroupList}
            </div>
                
        );
    }
}

SubjectDrop.defaultProps = {
    SubjectId: "0",
    BranchId: "0",
    ClassId: "0",
    InputName: "subjectId",
    isRequred: false
}
