import axios from 'axios';

export const login = (formData) => {

    return axios.post(`${process.env.PUBLIC_URL}/api/checkLogin`, formData)
    .catch(err => console.log(err));
  
}

export const fetchParentLogin = (formData) => {

    return axios.post(`${process.env.PUBLIC_URL}/api/ParentLogin`, formData)
    .catch(err => console.log(err));
  
}

export const fetchParentDirectLogin = (id) => {

    return axios.get(`${process.env.PUBLIC_URL}/api/ParentDirectLogin/${id}`)
    .catch(err => console.log(err));
  
}