import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {getSMSList, showHideForm} from '../../../../store/action/SMSClassAction';
import {showMessage} from '../../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class SMSClassList extends Component {

  componentDidMount() {
    this.props.changeStateToReducer();
  }

  deleteSMS = (id) => {
    
    axios.delete(`${process.env.PUBLIC_URL}/api/deleteClassSMS/${id}`)
    .then(res => {

      if(res.data.resType === 'success') {
        this.props.changeStateToReducer();
      }

      this.props.showMessage({
        messageType: res.data.resType,
        Message:res.data.message
      });

    }).catch(err => console.log(err));

  }

  reSendSMS = (id) =>{
    axios.get(`${process.env.PUBLIC_URL}/api/reSendClassSMS/${id}`)
    .then(res => {

      this.props.showMessage({
        messageType: res.data.resType,
        Message:res.data.message
      });

    }).catch(err => console.log(err));
  }

  
render() {

  const renderTable  = (List) =>{
    console.log(List);
    return(
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Message</th>
              <th>Class</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {List.map((item, index) => 
              <tr key={index}>
                <td>{item.Message}</td>
                <td>{item.className}</td>
                <td>
                  <i className="far fa-comment" onClick={this.reSendSMS.bind(this, item._id)} 
                      style={{margin:"2px 5px", color:"green", cursor:"pointer"}} title="Send"></i>

                  <i className="fas fa-trash-alt" onClick={this.deleteSMS.bind(this, item._id)} 
                    style={{margin:"2px 5px", color:"red", cursor:"pointer"}} title="Delete"></i>
                </td>
              </tr>
            )}
          </tbody>
        </table>

    );

  }

  const SMS = this.props.SMSloading ? <div className="loader"><img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} /></div> : renderTable(this.props.SMSList)

    return (

      <React.Fragment>
        {SMS}
      </React.Fragment>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    SMSList: state.smsClassReducer.SMSClassList,
    SMSloading:state.smsClassReducer.SMSClassLoading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      changeStateToReducer: () => {
        dispatch(getSMSList());
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(SMSClassList);


