const INITIAL_STATE = {
    isParentFormShow: false,
    isParentCardViewShow:false,
    parentLoading:true,
    parentDetail:'',
    parentList:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_PARENT_DETAIL':
          return ({
              ...states,
              parentDetail: action.payload
          });

        case 'UPDATE_PARENT_LIST':
            return ({
                ...states,
                parentList: action.payload,
                parentLoading:false
            });

        case 'SHOW_HIDE_PARENT_FORM':
            return({
                ...states,
                isParentFormShow:action.payload
            });

        case 'SHOW_HIDE_PARENT_CARDVIEW':
            return({
                ...states,
                isParentCardViewShow:action.payload
            });

        default:
          return states;

  }
}
