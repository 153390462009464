import React from 'react';
//import { Link } from 'react-router-dom'

export const SchoolLogo = ({SchoolName}) => {
    return (
        <div className="Logo_wrapper">
            <h1 className="Logo">School</h1>
        </div>
    )
}
