import axios from 'axios';

export function getTransportDetail(id) {
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/getTransportById/${id}`)
        .then(response =>{
            console.log(response)
            getTransportDetailRes(dispatch, response)
           
        }).catch(err => console.log(err));
    }
}

function getTransportDetailRes(dispatch, response) {
    
    try {
       if(response.data.resType === 'success'){

            return dispatch({
                type: 'UPDATE_TRANSPORT_DETAIL',
                payload: response.data.transport
            })
       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}

export function getTransportList() {
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/getTransport`)
        .then(response => {
            
            console.log(response);
            getTransportRes(dispatch, response)

        }).catch(err => console.log(err));
    }
}

function getTransportRes(dispatch, response) {
   
    try {
       if(response.data.resType === 'success'){

            return dispatch({
                type: 'UPDATE_TRANSPORT_LIST',
                payload: response.data.transport
            })
       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data.message
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}

export function showHideForm(data) {
  
    return dispatch => {

        return dispatch({
            type: 'SHOW_HIDE_TRANSPORT_FORM',
            payload: data
        })
    }
}




