const INITIAL_STATE = {
    isClassFormShow: false,
    classloading:true,
    classDetail:'',
    classList:[]
}

export default (states = INITIAL_STATE, action) => {
    
  switch (action.type) {

        case 'UPDATE_CLASS_DETAIL':
          return ({
              ...states,
              classDetail: action.payload
          });

        case 'UPDATE_CLASS_LIST':
            return ({
                ...states,
                classList: action.payload,
                classloading:false
            });

        case 'SHOW_HIDE_CLASS_FORM':
            return({
                ...states,
                isClassFormShow:action.payload
            });

        default:
          return states;

  }
}
