import axios from 'axios';

export function getHomeWorkList(id) {
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/parent/getHomeWorkList?id=${id}`)
        .then(response =>

            getHomeWorkListRes(dispatch, response)

        ).catch(err => console.log(err));
    }
}

function getHomeWorkListRes(dispatch, response) {
    console.log(response);
    try {
       if(response.data.resType === 'success'){

            return dispatch({
                type: 'UPDATE_PARENT_HOMEWORK_LIST',
                payload: response.data.homeWork
            })
       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data.message
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}


export function showHideForm(data) {
  
    return dispatch => {

        return dispatch({
            type: 'SHOW_HIDE_PARENT_HOME_WORK_FORM',
            payload: data
        })
    }
}
