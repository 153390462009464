import axios from 'axios';


export function getMarksList(branchId, classId, studentId, term) {
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/MarkSheet/getMarks?branchId=${branchId}&classId=${classId}&term=${term}&studentId=${studentId}`)
        .then(response =>

            getMarksListRes(dispatch, response)

        ).catch(err => console.log(err));
    }

}

function getMarksListRes(dispatch, response) {
   
    try {
       if(response.data.resType === 'success'){

            return dispatch({
                type: 'UPDATE_MARKS_SHEET_LIST',
                payload: response.data.markSheet
            })

       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data.message
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}


export function updateStudentList(data) {
  
    return dispatch => {

        return dispatch({
            type: 'UPDATE_MARKS_ASSIGN_STUDENT_LIST',
            payload: data
        })
    }
}
