import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {getStudentChargeList, showHideForm} from '../../../../store/action/StudentChargeAction';
import {showMessage} from '../../../../store/action/MessageAction';
import FeeTypeDrop from '../../../utils/FeeTypeDrop';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class StudentChargeForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      studentChargeId:0,
      isUpdate:false,
      FormGroup: {
        classId: this.props.feeChargeInfo.classId,
        studentId: this.props.feeChargeInfo.studentId,
        feeTypeId: '',
        Amount: '',
        Date: '',
        Remark: ''
      }
    };

  }

  clearForm = () => {
    const UserInput = this.state.FormGroup;
    UserInput['feeTypeId'] = '';
    UserInput['Amount'] = '';
    UserInput['Date'] = '';
    UserInput['Remark'] = '';

    this.setState({
        FormGroup: UserInput,
        studentChargeId:0,
        isUpdate: false
    });
    
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const UserInput = this.state.FormGroup;

    UserInput[name] = value;

    this.setState({
      FormGroup:UserInput
    })

    if(name === 'feeTypeId'){
      this.getFee();
    }

  }

  getFee = () => {
    axios.get(`${process.env.PUBLIC_URL}/api/getFeeChargeById/${this.state.FormGroup.classId}&${this.state.FormGroup.feeTypeId}`)
    .then(res => {

      if(res.data.resType === 'success'){
        const UserInput = this.state.FormGroup;
        if(res.data.feeSetup.length > 0){
          UserInput['Amount'] = res.data.feeSetup[0].Fee;
        }
        else{
          UserInput['Amount'] = '';
        }
        this.setState({
          FormGroup:UserInput
        })
      }

     }).catch(err => console.log(err));
  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    
    if (prevProps.studentChargeDetail !== this.props.studentChargeDetail) {

      if(this.props.studentChargeDetail !== ''){
        const UserInput = this.props.studentChargeDetail;
        UserInput['Date'] = this.GetFormattedDate(this.props.studentChargeDetail.Date);

        this.setState({
          FormGroup: UserInput,
          studentChargeId:this.props.studentChargeDetail._id,
          isUpdate: true
        })
      }

    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    
    const StudentCharge = this.state.FormGroup;
   
    let url = `${process.env.PUBLIC_URL}/api/SaveStudentCharge`;

    if(this.state.isUpdate){
      url = `${process.env.PUBLIC_URL}/api/updateStudentChargeById/${this.state.studentChargeId}`;
    }

    axios.post(url, StudentCharge)
    .then(res => {

        if(res.data.resType === 'success'){
          this.clearForm();
          console.log(this.state.FormGroup.studentId);
          this.props.getStudentChargeList(this.state.FormGroup.studentId);
        }

        this.props.showMessage({
          messageType: res.data.resType,
          Message: res.data.message
        });

     }).catch(err => console.log(err));

  }

render() {

    return (
      <div className='form-modle'>
          
          <form onSubmit={this.handleSubmit}>
          <h2 className="head_h2"> Student Charge </h2>
          <i onClick={this.props.showHideForm} className="fas fa-arrow-left close-btn"></i>
              
              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <label>Fee Type</label>
                <FeeTypeDrop 
                  InputName='feeTypeId' 
                  FeeTypeId={this.state.FormGroup.feeTypeId} 
                  func={this.handleInputChange} />
              </div>

              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <label>Amount</label>
                <input type="number" 
                    className="form-control" 
                    placeholder="Amount" 
                    name="Amount" 
                    value={this.state.FormGroup.Amount} 
                    onChange={this.handleInputChange} />
              </div>

              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <label>Date</label>
                <input type="date" 
                    className="form-control" 
                    placeholder="Date" 
                    name="Date" 
                    value={this.state.FormGroup.Date} 
                    onChange={this.handleInputChange} />
              </div>

              <div className="form-group col-sm-12 col-md-12 col-lg-12 float-left">
                <label>Remark</label>
                <input type="text" 
                    className="form-control" 
                    placeholder="Remark" 
                    name="Remark" 
                    value={this.state.FormGroup.Remark} 
                    onChange={this.handleInputChange} />
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12" style={{clear:"both"}}>
                  <button type="submit" className="btn btn-primary">Submit</button>
              </div>
              

          </form>

      </div>

    );
  }
}

function mapStateToProps(state) {
  return ({
    studentChargeDetail: state.studentChargeReducer.studentChargeDetail,
    feeChargeInfo: state.studentChargeReducer.feeChargeInfo
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      getStudentChargeList: (studentId) => {
        dispatch(getStudentChargeList(studentId));
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(false));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(StudentChargeForm);