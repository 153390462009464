import React, { Component } from 'react';
import { connect } from 'react-redux';
import {hideMessage, showMessage} from '../../../../store/action/MessageAction';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import {SchoolLogo} from '../../../utils/SchoolLogo';
import Navbar from '../../../Navbar';
import {ReactMessage} from '../../../utils/ReactMessage';
import {CheckSession} from '../../../utils/CheckSession';

import CardView from './CardView';
import Filter from './filter';

class MarkSheet extends Component {

  constructor(props){
    super(props);
    CheckSession(this.props);
    this.state={
      isAllSelected: false
    }
  }

  showHideForm = () => {
    this.props.showHideForm();
  }

render() {

    return (

      <div className="container mp0">

        <SchoolLogo SchoolName="Inzeal Infotech" />

        {this.props.messageShow ? 
        <ReactMessage func={this.props.hideMessage} messageType={this.props.messageType} Message={this.props.Message} /> : null}

        <Navbar />

        {this.props.isFilter ? <div className="form-wrapper">
          <h2>Mark Sheet</h2>
          <Filter />
          
        </div>

        :<div className="form-wrapper" style={{width:"100%"}}>

          <h2 style={{paddingLeft:"36px"}}> Back 
            <i onClick={this.props.updateFilterFlag} className="fas fa-arrow-left close-btn2"></i>
          </h2>
          
          <CardView />

        </div>}
       
      </div>

    );
  }
}

function mapStateToProps(state) {
    return ({
      messageShow: state.messageReducer.messageShow,
      messageType: state.messageReducer.messageType,
      Message: state.messageReducer.Message,
      isFilter: state.markSheetReducer.isFilter,
    });
}

function mapDispatchToProps(dispatch) {
  return ({
      hideMessage: () => {
        dispatch(hideMessage());
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      updateFilterFlag: () => {
        dispatch({
          type: 'UPDATE_HOME_WORK_FILTER_FLAG',
          payload: true
        });
      }
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(MarkSheet);

