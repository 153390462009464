import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {getStudentList, getStudentDetail, showHideForm} from '../../../../store/action/StudentMasterAction';
import {showMessage} from '../../../../store/action/MessageAction';
import {filter} from '../../../utils/FilterJson';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class StudentList extends Component {
  
  state = {
    studentList:this.props.studentList,
    search:""
  }

  componentDidMount() {
    this.props.changeStateToReducer();

  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevProps.studentList !== this.props.studentList) {
      this.setState({
        studentList: this.props.studentList
      })
    }
  }

  handleInputChange = (event) => {
       
    const target = event.target;
    const value = target.value;

    this.setState({
      search:value,
      studentList: filter.arrayOfObject(this.props.studentList, value)
    })

  }

  deleteStudent = (id) => {
    
    axios.delete(`${process.env.PUBLIC_URL}/api/deleteStudent/${id}`)
    .then(res => {

      if(res.data.resType === 'success'){
        this.props.changeStateToReducer();
      }

      this.props.showMessage({
        messageType: res.data.resType,
        Message:res.data.message
      });

    }).catch(err => console.log(err));
  }

  getStudentDet = (id) =>{
    this.props.getStudentDetail(id);
    this.props.showHideForm();
  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

render() {

  const renderTable  = (List) => {
   
    return(
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Student Code</th>
              <th>Student Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Father Name</th>
              <th>Mother Name</th>
              <th>Class Name</th>
              <th>Roll No</th>
              <th>Password</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {List.map((item, index) => 

              <tr key={index}>
                <td>{item.studentCode}</td>
                <td>{item.studentName}</td>
                <td>{item.Phone}</td>
                <td>{item.Email}</td>
                <td>{item.parentId === null ? null : item.parentId.fatherName}</td>
                <td>{item.parentId === null ? null : item.parentId.motherName}</td>
                <td>{item.classId === null ? null : item.classId.className}</td>
                <td>{item.rollNo}</td>
                <td>{item.Password}</td>
                <td>
                    <i className="fas fa-edit" onClick={this.getStudentDet.bind(this, item._id)} style={{margin:"2px", color:"green"}}></i>
                    <i className="fas fa-trash-alt" onClick={this.deleteStudent.bind(this, item._id)} style={{margin:"2px", color:"red"}}></i>
                </td>
              </tr>
              
            )}
          </tbody>
        </table>

    );
  }

  const Student = this.props.studentLoading ? 
            <div className="loader"><img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} /></div> : 
            renderTable(this.state.studentList);
  
    return (

      <div style={{overflow: "auto"}}>
        {/* <div className="form-group col-sm-4 col-md-4 col-lg-4 float-left">
          <input type="text" 
              className="form-control" 
              placeholder="Search......" 
              name="search" 
              value={this.state.search} 
              onChange={this.handleInputChange} />
        </div> */}
        {Student}
      </div>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    studentList: state.studentMasterReducer.studentList,
    studentLoading:state.studentMasterReducer.studentLoading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      changeStateToReducer: () => {
        dispatch(getStudentList('0','0'));
      },
      getStudentDetail: (id) => {
        dispatch(getStudentDetail(id));
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(StudentList);


