import React, { Component } from 'react';
import { connect } from 'react-redux';
import {showHideCardView} from './WardAction';
import {hideMessage} from '../../../store/action/MessageAction';
// import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import {SchoolLogo} from '../../utils/SchoolLogo';
import Navbar from '../../ParentNavbar';
import {ReactMessage} from '../../utils/ReactMessage';
import {CheckParentSession} from '../../utils/CheckSession';

import WardList from './List';
import WardCardView from './CardView';

class WardIndex extends Component {

  constructor(props){
    super(props);
    CheckParentSession(this.props);
  }

  toggleCardView = () => {
    if(this.props.isCardViewShow === false){
      this.props.showHideCardView(true);
    }
    else{
      this.props.showHideCardView(false);
    }
  }

render() {

    return (

      <div className="container mp0">

        <SchoolLogo SchoolName="Inzeal Infotech" />

        {this.props.messageShow ? <ReactMessage func={this.props.hideMessage} messageType={this.props.messageType} Message={this.props.Message} /> : null}

        <Navbar />

        <div className="form-wrapper" style={{width:"100%"}}>

          <h2>Student List <button 
              onClick={this.toggleCardView} 
              className="btn btn-sm btn-info float-right">Toggle</button></h2>

          {this.props.isCardViewShow ? <WardCardView /> : <WardList />}
            
        </div>
       
      </div>

    );
  }
}

function mapStateToProps(state) {
    return ({
      messageShow: state.messageReducer.messageShow,
      messageType: state.messageReducer.messageType,
      Message: state.messageReducer.Message,
      isCardViewShow: state.wardReducer.isWardCardShow,
    });
}

function mapDispatchToProps(dispatch) {
  return ({
      hideMessage: () => {
        dispatch(hideMessage());
      },
      showHideCardView: (data) =>{
        dispatch(showHideCardView(data));
      }
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(WardIndex);

