import React, { Component } from 'react';
import{Link} from 'react-router-dom';
import axios from 'axios';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import {SchoolLogo} from '../../../utils/SchoolLogo';
import Navbar from '../../../Navbar';
import {ReactMessage} from '../../../utils/ReactMessage';
import {CheckSession} from '../../../utils/CheckSession';



class BranchSetupList extends Component {

  constructor(props){
    super(props);
    this.state = {
      BranchList:[],
      loading : true,
      messageShow: false,
      messageType: 'success',
      Message:'',
    };

    this.mounted = true;
    CheckSession(this.props);
  }

  componentWillUnmount(){
    this.mounted = false;
  }

  componentDidMount() {
    this.getBranchList();
  }

  hideMessage = () => {
    this.setState({
      messageShow: false,
      messageType: 'success',
      Message: '',
    });
  }

  getBranchList = () => {
  
    axios.get(`${process.env.PUBLIC_URL}/api/getBranch`)
    .then(res => {
            
        if(res.data.resType === 'success'){
          if(this.mounted){
            this.setState({
              BranchList:res.data.branch,
              loading: false
            });
          }
          
        }

    }).catch(err => console.log(err));
  }

  deleteBranch = (id) => {
    
    axios.delete(`${process.env.PUBLIC_URL}/api/deleteBranch/${id}`)
    .then(res => {

      if(res.data.resType === 'success'){
        this.getBranchList();
      }

      this.setState({
        messageShow: true,
        messageType: res.data.resType,
        Message: res.data.message,
      });
        
    }).catch(err => console.log(err));
  }


  
render() {

  const BranchTable  = (List) =>{
    return(
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>branchCode</th>
              <th>branchName</th>
              <th>branchEmail</th>
              <th>branchContactNo</th>
              <th>branchAddress</th>
              <th>inchargePerson</th>
              <th>PersonContact</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {List.map((item, index) => 
              <tr key={index}>
                <td>{item.branchCode}</td>
                <td>{item.branchName}</td>
                <td>{item.branchEmail}</td>
                <td>{item.branchContactNo}</td>
                <td>{item.branchAddress}</td>
                <td>{item.inchargePerson}</td>
                <td>{item.inchargePersonContact}</td>
                <td>
                  <Link className="btn btn-sm btn-info" to={"/BranchSetup/"+item._id}>Edit</Link>
                  <button className="btn btn-sm btn-danger" onClick={this.deleteBranch.bind(this, item._id)}>Delete</button>
                </td>
              </tr>
              
              )}
          </tbody>
        </table>

    );

  }

  const Branch = this.state.loading ? <div className="loader"><img src={process.env.PUBLIC_URL+"/Images/loading3.gif"} alt="loader" style={{width:"200px"}} /></div> : BranchTable(this.state.BranchList)


    return (

      <div className="container mp0">

        <SchoolLogo SchoolName="Inzeal Infotech" />

        {this.state.messageShow ? <ReactMessage func={this.hideMessage} messageType={this.state.messageType} Message={this.state.Message} /> : null}

        <Navbar />

          <div className="form-wrapper" style={{width:"100%"}}>
              
            <h2>Branch List <Link to={"/BranchSetup/auth_newdasdahsdwye"} className="btn btn-sm btn-info float-right">Add New</Link></h2>
            <div style={{overflow:"auto"}}>
              {Branch}
            </div>
            
          </div>
      </div>

    );
  }
}
export default BranchSetupList;

