import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {getParentDetail, showHideForm, getParentList} from '../../../../store/action/ParentMasterAction';
import {showMessage} from '../../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';


class ParentCardView extends Component {

  deleteParent = (id) => {
    
    axios.delete(`${process.env.PUBLIC_URL}/api/deleteParent/${id}`)
    .then(res => {

      if(res.data.resType === 'success'){
        this.props.changeStateToReducer();
      }

      this.props.showMessage({
        messageType: res.data.resType,
        Message: res.data.message
      });

    }).catch(err => console.log(err));
  }

  getParentDet = (id) =>{
    this.props.getParentDetail(id);
    this.props.showHideForm();
  }

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  
render() {

  const ParentCard  = (List) => {
    
    return(
      <div className="row">
          {List.map((item, index) => 

            <div key={index} className="col-sm-4 col-md-3 col-lg-2">
                <div className="card">
                  <img className="card-img-top" 
                  src={process.env.PUBLIC_URL+"/Parent/ProfilePhoto/"+item.photoPath} 
                  alt="Card" style={{width:"100%"}} />
                   
                  <div className="card-body" style={{padding:"5px"}}>
                    <h6 className="card-title" style={{marginBottom:'1px'}}>{item.fatherName}</h6>
                    <p style={{marginBottom:'1px'}}>{item.Profession}</p>
                    <i className="fas fa-edit" onClick={this.getParentDet.bind(this, item._id)} style={{margin:"2px", color:"green"}}></i>
                    <i className="fas fa-trash-alt" onClick={this.deleteParent.bind(this, item._id)} style={{margin:"2px", color:"red"}}></i>
                  </div>

                </div>
            </div>

          )}

      </div>
    );
  }

  const Parent = this.props.parentloading ? <i>Loading......!</i> : ParentCard(this.props.parentList);
  
    return (

      <React.Fragment>
        {Parent}
      </React.Fragment>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    parentList: state.parentMasterReducer.parentList,
    parentloading:state.parentMasterReducer.parentloading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      changeStateToReducer: () => {
        dispatch(getParentList());
      },
      getParentDetail: (id) => {
        dispatch(getParentDetail(id));
      },
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      showHideForm: () => {
        dispatch(showHideForm(true));
      }
  });
}



export default connect(mapStateToProps,mapDispatchToProps)(ParentCardView);


