import { combineReducers } from 'redux';
import StateReducer from './StateReducer';
import CityReducer from './CityReducer';
import DesignationReducer from './DesignationReducer';
import MessageReducer from './MessageReducer';
import ReligionReducer from './ReligionReducer';
import TeacherMasterReducer from './TeacherMasterReducer';
import ParentMasterReducer from './ParentMasterReducer';
import ClassReducer from './ClassReducer';
import StudentMasterReducer from './StudentMasterReducer';
import FeeTypeReducer from './FeeTypeReducer';
import FeeSetupReducer from './FeeSetupReducer';
import StudentChargeReducer from './StudentChargeReducer';
import SMSReducer from './SMSReducer';
import SMSIndividualReducer from './SMSIndividualReducer';
import SMSClassReducer from './SMSClassReducer';
import ChatReducer from './ChatReducer';
import PermissionReducer from './PermissionReducer';
import AddTransportReducer from './AddTransportReducer';
import AddTransportMemberReducer from './AddTransportMemberReducer';
import ExpenseReducer from './ExpenseReducer';
import FeeReportReducer from './FeeReportReducer';
import AllNotification from '../../component/Admin/SendNotification/AllParent/Reducer';
import ClassNotification from '../../component/Admin/SendNotification/ClassWise/Reducer';
import AssignHomeWork from '../../component/Admin/Students/AssignHomeWork/Reducer';
import SubjectGroup from '../../component/Admin/Masters/SubjectGroup/Reducer';
import Subjects from '../../component/Admin/Masters/Subjects/Reducer';
import AttendanceReportReducer from '../../component/Admin/Reports/Attendance/Reducer';
import MarksSetupReducer from '../../component/Admin/Examination/ExamMarksSetup/Reducer';
import MarksAssignReducer from '../../component/Admin/Examination/AssignMarks/Reducer';
import MarkSheetReducer from '../../component/Admin/Examination/MarkSheet/Reducer';

//Parent Panel
import WardReducer from '../../component/Parent/Ward/WardReducer';
import AttendReducer from '../../component/Parent/Attendance/AttendReducer';
import ParentNotification from '../../component/Parent/Notification/Reducer';
import HomeWork from '../../component/Parent/HomeWork/Reducer';
import ParentExamination from '../../component/Parent/Examination/Reducer';

export default combineReducers({
    stateReducer: StateReducer,
    cityReducer: CityReducer,
    designationReducer: DesignationReducer,
    messageReducer: MessageReducer,
    religionReducer: ReligionReducer,
    teacherMasterReducer: TeacherMasterReducer,
    parentMasterReducer: ParentMasterReducer,
    classReducer: ClassReducer,
    studentMasterReducer: StudentMasterReducer,
    feeTypeReducer: FeeTypeReducer,
    feeSetupReducer: FeeSetupReducer,
    studentChargeReducer: StudentChargeReducer,
    smsReducer: SMSReducer,
    smsIndividualReducer: SMSIndividualReducer,
    chatReducer: ChatReducer,
    permissionReducer: PermissionReducer,
    addTransportReducer: AddTransportReducer,
    addTransportMemberReducer: AddTransportMemberReducer,
    expenseReducer: ExpenseReducer,
    feeReportReducer: FeeReportReducer,
    smsClassReducer: SMSClassReducer,
    wardReducer: WardReducer,
    attendReducer: AttendReducer,
    allNotification: AllNotification,
    classWiseNotification: ClassNotification,
    assignHomeWork: AssignHomeWork,
    subjectGroupReducer: SubjectGroup,
    subjectsReducer: Subjects,
    attendanceReportReducer: AttendanceReportReducer,
    marksSetupReducer: MarksSetupReducer,
    marksAssignReducer: MarksAssignReducer,
    markSheetReducer: MarkSheetReducer,

    //// Parent Panel
    parentNotification: ParentNotification,
    homeWorkReducer: HomeWork,
    parentExamination: ParentExamination
});
