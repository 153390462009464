import React, { Component } from 'react';
import { connect } from 'react-redux';
import {updateSubjectList} from './Action';
import {showMessage} from '../../../../store/action/MessageAction';
//import * as $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

class CardView extends Component {

  GetFormattedDate = (D) => {
    return D.slice(0, 10);
  }

  handleInputChange = (i, Name, event) => {

    const target = event.target;
    const value = target.value;

    let list = [...this.props.subjectList];
    list[i][Name] = value;

    this.props.updateSubjectList(list)

  }


render() {


  const Card  = (List) => {
    
    return(
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Subject</th>
                <th>Min Marks</th>
                <th>Max Marks</th>
              </tr>
            </thead>
            <tbody>
              {List.map((item, index) => 
                <tr key={index}>
                  <td>{item.subjectName}</td>
                  <td><input type="text" 
                    className="form-control" 
                    placeholder="Min Marks"
                    value={item.minMarks} 
                    onChange={this.handleInputChange.bind(this, index, 'minMarks')} /></td>

                  <td><input type="text" 
                    className="form-control" 
                    placeholder="Max Marks"
                    value={item.maxMarks} 
                    onChange={this.handleInputChange.bind(this, index, 'maxMarks')} /></td>
                </tr>
              )}
            </tbody>
          </table>

    );
  }

  const Student = this.props.subjectLoading ? <i>No Data</i> : Card(this.props.subjectList);
 
  
    return (

      <React.Fragment>
        {Student}
      </React.Fragment>
      
    );
  }
}

function mapStateToProps(state) {
  return ({
    subjectList: state.marksSetupReducer.subjectList,
    subjectLoading: state.marksSetupReducer.subjectLoading
  });
}

function mapDispatchToProps(dispatch) {
  return ({
      showMessage: (data) => {
        dispatch(showMessage(data));
      },
      updateSubjectList: (list) => {
        dispatch(updateSubjectList(list));
      }
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(CardView);