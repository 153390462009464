import axios from 'axios';

export function getSubjectDetail(id) {
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/getSubjectById/${id}`)
        .then(response =>

            getSubjectDetailRes(dispatch, response)

        ).catch(err => console.log(err));
    }
}

function getSubjectDetailRes(dispatch, response) {
    
    try {
       if(response.data.resType === 'success'){

            return dispatch({
                type: 'UPDATE_SUBJECT_DETAIL',
                payload: response.data.subject
            })
       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}

export function getSubjectList(classId, branchId) {
  
    return dispatch => {

        axios.get(`${process.env.PUBLIC_URL}/api/getSubject?classId=${classId}&branchId=${branchId}`)
        .then(response =>

            getSubjectListRes(dispatch, response)

        ).catch(err => console.log(err));
    }
}

function getSubjectListRes(dispatch, response) {
   
    try {
       if(response.data.resType === 'success'){

            return dispatch({
                type: 'UPDATE_SUBJECT_LIST',
                payload: response.data.subject
            })
       }
       else{
            return dispatch({
                type: 'ERROR_CATCH',
                payload: response.data.message
            })
       }
    }
    catch (err) {
        return dispatch({
            type: 'ERROR_CATCH',
            payload: 'Error_CATCH'
        })
    }
}

export function showHideForm(data) {
  
    return dispatch => {

        return dispatch({
            type: 'SHOW_HIDE_SUBJECT_FORM',
            payload: data
        })
    }
}




