import axios from 'axios';

export const teacher = {

    getTeacherDrop: (id) => {
        // id => ClassId
        return axios.get(`${process.env.PUBLIC_URL}/api/getTeacherDrop/${id}`)
        .catch(err => console.log(err));
    }
    
}

